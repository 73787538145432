/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { createSelector } from 'reselect';
import { verifyScopeExist } from '../Auth/helper/helper';
import { getFeatureFlag } from '../../../App/ducks/Config/Config-Selectors';

export const getCurrentBrandId = (state) => state.ui.brandId;

// Order Id
export const getOrderId = (state) => state.order?.orderId || '';

// Auth
export const getAuthInfo = (state) => state.member.auth;
export const getUserType = (state) => state.member?.auth?.userType;
export const getAccessToken = (state) => state.member?.auth?.accessToken;
export const getExpiresAt = (state) => state.member.auth.expiresAt;
export const getIsAuthenticatedStatus = (state) => state?.member?.auth?.isAuthenticated;
export const getLastVisitedPages = (state) => state.member.auth.lastVisitedPage;
export const getRememberConsentStatus = (state) => state.member.auth.rememberConsent;
export const getAuthError = (state) => state.member.auth.authError;
export const getAuthSuccess = (state) => state.member.auth.authSuccess;
export const getIsUserReAuthenticated = (state) => state.member?.auth?.reAuthenticatedUser || false;
export const getIsUserRecentlyLoggedOutStatus = (state) => state?.member?.auth?.isUserRecentlyLoggedOut;
export const getEnterpriseIdSelector = (state) => state.member.enterpriseId.enterpriseId;
export const getEnterpriseIdMessage = (state) => state.member.enterpriseId.enterpriseIdMessage;
export const getLastVisted = (state) => {
    if (state.member.auth.lastVisitedPage.length > 0) {
        const { lastVisitedPage } = state.member.auth;
        if (lastVisitedPage.indexOf('/shipping') >= 0) {
            return `/checkout/shipping/${getOrderId(state)}`;
        } if (lastVisitedPage.indexOf('/cart') >= 0) {
            return `/checkout/cart/${getOrderId(state)}`;
        } if (lastVisitedPage.indexOf('/payment') >= 0) {
            return `/checkout/payment/${getOrderId(state)}`;
        }
        return state.member.auth.lastVisitedPage;
    }
    return '/';
};

// Profile
export const getProfileInfo = (state) => state.member?.profile || {};
export const getUserEmailId = (state) => state.member?.profile?.email || '';
export const getUserRole = (state) => state.member?.profile?.userRole || '';
export const getFirstLastName = (state) => ({
    firstName: state.member?.profile?.firstName || '',
    lastName: state.member?.profile?.lastName || '',
});
export const getContactId = (state) => state.member?.profile?.contactId;
export const getAutoRenewStatus = (state) => state.member?.profile?.autoRenewStatus;
export const getMembershipType = (state) => state.member.profile.membershipType;
export const getMembershipTypeLoading = (state) => state.member.profile.membershipTypeLoading;
export const getMemberLoyalty = (state) => state.member.profile.loyaltyStatus || '';

// AddressBook
export const getAddressBookEntries = (state) => state.member?.addressBook?.addressBookEntries || [];
export const getGuestAddressBookEntries = (state) => state.member?.addressBook?.guestAddressBookEntries || [];
export const getTotalEntriesAddressBook = (state) => state.member.addressBook.totalEntries;
export const getAddressBookPageNumber = (state) => {
    const { pageNumber } = state.member.addressBook;
    if (pageNumber) {
        return pageNumber;
    }
    return 0;
};
export const getTotalNoOfPages = (state) => state.member.addressBook.totalPages;
export const getBillingAddress = (state) => state.member.addressBook.billingAddress;

// Wallet
export const getWalletEntries = (state) => state.member.wallet.walletEntries || [];
export const getSavedCreditCardError = (state) => state.member.wallet.error.walletSaveCreditCard;
export const getCreditCardSavedFlag = (state) => state.member.wallet.creditCardSavedFlag;
export const getWalletCards = createSelector(
    [
        getWalletEntries,
    ],
    (walletCards) => walletCards.filter((card) => (!['PY', 'GP'].includes(card?.payType))),
);

// Get ShopRunner Token
export const getShopRunnerToken = (state) => {
    if (state
        && state.member
        && state.member.shopRunner
        && state.member.shopRunner.srToken) {
        return state.member.shopRunner.srToken;
    }
    return null;
};

// Member Id
export const getMemberId = (state) => state.cart?.customer?.customerId || null;

/**
 * @param {} state
 */
export const getBrandsById = (state) => {
    if (state && state.page && state.page.brands && state.page.brands.byId) {
        return state.page.brands.byId;
    }

    return null;
};

// Brand (Client Id)
export const getBrand = createSelector(
    [
        getCurrentBrandId,
        getBrandsById,
    ],
    (currentBrandId, brandsById) => {
        if (currentBrandId && brandsById[currentBrandId]) {
            return brandsById[currentBrandId];
        }

        return null;
    },
);

/**
 * @param {} state
 */
export const getConfigByDomain = (state) => {
    if (state && state.app && state.app.config && state.app.config.byDomain) {
        return state.app.config.byDomain;
    }
    return null;
};

/**
 * @param {} state
 */
// Flags
export const getFlags = createSelector(
    [
        getBrand,
        getConfigByDomain,
    ],
    (brand, config) => {
        if (brand && brand.domain && config) {
            return config[brand.domain]['app-feature-flags'];
        }
        return {};
    },
);

// Check If Logged In user is Passport
export const getIsPassportUserLoggedIn = createSelector(
    [
        getUserType,
        getUserRole,
    ],
    (userType, userRole) => {
        if (userType === 'R' && userRole === 'P') {
            return true;
        }
        return false;
    },
);

/*
    * Check If user is remembered
    * Check if remembered and auth == false then redirect to login screen
*/
export const checkUserRemembered = (state) => {
    // const userProfile = getFirstLastName(state);
    const userProfile = getProfileInfo(state);
    const isAuthenticated = getIsAuthenticatedStatus(state);
    if (userProfile.email && !isAuthenticated) {
        return true;
    }
    return false;
};

/*
    * Show consent message to allow user to continue as guest or registered user
*/
export const showConcentForRememberUser = (state) => {
    const isRemembered = checkUserRemembered(state);
    const isRememberConsentShown = getRememberConsentStatus(state);
    if (isRemembered && !isRememberConsentShown) {
        return true;
    }
    return false;
};

export const getIsWriteScopeCheckout = (state) => {
    try {
        const isAccessBasedOnScopeEnabled = getFeatureFlag('is-access-based-on-scope-enabled')(state);
        if (!isAccessBasedOnScopeEnabled) {
            return true;
        }
        const accessToken = getAccessToken(state);
        const val = verifyScopeExist(accessToken, 'write:checkout');
        return val;
    } catch (e) {
        return false;
    }
};
