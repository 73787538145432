/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    takeEvery, call, put, fork, select,
} from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import * as profileActions from './Profile-Actions';
import * as profileActionTypes from './Profile-ActionTypes';
import {
    getTokenLocalStorage,
    setTokenLocalStorage,
} from '../Auth/helper/helper';
import { getShopRunnerToken } from '../Common/Common-Selectors';

function convertDateStringToDateTime(dateStr) {
    const splitDate = dateStr.split('/');
    if (splitDate.length === 3) {
        return (new Date(splitDate[2], splitDate[0], splitDate[1]) / 1000);
    }
    return null;
}

function* loadUserProfileDetails(action) {
    const { userInfo, accesstoken } = action.data;

    let userRole = '';
    let membershipType = 'G';
    const {
        XAtt3: passportFlag, XAtt4: passportStartDate, XAtt5: passportEndDate, loyaltyStatus,
    } = userInfo;

    if (passportFlag
        && passportFlag === '01-01'
        && passportStartDate
        && passportEndDate) {
        const startDateTime = convertDateStringToDateTime(passportStartDate);
        const endDateTime = convertDateStringToDateTime(passportEndDate);
        const nowDate = new Date();
        const todayDateTime = (new Date(nowDate.getFullYear(), nowDate.getMonth() + 1, nowDate.getDate()).getTime() / 1000);
        if (startDateTime
            && endDateTime
            && (todayDateTime >= startDateTime && todayDateTime <= endDateTime)) {
            userRole = 'P';
            membershipType = 'P';
        }
    }

    if (!userRole && userInfo?.email) {
        membershipType = 'R';
    }

    yield put(profileActions.setMembershipType(membershipType));

    const profile = {
        firstName: userInfo.firstName || '',
        lastName: userInfo.lastName || '',
        loginsCount: userInfo.loginsCount || '',
        contactId: userInfo.contactId || '',
        email: userInfo.email || '',
        passportFlag: userInfo.XAtt3 || '',
        passportStartDate: userInfo.XAtt4 || '',
        passportEndDate: userInfo.XAtt5 || '',
        loyaltyStatus,
        userRole,
        membershipType,
        offerGatewayOptin: userInfo.offerGatewayOptin,
    };

    yield put(profileActions.setProfileDetails(profile));
    yield call(setTokenLocalStorage, { token: accesstoken, profile }, 'userInfo');
}

function* updateUserProfileDetails(action) {
    const { userInfo: { profile, token } } = action.data;
    const cookies = new Cookies();
    const persistedUserProfile = cookies.get('persisted_user_profile');
    const newProfile = {
        ...persistedUserProfile, passportFlag: profile.passportFlag,
    };
    cookies.set('persisted_user_profile', newProfile);
    yield put(profileActions.setProfileDetails(profile));
    yield call(setTokenLocalStorage, { token, profile }, 'userInfo');
}

function* workerSetProfileUserRole(action) {
    let userRole = action.data;
    const shopRunnerToken = yield select(getShopRunnerToken);
    if (shopRunnerToken && userRole !== 'P') {
        userRole = 'S';
    }
    yield put(profileActions.loadProfileUserRole(userRole));
    yield put(profileActions.loadedProfileUserRole());
    if (userRole === '') {
        yield put(profileActions.loadProfileDetailsFromLocalStorage());
    }
}

function* workerUpdateEmailAddress(action) {
    const userInfoLocalStorage = yield call(getTokenLocalStorage, 'userInfo');
    if (userInfoLocalStorage) {
        const objUserInfo = JSON.parse(userInfoLocalStorage);
        objUserInfo.profile.email = action.data.emailId;
        yield call(setTokenLocalStorage, objUserInfo, 'userInfo');
        yield put(profileActions.setProfileEmailId(action.data.emailId));
    }
}

function* watchSetProfileUserRole() {
    yield takeEvery(profileActionTypes.SET_PROFILE_USER_ROLE, workerSetProfileUserRole);
}

function* watchUpdateEmailAddress() {
    yield takeEvery(profileActionTypes.SET_PROFILE_EMAIL_ADDRESS, workerUpdateEmailAddress);
}

function* watchLoadProfileDetails() {
    yield takeEvery(profileActions.loadProfileDetails().type, loadUserProfileDetails);
}

function* watchUpdateProfileDetails() {
    yield takeEvery(profileActions.updateUserProfileDetails().type, updateUserProfileDetails);
}

const watchers = [
    fork(watchLoadProfileDetails),
    fork(watchSetProfileUserRole),
    fork(watchUpdateEmailAddress),
    fork(watchUpdateProfileDetails),
];

export default {
    watchers,
};
