/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const SET_AUTH_DETAILS = 'MEMBER/AUTH/SET_AUTH_DETAILS';
export const SET_ACCESS_TOKEN = 'MEMBER/AUTH/SET_ACCESS_TOKEN';
export const LOG_LAST_VISITED_PAGE = 'MEMBER/AUTH/LOG_LAST_VISITED_PAGE';
export const LISTEN_ROUTE_CHANGES = 'MEMBER/AUTH/LISTEN_ROUTE_CHANGES';
export const TRIGGER_INTERSTITIAL_LOGIN = 'MEMBER/AUTH/TRIGGER_INTERSTITIAL_LOGIN';
export const CLEAR_AUTH_DEPENDENT_STATE = 'MEMBER/AUTH/CLEAR_AUTH_DEPENDENT_STATE';
export const LOG_GUEST_SESSION = 'MEMBER/AUTH/LOG_GUEST_SESSION';
export const LOG_USER_LOGGED_OUT = 'MEMBER/Auth/LOG_USER_LOGGED_OUT';
export const LOG_USER_LOGGED_IN_SUCCESS = 'MEMBER/AUTH/LOG_USER_LOGGED_IN_SUCCESS';
export const LOG_USER_LOGGED_IN_FAILED = 'MEMBER/AUTH/LOG_USER_LOGGED_IN_FAILED';
export const LOG_HYDRAID_USER_SESSION = 'MEMBER/AUTH/LOG_HYDRAID_USER_SESSION';
export const LOG_USER_SESSION_READY = 'MEMBER/AUTH/SESSION_READY';
export const LOG_USER_LOGIN_SESSION_READY = 'MEMBER/AUTH/LOGIN_SESSION_READY';
export const LOG_IS_USER_RECENTLY_LOGGED_OUT = 'MEMBER/AUTH/LOG_IS_USER_RECENTLY_LOGGED_OUT';
