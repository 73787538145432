/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    take, fork, call, put, select,
} from 'redux-saga/effects';

import mbpLogger from 'mbp-logger';

import * as tagManagerActions from './TagManager-Actions';
import * as tagManagerSelectors from './TagManager-Selectors';
import { getFeatureFlags } from '../../../App/ducks/Config/Config-Selectors';
import * as tagManagerHelpers from './TagManager-Helpers';
import * as sessionTrackingHelpers from '../../../../../app/helpers/tracking/session/sessionTrackingHelpers';
import * as pageDataLayerHelpers from '../../../../../app/helpers/tracking/page/pageDataLayerHelpers';
import * as checkoutSelectors from '../../../Checkout/Checkout-Selectors';
import { getUserType } from '../../../Checkout/ducks/Global/Global-Selectors';
import { getUserRole } from '../../../Member/ducks/Common/Common-Selectors';
import { getEnterpriseId } from '../../../Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import getCookie from '../../../../../app/helpers/common/storage/cookieStorage';
import {  productIdWithBaseCode } from '../../../../../app/helpers/tracking/product/dataLayerProductHelpers';
import useBrowserUUID from '../../../../../app/helpers/useBrowserUUID';

let isTriggerEventAdded = false;

// Handle session clean up rules
const cleanSessionOnComplete = ({
    featureFlags,
    pageType,
}) => {
    // Clear session attribution after Order Confirmation pageview
    if (featureFlags && featureFlags['is-attribution-clear-on-order-complete-enabled']) {
        if (pageType === 'thankyouconfirm') {
            sessionTrackingHelpers.clearSessionData();
        }
    }
};

function* buildUserData() {
    const memberProfile = yield select(tagManagerSelectors.getMemberProfile) || '';
    const userId = yield select(tagManagerSelectors.getMemberUserId) || '';
    const enterpriseId = yield select(getEnterpriseId);
    // determine R, G from userType - P from userRole -> set a single value as userType in DL
    const ut = yield select(getUserType);
    const userRole = yield select(getUserRole);
    let userType = userRole || ut || 'G';
    // allow userType to be overriden from persisted data
    const persistedUserProfile = getCookie('persisted_user_profile');
    if (userType === 'G' && persistedUserProfile?.membershipType && persistedUserProfile.membershipType !== 'G') {
        userType = persistedUserProfile.membershipType;
    }

    const user = {
        ...memberProfile,
        userType,
        userId,
        enterpriseId,
    };

    return user;
}

function* onTrackPageView(action) {
    mbpLogger.logDebug({
        action,
        appName: process.env.npm_package_name,
        module: 'mbp-tag-manager',
        function: 'onTrackPageView',
        message: 'START',
    });
    const { page, updateEveryRender } = action;
    const currentPageView = yield select(tagManagerSelectors.getCurrentPageView);
    const pageViewFired = yield select(tagManagerSelectors.getPageViewFiredState);

    mbpLogger.logDebug({
        currentPageView,
        appName: process.env.npm_package_name,
        module: 'mbp-tag-manager',
        function: 'onTrackPageView',
    });

    if (!pageViewFired // Fresh page view
        || (!currentPageView.pageType // or Fresh page type
        || (currentPageView.pageType !== page.type)) // or Different page type
        || updateEveryRender // Ignore the pageType and pageViewFired
    ) {
        const { brand } = page;
        const featureFlags = yield select(getFeatureFlags);

        // User data
        const user = yield call(buildUserData) || null;

        // Payment Details
        const paymentDetails = yield select(checkoutSelectors.getEPaymentDetails);

        // Process Raw data object
        const pageDataLayer = yield call(pageDataLayerHelpers.generatePageDataLayer, {
            session: {
                user,
                paymentDetails,
            },
            page: {
                ...page,
                flags: featureFlags,
            },
        });

        // TODO:: Flag will remove once it's confirm working
        if (featureFlags['is-product-id-consistent-events-enabled'] && (pageDataLayer?.page || '')?.indexOf?.('/checkout') < 0) {
            if (pageDataLayer?.products?.length) {
                pageDataLayer.products = productIdWithBaseCode(pageDataLayer?.products);
            }
        }
        if (typeof window !== 'undefined') {
            // a view key for optimize
            window.mbpDataLayerView = pageDataLayer;
            // if true add event listener / call and inject optimize script
            if (featureFlags['is-optimize-script-enabled']) {
                if (typeof document !== 'undefined') {
                    if (!window.mbpTrackEventFlag) {
                        document.addEventListener('mbpTrackEvent', (e) => {
                            tagManagerHelpers.trackEvent(e.detail); // will call utag.link(e.detail)
                        });
                        window.mbpTrackEventFlag = true;
                    }
                }
                window.dataLayer.push({ event: 'optimize.activate' }); // activation event for optimize
            }
            document.dispatchEvent(new CustomEvent('EventNewPageView', { detail: pageDataLayer }));
            if (!isTriggerEventAdded) {
                document.addEventListener('triggerTagManagerEvent', (e) => {
                    tagManagerHelpers.trackEvent(e.detail);
                });
                isTriggerEventAdded = true;
            }
        }

        // Pass data layer to vendor-specific (e.g. Tealium) pageView handler
        if (pageDataLayer) {
            // Fire PageDataLayerLoaded event
            tagManagerHelpers.firePageDataLayerBrowserEvents({
                pageDataLayer,
                delayedTimeout: brand['tag-manager-page-data-layer-event-timeout'] || 500,
            });

            const lhCheckFlag = featureFlags && featureFlags['lh-check'];
            // Get user role so tags can tell if customer has passport (userRole === P)
            const userRole = yield select(tagManagerSelectors.getUserRole);

            // Queued events
            const queuedEvents = yield select(tagManagerSelectors.getQueuedEvents);

            // console.log('TAG MANAGER OPs queuedEvents', queuedEvents);
            // Fire vendor track page view method
            yield call(tagManagerHelpers.trackPageView, {
                location: page.location,
                userRole,
                lhCheckFlag,
                featureFlags,
                queuedEvents,
                ...pageDataLayer,
            });

            // Set Current Page Name
            const { pageName, pageType } = pageDataLayer;

            if (pageName && pageType) {
                yield put(tagManagerActions.setCurrentPageView(pageName, pageType));
                yield put(tagManagerActions.trackPageViewComplete(true));

                // injectVendorScripts({
                //     featureFlags,
                //     pageType,
                // });

                cleanSessionOnComplete({
                    featureFlags,
                    pageType,
                });

                // Clear queued events
                if (queuedEvents.length) {
                    yield put(tagManagerActions.clearQueuedEvents());
                }
            } else {
                mbpLogger.logError({
                    pageDataLayer,
                    function: 'onTrackPageView',
                    appName: process.env.npm_package_name,
                    module: 'mbp-tag-manager',
                    message: 'onTrackPageView | Failed to set current page view state. Missing params (page name/type).',
                });
            }
        }
    }
}

function* onTrackEvent(trackEventPayload) {
    mbpLogger.logDebug({
        trackEventPayload,
        appName: process.env.npm_package_name,
        module: 'mbp-tag-manager',
        function: 'onTrackEvent',
        message: 'START',
    });

    const { dataLayer } = trackEventPayload;
    const { queued } = dataLayer;

    // copy track event obj payload
    const eventDataLayer = { ...dataLayer };
    const flagState = yield select(getFeatureFlags);

    // TODO:: Flag will remove once it's confirm working
    if (flagState['is-product-id-consistent-events-enabled']) {
        if (eventDataLayer?.products?.length) {
            eventDataLayer.products = productIdWithBaseCode(eventDataLayer?.products);
        }
    }
    // if empty, null, or undefined do not fire off tracker
    if (!eventDataLayer.eventAction || !eventDataLayer.eventCategory) {
        if (process.env.NODE_ENV === 'development') {
            console.log('No event action or event category present');
        }
        return null;
    }

    // Get user role so tags can tell if customer has passport (userRole === P)
    const userRole = yield select(tagManagerSelectors.getUserRole);

    // pull in pageType (i.e. homepage, category, product)
    const currentPageView = yield select(tagManagerSelectors.getCurrentPageView);

    // see if eventLabel requires a dynamic page name
    if (eventDataLayer
        && eventDataLayer.eventLabel
        && typeof eventDataLayer.eventLabel === 'string'
        && eventDataLayer.eventLabel.indexOf('pageType') !== -1) {
        // if page desc exists ex : Logon_From_Google | category
        if (eventDataLayer.eventLabel.indexOf('|') !== -1) {
            // split by the pipe and set label desc
            const eventLabelArr = eventDataLayer.eventLabel.split('|');
            const eventLabelDesc = eventLabelArr?.[0];
            // set label to desc and pagetype
            eventDataLayer.eventLabel = `${eventLabelDesc} | ${currentPageView.pageType}`;
        } else {
            eventDataLayer.eventLabel = currentPageView.pageType;
        }
    } else if (eventDataLayer
        && eventDataLayer.eventLabel
        && typeof eventDataLayer.eventLabel === 'string'
        && eventDataLayer.eventLabel.indexOf('Radio') !== -1
        && typeof window !== 'undefined') {
        eventDataLayer.eventLabel = window.location?.pathname.split('/')[2];
    }

    // see if eventAction requires a dynamic page name
    if (eventDataLayer
        && eventDataLayer.eventAction
        && typeof eventDataLayer.eventAction === 'string'
        && eventDataLayer.eventAction.indexOf('pageType') !== -1) {
        // if page desc exists ex : Logon_From_Google | category
        if (eventDataLayer.eventAction.indexOf('|') !== -1) {
            // split by the pipe and set action desc
            const eventActionArr = eventDataLayer.eventAction.split('|');
            const eventActionDesc = eventActionArr?.[0];
            // set action to desc and pagetype
            eventDataLayer.eventAction = `${eventActionDesc} | ${currentPageView.pageType}`;
        } else {
            eventDataLayer.eventAction = currentPageView.pageType;
        }
    } else if (eventDataLayer
        && eventDataLayer.eventAction
        && typeof eventDataLayer.eventAction === 'string'
        && eventDataLayer.eventAction.indexOf('Radio') !== -1
        && typeof window !== 'undefined') {
        eventDataLayer.eventAction = window.location?.pathname.split('/')[2];
    }
    if (eventDataLayer
        && eventDataLayer.eventCategory
        && typeof eventDataLayer.eventCategory === 'string'
        && eventDataLayer.eventCategory.indexOf('pageType') !== -1) {
        // if page desc exists ex : Logon_From_Google | category
        if (eventDataLayer.eventCategory.indexOf('|') !== -1) {
            // split by the pipe and set eventCategory desc
            const eventCatArr = eventDataLayer.eventCategory.split('|');
            const eventCatDesc = eventCatArr?.[0];
            // set eventCategory to desc and pagetype
            eventDataLayer.eventCategory = `${eventCatDesc} | ${currentPageView.pageType}`;
        } else {
            eventDataLayer.eventCategory = currentPageView.pageType;
        }
    } else if (eventDataLayer
        && eventDataLayer.eventCategory
        && typeof eventDataLayer.eventCategory === 'string'
        && eventDataLayer.eventCategory.indexOf('Radio') !== -1
        && typeof window !== 'undefined') {
        eventDataLayer.eventCategory = window.location?.pathname.split('/')[2];
    }

    eventDataLayer.browser_uuid = useBrowserUUID();

    if (typeof window !== 'undefined') {
        eventDataLayer.isUsCustomer = window.isUSCustomer;
    }

    const mergedDataLayer = { ...eventDataLayer, ...currentPageView, userRole };

    // Fire PageDataLayerLoaded
    if (typeof document !== 'undefined') {
        document.dispatchEvent(new CustomEvent('EventDataLayerLoaded', { detail: mergedDataLayer }));
    }

    // Fire Event
    let eventTracked = false;
    let eventQueued = false;
    // console.log('DEBUG: window.utag', window.utag);
    // console.log('DEBUG: window.pageViewFired', window.pageViewFired);
    if (queued || !window.pageViewFired) { // Add to queued events list
        // Append non-interaction flag
        mergedDataLayer.nonInteraction = '1';

        eventQueued = yield put(tagManagerActions.queueEvent(mergedDataLayer));
        // console.log('DEBUG: TAG MANAGER OPs event queued', mergedDataLayer.eventAction);
    } else { // Pass directly to 3rd party tag manager
        eventTracked = yield call(tagManagerHelpers.trackEvent, mergedDataLayer);
    }

    // Queue Success
    if (eventQueued) {
        yield put(tagManagerActions.queuedEventComplete());
    }

    // Track Success
    if (eventTracked) {
        yield put(tagManagerActions.trackEventComplete());
    }

    return (eventTracked || eventQueued); // 0 or 1
}

function* watchTrackPageView() {
    let action = yield take(tagManagerActions.trackPageView().type);

    mbpLogger.logDebug({
        action,
        appName: process.env.npm_package_name,
        module: 'mbp-tag-manager',
        function: 'watchTrackPageView',
        message: 'watcher for page view tracking',
    });

    while (action !== END) {
        yield fork(onTrackPageView, action.payload);
        action = yield take(tagManagerActions.trackPageView().type);
    }
}

function* watchTrackEvent() {
    let action = yield take(tagManagerActions.trackEvent().type);

    mbpLogger.logDebug({
        action,
        appName: process.env.npm_package_name,
        module: 'mbp-tag-manager',
        function: 'watchTrackEvent',
        message: 'watcher for event tracking',
    });

    while (action !== END) {
        yield fork(onTrackEvent, action.payload);
        action = yield take(tagManagerActions.trackEvent().type);
    }
}

const watchers = [
    fork(watchTrackPageView),
    fork(watchTrackEvent),
];

export {
    watchers,

    onTrackEvent,
    onTrackPageView,

    buildUserData,
};
