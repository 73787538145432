/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const twilioStyles = {
    // Twilio chatbot
    chatbotErrorColor: '#d11313',
    chatbotErrorHoverColor: '#f53636',
    chatbotBubbleContainerColor: '#f5f8fa',
    chatbotMessageInputColor: '#ECEDF1',
};

const brandsConfig = {
    '18F': {
        id: '1001',
        code: '18F',
        domain: '1800flowers', // brand's web domain - string in the middle of  https://www.1800flowers.com
        storeId: 20051, // brand's unique store ID
        catalogId: 13302, // brands have many catalogs,but web has one unique catalog
        description: '1-800-Flowers', // simple description of brand used for alt text
        other: {
            order_service_email: '',
        },
        giftMessageConfig: {
            giftMessageMaxLines: 1,
            giftMessageMaxChars: 180,
            excludeFromTextLabel: false,
            signatureMaxChars: 14,
        },
        bloomreach: {
            account_id: null,
            domain_key: null,
            request_id: null,
            url: null,
            view_id: null,
        },
        typography: {
            fontFamily: [
                'Area Variable, LatoMedium',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},
        blockHeading: {},
        overrides: {},

        presentation_family: 'flower',

        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#5f3c86',
                900: '#6f5092',
                800: '#7f639e',
                700: '#8f77aa',
                600: '#9f8ab6',
                500: '#af9ec3',
                400: '#bfb1cf',
                300: '#cfc5db',
                200: '#dfd8e7',
                100: '#efecf3',
                contrastText: '#fff',
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#d9f5dc',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#dedede',
                green: '#6E9930',
                red: '#d90059',
                blue: '#371A6E',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: 'rgba(0, 0, 0, 0.87)',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                hint: 'rgba(0, 0, 0, 0.38)',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '52px',
            celebrationsColor: '#7ac3a6',
            passportColor: '#2d7dba',

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#65388b', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#e8eaec',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            colorAlert: '#f71548',
            contentColor: '#666666',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            colorValid: '#24b200',

            black: '#1f1f1b',
            white: '#fff',
            borderLeft: '#52287e',
            backgroundColor: '#f9f6f6',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#f2f2f2',
            bgHeader: '#65388b',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#EBEBEB',
            bigBoldLabel: '#1f1f1b',
            colorIconHeader: '#000',
            contentHeading: '#6e9930',
            cardPagebackground: '#ebeadd',
            colorHeadingSympathy: '#70548f',
            deliverySentence: '#52287e',
            buttonBackgroundColor: '#333',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            OBHeadingColor: '#663399',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: '#1f1f1b',
            globalBannerTextColor: 'white',
            select: '#646464',
            globalBannerTextDecoration: 'underline',
            linkMenu: '#324765',
            orderConfirmationHeader: '#1f1f1b',
            orderConfirmationText: 'black',
            rewardColor: '#00488a',
            primaryButton: '#1bc86d',
            successButton: '#1bc86d',
            submit: '#333333',
            productLine: '#BDBBBB',
            modalBorder: '#ececec',
            productTitleColor: '#1f1f1b',
            productAvailibilityPDP: '#a1001a',
            sectionHeaderTextColor: '#212121',
            secondaryButton: '#324765',
            bgChatMessage: '#663789',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#660000',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(48, 69, 98, .85)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                promoSection: {
                    marginDesktop: 16,
                    marginMobile: 16,
                    borderRadius: '3px',
                    textPadding: '10px',
                    paddingTextMobile: '10px',
                },
            },
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#a8a89a',
            },
            desktopHeaderMenu: {
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            mobileMenuColor: '#fff',
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#65388b',
            checkRadioBtn: '#65388b',
            copyright: '#606060',
            mobileFooterBottomLink: '#65388b',
            desktopBgFooter: '#65388b',
            modalBg: '#65388b',
            modalTile: '#65388b',

            cta1: '#1f1f1b', // DEPRECATED
            cta2: '#47201D', // DEPRECATED
            cta3: '#660000', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#47201D', // DEPRECATED
            brown2: '#DBD0C2', // DEPRECATED
            brown3: '#DBD0C2', // DEPRECATED
            grey1: '#1f1f1b', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#a8a89a', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#ebebde', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#b6b5b5', // DEPRECATED

            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '50px auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '44px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '240px',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '186px',
                },
            },
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '0px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            // celebrations Rewards
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes Page
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Listeners Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#65388b',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#fff',
            salePriceColor: '#a1101a',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#F4F2F7',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: '#E8F1ED',
            slideOutMiniCartFont: 'Area Variable, LatoMedium, sans-serif',
            ctaBgColorNup4: '#bca1c6',
            ctaColorNup4: '#ffffff',
            ctaBorderClrNup4: '#bca1c6',
            earliestDeliveryColor: '#21571D',
        },
        implement_delivery_method: true,
    },
    HD: {
        id: '1019',
        code: 'HD',
        giftMessageConfig: {
            giftMessageMaxLines: 3,
            giftMessageMaxChars: 29,
            excludeFromTextLabel: true,
            signatureMaxChars: 24,
        },
        bloomreach: {
            account_id: '5027',
            domain_key: '1800flowers_com',
            request_id: '4548275533545',
            url: 'harryanddavid_com',
            view_id: 'Harry & David Brand',
        },
        domain: 'harryanddavid', // brand's web domain - string in the middle of  https://www.1800flowers.com
        storeId: 21051, // brand's unique store ID
        catalogId: 16802, // brands have many catalogs,but web has one unique catalog
        description: 'Harry & David', // simple description of brand used for alt text
        other: {
            order_service_email: 'service@harryanddavid.com',
        },
        typography: {
            // Its a default font family that can used throughout the app
            fontFamily: [
                'lato',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
            secondaryFontFamily: [
                'Roboto',
                'Helvetica',
                'Arial',
                'sans-serif',
            ].join(','),
            heading: {
                fontFamily: 'Crimson, Garamond, Times, serif',
                fontSize: {
                    desktop: '1.5rem',
                    mobile: '1.25rem',
                },
            },
            cta: {
                fontFamily: 'latobold, Arial, Verdana, sans-serif',
                fontSize: {
                    // These sizes are from the UX team
                    sm: '1rem',
                    md: '1.25rem',
                    lg: '1.5rem',
                },
            },
            mobileMenu: {
                fontFamily: 'LatoRegular, Arial, Verdana, sans-serif',
            },
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},
        block_heading: {},
        presentation_family: 'food',

        overrides: {},
        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#2f2f2f',
                900: '#444444',
                800: '#595959',
                700: '#6d6d6d',
                600: '#828282',
                500: '#595959',
                // 500: '#979797',
                400: '#acacac',
                300: '#c1c1c1',
                200: '#d5d5d5',
                100: '#ededed',
                contrastText: '#ffffff', // Made to default contrast color over
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#a1001a',
                900: '#aa1a31',
                800: '#b43348',
                700: '#bd4d5f',
                600: '#c76676',
                500: '#do808d',
                400: '#d999a3',
                300: '#e3b3ba',
                200: '#ecccd1',
                100: '#f6e6e8',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#a1001a',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: '#2f2f2f',
                secondary: '#757575',
                disabled: '#B7B7B7',
                hint: '#000000',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '51px',
            navSecondaryHeight: '30px',
            celebrationsColor: '#7ac3a6',
            passportColor: '#2d7dba',
            colorPrimaryAccount: '#a1001a',  // Used by css baseline to set GL account primary
            colorSecondaryAccount: '#790000',  // Used by css baseline to set GL account secondary

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#1f1f1b', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            colorAlert: '#f71548',
            colorAlertContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            cardPagebackground: '#ebeadd',
            colorSuccess: '#43a047',
            contentHeading: '#6e9930',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            colorValid: '#24b200',
            borderLeft: '#52287e',
            black: '#1f1f1b',
            white: '#fff',

            backgroundColor: '#f9f6f6',
            buttonBackgroundColor: '#333',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#f2f2f2',
            bgHeader: 'white',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#2f2f2f',
            bigBoldLabel: '#595959',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#E8F1ED',
            colorHeadingSympathy: '#70548f',
            colorIconHeader: '#000',
            bgChatMessage: '#2f2f2f',
            desktopHeaderMenu: {  // TODO: replace this with brand's real values
                background: '#f4f2f7',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
                minWidth: '768px',
                width: '100%',
            },
            deliverySentence: '#52287e',
            productAvailibilityPDP: '#a1001a',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            OBHeadingColor: '#663399',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: 'black',
            globalBannerTextColor: 'white',
            globalBannerTextDecoration: 'none',
            linkMenu: '#595959',
            productLine: '#BDBBBB',
            modalBorder: '#ececec',
            select: '#646464',
            orderConfirmationHeader: '#595959',
            orderConfirmationText: 'black',
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                salePrice: '#a1001a',
                // For now we are pulling these colors from content stack
                // skus: { // only for HD use
                //     availableToShipTextColor: '#999999',
                //     skuBorderColor: '#c6b66f',
                //     priceDiscountColor: '#506985',
                //     checkedRadioColor: '#ff9900',
                //     uncheckedRadioColor: '#f5f8fa',
                //     radioHoverColor: '#ebf1f5',
                //     skuLabelAmountColor: '#7C7C7C',
                // },
            },
            rewardColor: '#00488a',
            primaryButton: '#a1001a',
            successButton: '#a1001a',
            productTitleColor: '#2f2f2f',
            sectionHeaderTextColor: '#202020',
            secondaryButton: '#595959',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#3c3c35',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(255,255,255,0.82)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            submit: '#333333',
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#595959',
            checkRadioBtn: '#a1001a',
            copyright: '#747472',
            mobileMenuColor: '#000',
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#909090',
            },
            mobileFooterBottomLink: '#747472',
            desktopBgFooter: '#F4F2F7',
            modalBg: '#f2f2f2',
            modalTile: '#f2f2f2',

            cta1: '#2f2f2f', // DEPRECATED
            cta2: '#a94', // DEPRECATED
            cta3: '#3c3c35', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#5b322c', // DEPRECATED
            brown2: '#311714', // DEPRECATED
            brown3: '#600600', // DEPRECATED
            brown4: '#d9d0c1', // DEPRECATED
            brown5: 'rgba(255,255,255,0.82)', // DEPRECATED
            brown6: '#ded8d8', // DEPRECATED
            grey1: '#2f2f2f', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#909090', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#e3e3e3', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#595959', // DEPRECATED
            grey11: '#e4e4e4', // DEPRECATED

            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '0 auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '44px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '99%',
                    height: 'auto',
                },
                Medium: {
                    width: '100%',
                    maxWidth: '340px',
                    height: '100%',
                    minHeight: '1px',
                },
                Small: {
                    width: '100%',
                },
                Large: {
                    width: '100%',
                },
            },
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#FF0000',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#525252',
            contentColor: '#666666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            // celebrations Rewards
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',

            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#2f2f2f',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#fff',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',

            upsellsBackground: '#e8f1ec',
            upsellsTextColor: '#2F2F2F',
            upsellsFutureAvailabilityColor: '#A2011A',
            upsellsHeaderFonts: 'Crimson Text',
            filterDropdownHoverColor: '#E8F1ED',
            highlightColor: '#E8F1ED',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: '#E8F1ED',
            slideOutMiniCartFont: 'LatoRegular, Arial, Verdana, sans-serif',
            slideOutMiniCartFlashSaleFont: 'LatoBold, Arial, Verdana, sans-serif',
            slideOutMiniCartMainColor: '#2f2f2f',
            ctaBgColorNup4: '#ffffff',
            ctaColorNup4: '#2f2f2f',
            ctaBorderClrNup4: '#2f2f2f',
            earliestDeliveryColor: '#2B553B',
        },
        excludeSEO: [
            '/gifthistory-home-gift-baskets',
            '/About-Us-Privacy-Policy',
            '/h/view/about01',
        ],
        implement_delivery_method: false,
    },
    '18B': {
        id: '1009',
        code: '18B',
        giftMessageConfig: {
            giftMessageMaxLines: 3,
            giftMessageMaxChars: 29,
            excludeFromTextLabel: true,
            signatureMaxChars: 24,
        },
        bloomreach: {
            account_id: null,
            domain_key: null,
            request_id: null,
            url: null,
            view_id: null,
        },
        domain: '1800baskets',
        storeId: 20054,
        catalogId: 13802,
        description: '1-800-Baskets',
        other: {
            order_service_email: '',
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        blockHeading: {},
        presentation_family: 'food',
        palette: {
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#dedede',
                green: '#6E9930',
                red: '#a1001a',
            },
            navHeight: '53px',
            product: {
                name: '#1f1f1b',
                retailPrice: '#1f1f1b',
                crossedRetailPrice: '#1f1f1b',
                salePrice: '#a1001a',
            },
            // replacement keys for clarity and versatility
            colorPrimary: '#9c0352', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',

            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            colorAlert: '#a1001a',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',

            // existing acceptable values
            black: '#1f1f1b',
            white: '#fff',
            bgFooter: '#42434b',
            bgHeader: '#FFFFFF',
            bgPhoneIcon: '#1bc86d',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#F5F5F5',
            bigBoldLabel: '#9c0352',
            colorIconHeader: '#000',
            productAvailibilityPDP: '#a1001a',
            disableButton: '#d8d8d8',
            error: { main: '#ff0000', guidedNav: '#f71449' },
            gfBgColor: '#9872bb',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: '#9c0352',
            globalBannerTextColor: 'white',
            globalBannerTextDecoration: 'underline',
            linkMenu: '#ce046b',
            orderConfirmationHeader: '#9c0352',
            orderConfirmationText: 'black',
            primary: { 500: '#9c0352' },
            rewardColor: '#00488a',
            primaryButton: '#1bc86d',
            successButton: '#1bc86d',
            productTitleColor: '#1f1f1b',
            sectionHeaderTextColor: '#202020',
            secondaryButton: '#324765',
            clubsTypebackground: '#F2F0EC',
            cardPagebackground: '#ebeadd',
            buttonBackgroundColor: '#333',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            bgChatMessage: '#9c0352',
            shape: {
                borderRadius: '4px',
            },
            snipeText: 'white',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(206,4,107,0.7)',
            snipeTextFontSize: 12,
            snipeTextFontWeight: 300,
            guidedNav: {
                background: '#FFF',
            },
            tickCheck: '#00CC33',
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                salePrice: '#a1001a',
            },
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#a8a89a',
            },
            mobileMenuColor: '#000',
            desktopHeaderMenu: {
                // TODO: replace this with brand's real values
                background: '#f4f2f7',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#8E1429',
            checkRadioBtn: '#8E1429',
            copyright: '#000',
            mobileFooterBottomLink: '#000',
            desktopBgFooter: '#ececec',
            modalBg: '#ececec',
            modalTile: '#5b322c',
            celebrationsColor: '#7ac3a6',
            passportColor: '#2d7dba',

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // OK to modify values as long as replacement is updated as well (unless specifically noted)
            pink1: '#8E1429', // DEPRECATED
            brown1: '#47201D', // DEPRECATED
            brown2: '#DBD0C2', // DEPRECATED
            brown3: '#DBD0C2', // DEPRECATED
            brown5: 'rgba(255,255,255,0.82)', // this is weirdly named >> MenuItem.js class = toggleHeader
            cta1: '#9c0352', // DEPRECATED
            cta2: '#47201D', // DEPRECATED
            cta3: '#660000', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            grey1: '#1f1f1b', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#a8a89a', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#ebebde', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#b6b5b5', // DEPRECATED

            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fafafa',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '100%',
                        upLarge: '95%',
                    },
                },
            },
            faqBgColor: '#ffeffb',
            availableMessageColor: '#666',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            contentColor: '#666666',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#fff',
            salePriceColor: '#a1001a',
            crDividerColor: '#cecece',
            nup: {
                margin: '30px auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    fontWeight: '400',
                    fontSize: '26px',
                    letterSpacing: '.4px',
                },
                Standard: {
                    width: '200px',
                    height: 'auto',
                },
                Medium: {
                    width: '300px',
                    height: 'auto',
                },
                Small: {
                    width: '186px',
                },
            },
            genericBanner: {
                margin: 'auto auto 25px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: 'auto',
                textAlign: 'center',
            },
            checkoutHeaderBg: '#ffffff',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#F5F5F5',
            highlightColor: '#F5F5F5',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: '#F5F5F5',
            slideOutMiniCartFont: 'Helvetica, Arial, sans-serif',
            continueShoppingHoverBg: '#43a047',
            continueShoppingBg: '#43a047',
            ctaBgColorNup4: '#ffffff',
            ctaColorNup4: '#2f2f2f',
            ctaBorderClrNup4: '#2f2f2f',
            earliestDeliveryColor: '#3A6A26',
        },
        typography: {
            useNextVariants: true,
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontSize: 14,
        },
        excludeSEO: [
            'About-Us-Privacy-Policy',
            'About-Us-Terms-of-Use',
            'passport-baskets',
            'themarketboxlandingpage',
        ],
        implement_delivery_method: false,
    },
    CCO: {
        id: '1012',
        code: 'CCO',
        giftMessageConfig: {
            giftMessageMaxLines: 4,
            giftMessageMaxChars: 30,
            excludeFromTextLabel: true,
            signatureMaxChars: 24,
        },
        bloomreach: {
            account_id: null,
            domain_key: null,
            request_id: null,
            url: null,
            view_id: null,
        },
        domain: 'cheryls', // brand's web domain - string in the middle of  https://www.1800flowers.com
        storeId: 10202, // brand's unique store ID
        catalogId: 11102, // brands have many catalogs,but web has one unique catalog
        description: 'Cheryl\'s Cookies', // simple description of brand used for alt text
        other: {
            order_service_email: 'customerservice@cheryls.com',
        },
        typography: {
            fontFamily: [
                'Helvetica',
                'Arial',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
            pdpSkuLabel: {
                fontSize: '0.938em',
            },
            heading: {
                fontFamily: 'NeutraBold, Helvetica, Arial, sans-serif;',
                fontSize: {
                    desktop: '1.5rem',
                    mobile: '1.25rem',
                },
            },
            cta: {
                fontFamily: 'NeutraBold, Helvetica, Arial, sans-serif;',
                fontSize: {
                    sm: '1rem',
                    md: '1.25rem',
                    lg: '1.5rem',
                },
            },
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},
        blockHeading: {},
        overrides: {},

        presentation_family: 'food',

        palette: {
            product: {
                name: '#1f1f1b',
                retailPrice: '#1f1f1b',
                crossedRetailPrice: '#1f1f1b',
                salePrice: '#a1001a',
            },
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#2f2f2f',
                900: '#444444',
                800: '#595959',
                700: '#6d6d6d',
                600: '#828282',
                500: '#595959',
                // 500: '#979797',
                400: '#acacac',
                300: '#c1c1c1',
                200: '#d5d5d5',
                100: '#ededed',
                contrastText: '#fff',
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#a1001a',
                900: '#aa1a31',
                800: '#b43348',
                700: '#bd4d5f',
                600: '#c76676',
                500: '#do808d',
                400: '#d999a3',
                300: '#e3b3ba',
                200: '#ecccd1',
                100: '#f6e6e8',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: '#2f2f2f',
                secondary: '#757575',
                disabled: '#B7B7B7',
                hint: '#000000',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '30px',
            colorPrimaryAccount: '#522d23',  // Used by css baseline to set GL account primary
            colorSecondaryAccount: '#790000',  // Used by css baseline to set GL account secondary

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#522d23', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',

            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            colorAlert: '#a1001a',
            colorAlertContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            colorSuccess: '#43a047',
            contentHeading: '#6e9930',
            cardPagebackground: '#ebeadd',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            borderLeft: '#52287e',
            black: '#1f1f1b',
            white: '#fff',

            backgroundColor: '#f9f6f6',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#42434b',
            bgHeader: '#fff',
            bgPhoneIcon: '#1bc86d',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#43201c',
            bgTile2: '#ff0',
            yotpoRatingColor: '#522d23',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#E9F7F7',
            bigBoldLabel: '#5b322c',
            colorHeadingSympathy: '#70548f',
            colorIconHeader: '#000',
            bgChatMessage: '#542d24',
            desktopHeaderMenu: {  // TODO: replace this with brand's real values
                background: '#efefef',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            deliverySentence: '#52287e',
            buttonBackgroundColor: '#333',
            productAvailibilityPDP: '#a1001a',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            OBHeadingColor: '#663399',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: 'black',
            globalBannerTextColor: 'white',
            globalBannerTextDecoration: 'none',
            linkMenu: '#595959',
            orderConfirmationHeader: '#595959',
            orderConfirmationText: 'black',
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#3a0704',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                skus: { // only for HD use
                    availableToShipTextColor: '#999999',
                    skuBorderColor: '#c6b66f',
                    priceDiscountColor: '#506985',
                    checkedRadioColor: '#ff9900',
                    uncheckedRadioColor: '#f5f8fa',
                    radioHoverColor: '#ebf1f5',
                    skuLabelAmountColor: '#7C7C7C',
                },
                salePrice: '#a1001a',
            },
            rewardColor: '#00488a',
            primaryButton: '#e80a4d',
            successButton: '#1bc86d',
            productTitleColor: '#2f2f2f',
            sectionHeaderTextColor: '#202020',
            secondaryButton: '#595959',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#3c3c35',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(255,255,255,0.82)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#595959',
            checkRadioBtn: '#a1001a',
            copyright: '#000',
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#909090',
            },
            mobileMenuColor: '#000',
            mobileFooterBottomLink: '#747472',
            desktopBgFooter: '#fcf8f0',
            modalBg: '#f2f2f2',
            modalTile: '#f2f2f2',
            celebrationsColor: '#7ac3a6',
            passportColor: '#2d7dba',

            cta1: '#2f2f2f', // DEPRECATED
            cta2: '#a94', // DEPRECATED
            cta3: '#3c3c35', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#5b322c', // DEPRECATED
            brown2: '#311714', // DEPRECATED
            brown3: '#600600', // DEPRECATED
            brown4: '#d9d0c1', // DEPRECATED
            brown5: 'rgba(255,255,255,0.82)', // DEPRECATED
            brown6: '#ded8d8', // DEPRECATED
            grey1: '#2f2f2f', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#909090', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#e3e3e3', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#595959', // DEPRECATED
            grey11: '#e4e4e4', // DEPRECATED

            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#fff',

            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '0 auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '240px',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '186px',
                },
                Large: {
                    width: '100%',
                    height: 'auto',
                },
            },
            genericBanner: {
                margin: 'auto auto 20px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            contentColor: '#666666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            // celebrations Rewards
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            checkoutHeaderBg: '#522d23',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#E9F7F7',
            highlightColor: '#E9F7F7',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: '#E9F7F7',
            slideOutMiniCartFont: 'ArcherRegular, Helvetica, Arial, sans-serif',
            ctaBgColorNup4: '#ffffff',
            ctaColorNup4: '#2f2f2f',
            ctaBorderClrNup4: '#2f2f2f',
            earliestDeliveryColor: '#5F3823',
        },
        excludeSEO: [
            '/gifthistory-home-gift-baskets',
            '/About-Us-Privacy-Policy',
            '/h/view/about01',
        ],
        implement_delivery_method: false,
    },
    TPF: {
        id: '1002',
        code: 'TPF',
        giftMessageConfig: {
            giftMessageMaxLines: 4,
            giftMessageMaxChars: 30,
            excludeFromTextLabel: true,
            signatureMaxChars: 24,
        },
        bloomreach: {
            account_id: null,
            domain_key: null,
            request_id: null,
            url: null,
            view_id: null,
        },
        domain: 'thepopcornfactory', // brand's web domain - string in the middle of  https://www.1800flowers.com
        storeId: 10201, // brand's unique store ID
        catalogId: 10101, // brands have many catalogs,but web has one unique catalog
        description: 'The Popcorn Factory', // simple description of brand used for alt text
        presentation_family: 'food',
        other: {
            order_service_email: 'customerservice@thepopcornfactory.com',
        },
        typography: {
            // Its a default font family that can used throughout the app
            fontFamily: [
                'lato',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
            secondaryFontFamily: [
                'Trebuchet MS',
                'Arial',
                'Helvetica',
                'sans-serif',
            ].join(','),
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},
        blockHeading: {},
        overrides: {},
        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#2f2f2f',
                900: '#444444',
                800: '#595959',
                700: '#6d6d6d',
                600: '#828282',
                500: '#595959',
                400: '#acacac',
                300: '#c1c1c1',
                200: '#d5d5d5',
                100: '#ededed',
                contrastText: '#ffffff', // Made to default contrast color over
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#a1001a',
                900: '#aa1a31',
                800: '#b43348',
                700: '#bd4d5f',
                600: '#c76676',
                500: '#do808d',
                400: '#d999a3',
                300: '#e3b3ba',
                200: '#ecccd1',
                100: '#f6e6e8',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: '#000000',
                secondary: '#757575',
                disabled: '#B7B7B7',
                hint: '#000000',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#1f1f1b', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#444', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            colorAlert: '#f71548',
            colorAlertContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            cardPagebackground: '#ebeadd',
            colorSuccess: '#43a047',
            contentHeading: '#6e9930',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            borderLeft: '#52287e',
            black: '#1f1f1b',
            white: '#fff',

            backgroundColor: '#f9f6f6',
            buttonBackgroundColor: '#b01116',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#f2f2f2',
            bgHeader: 'white',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#2f2f2f',
            bigBoldLabel: '#595959',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#FBEFD8',
            colorHeadingSympathy: '#70548f',
            colorIconHeader: '#000',
            productAvailibilityPDP: '#a1001a',
            bgChatMessage: '#b01116',
            desktopHeaderMenu: {  // TODO: replace this with brand's real values
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            deliverySentence: '#52287e',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            OBHeadingColor: '#663399',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: 'black',
            globalBannerTextColor: 'white',
            globalBannerTextDecoration: 'none',
            linkMenu: '#595959',
            orderConfirmationHeader: '#595959',
            orderConfirmationText: 'black',
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#b01116',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                salePrice: '#b01116',
                // For now we are pulling these colors from content stack
                // skus: { // only for HD use
                //     availableToShipTextColor: '#999999',
                //     skuBorderColor: '#c6b66f',
                //     priceDiscountColor: '#506985',
                //     checkedRadioColor: '#ff9900',
                //     uncheckedRadioColor: '#f5f8fa',
                //     radioHoverColor: '#ebf1f5',
                //     skuLabelAmountColor: '#7C7C7C',
                // },
            },
            rewardColor: '#00488a',
            primaryButton: '#b01116',
            successButton: '#1bc86d',
            productTitleColor: '#2f2f2f',
            sectionHeaderTextColor: '#202020',
            secondaryButton: '#595959',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#3c3c35',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(255,255,255,0.82)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            submit: '#333333',
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#595959',
            checkRadioBtn: '#a1001a',
            copyright: '#747472',
            mobileMenuColor: '#000',
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#909090',
            },
            mobileFooterBottomLink: '#747472',
            desktopBgFooter: '#ffffff',
            modalBg: '#f2f2f2',
            modalTile: '#f2f2f2',
            celebrationsColor: '#7ac3a6',
            passportColor: '#2d7dba',

            cta1: '#2f2f2f', // DEPRECATED
            cta2: '#a94', // DEPRECATED
            cta3: '#3c3c35', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#5b322c', // DEPRECATED
            brown2: '#311714', // DEPRECATED
            brown3: '#600600', // DEPRECATED
            brown4: '#d9d0c1', // DEPRECATED
            brown5: 'rgba(255,255,255,0.82)', // DEPRECATED
            brown6: '#ded8d8', // DEPRECATED
            grey1: '#2f2f2f', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#909090', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#e3e3e3', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#595959', // DEPRECATED
            grey11: '#e4e4e4', // DEPRECATED

            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '0 auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '20px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '240px',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '100%',
                },
            },
            genericBanner: {
                margin: '0px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            contentColor: '#666666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            // celebrations Rewards
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',

            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            homePageTextColor: '#5b322c',
            productPriceColor: '#666',
            footermainHeading: '#000099',
            checkoutHeaderBg: '#b13716',
            secureCheckoutLogo: '',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#FDF9EF',
            highlightColor: '#FDF9EF',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: '#FAF1E3',
            slideOutMiniCartFont: 'Krub, Arial, Helvetica, sans-serif',
            slideOutMiniCartFlashSaleFont: 'Krub, Arial, Helvetica, sans-serif',
            slideOutMiniCartMainColor: '#1f1f1b',
            ctaBgColorNup4: '#ffffff',
            ctaColorNup4: '#2f2f2f',
            ctaBorderClrNup4: '#2f2f2f',
            earliestDeliveryColor: '#4D7886',
        },
        excludeSEO: [
            '/gifthistory-home-gift-baskets',
            '/About-Us-Privacy-Policy',
            '/h/view/about01',
        ],
        implement_delivery_method: false,
    },
    SCH: {
        id: '1025',
        code: 'SCH',
        giftMessageConfig: {
            giftMessageMaxLines: 3,
            giftMessageMaxChars: 29,
            excludeFromTextLabel: true,
            signatureMaxChars: 24,
        },
        bloomreach: {
            account_id: null,
            domain_key: null,
            request_id: null,
            url: null,
            view_id: null,
        },
        domain: 'simplychocolate', // brand's web domain - string in the middle of  https://www.1800flowers.com
        storeId: 24052, // brand's unique store ID
        catalogId: 20803, // brands have many catalogs,but web has one unique catalog
        description: 'simply Chocolate', // simple description of brand used for alt text
        other: {
            order_service_email: '',
        },
        typography: {
            fontFamily: [
                'GothamBook',
                'Arial',
                'Helvetica',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},
        blockHeading: {},
        overrides: {},

        presentation_family: 'food',

        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#A1662C',
                900: '#444444',
                800: '#595959',
                700: '#6d6d6d',
                600: '#828282',
                500: '#595959',
                // 500: '#979797',
                400: '#acacac',
                300: '#c1c1c1',
                200: '#d5d5d5',
                100: '#ededed',
                contrastText: '#000',
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#512C1C',
                900: '#aa1a31',
                800: '#b43348',
                700: '#bd4d5f',
                600: '#c76676',
                500: '#do808d',
                400: '#d999a3',
                300: '#e3b3ba',
                200: '#ecccd1',
                100: '#f6e6e8',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: '#512c1c',
                secondary: '#757575',
                disabled: '#B7B7B7',
                hint: '#000000',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '30px',

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#512c1d', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',

            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants

            colorInfo: '',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            colorAlert: '#a1001a',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            contentHeading: '#6e9930',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            borderLeft: '#52287e',
            black: '#1f1f1b',
            white: '#fff',

            backgroundColor: '#f9f6f6',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#42434b',
            bgHeader: '#fff',
            bgPhoneIcon: '#1bc86d',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#43201c',
            bgTile2: '#ff0',
            yotpoRatingColor: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#FAF7F4',
            bigBoldLabel: '#5b322c',
            colorHeadingSympathy: '#70548f',
            colorIconHeader: '#000',
            bgChatMessage: '#512c1d',
            desktopHeaderMenu: {  // TODO: replace this with brand's real values
                background: '#efefef',
                text: '#333',
                border: 'none',
                maxWidth: '1400px',
            },
            deliverySentence: '#52287e',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            OBHeadingColor: '#663399',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: 'black',
            globalBannerTextColor: 'white',
            globalBannerTextDecoration: 'none',
            linkMenu: '#595959',
            orderConfirmationHeader: '#595959',
            orderConfirmationText: 'black',
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#A1662C',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                skus: { // only for HD use
                    availableToShipTextColor: '#999999',
                    skuBorderColor: '#c6b66f',
                    priceDiscountColor: '#506985',
                    checkedRadioColor: '#ff9900',
                    uncheckedRadioColor: '#f5f8fa',
                    radioHoverColor: '#ebf1f5',
                    skuLabelAmountColor: '#7C7C7C',
                },
                salePrice: '#a1001a',
            },
            rewardColor: '#00488a',
            primaryButton: '#A1662C',
            successButton: '#A1662C',
            productTitleColor: '#2f2f2f',
            sectionHeaderTextColor: '#202020',
            secondaryButton: '#595959',
            clubsTypebackground: '#F2F0EC',
            cardPagebackground: '#ebeadd',
            buttonBackgroundColor: '#333',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#3c3c35',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(255,255,255,0.82)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#595959',
            checkRadioBtn: '#a1001a',
            copyright: '#000',
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#909090',
            },
            mobileMenuColor: '#000',
            mobileFooterBottomLink: '#747472',
            desktopBgFooter: '#512c1d',
            modalBg: '#f2f2f2',
            modalTile: '#f2f2f2',

            cta1: '#2f2f2f', // DEPRECATED
            cta2: '#a94', // DEPRECATED
            cta3: '#3c3c35', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#5b322c', // DEPRECATED
            brown2: '#311714', // DEPRECATED
            brown3: '#600600', // DEPRECATED
            brown4: '#d9d0c1', // DEPRECATED
            brown5: 'rgba(255,255,255,0.82)', // DEPRECATED
            brown6: '#ded8d8', // DEPRECATED
            grey1: '#2f2f2f', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#909090', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#e3e3e3', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#595959', // DEPRECATED
            grey11: '#e4e4e4', // DEPRECATED

            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#fff',
            salePriceColor: '#a1001a',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '100%',
                        upLarge: '1400px',
                    },
                },
            },
            nup: {
                margin: '30px auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '240px',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '186px',
                },
                Large: {
                    width: '100%',
                    height: 'auto',
                },
            },
            genericBanner: {
                margin: 'auto auto 20px auto',
            },
            heroSingle: {
                margin: '0px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            // Sympathy Helpful Resource
            availableMessageColor: '#666',
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            contentColor: '#666666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            // celebrations Rewards
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            checkoutHeaderBg: '#ffffff',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#F6F0EA',
            highlightColor: '#F6F0EA',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: '#F5F5F5',
            slideOutMiniCartFont: 'GothamBook, Arial, Helvetica, sans-serif',
            slideOutMiniCartFlashSaleFont: 'GothamBook, Arial, Helvetica, sans-serif',
            slideOutMiniCartMainColor: '#512c1c',
            ctaBgColorNup4: '#ffffff',
            ctaColorNup4: '#2f2f2f',
            ctaBorderClrNup4: '#2f2f2f',
            earliestDeliveryColor: '#5F2900',
        },
        excludeSEO: [
            '/gifthistory-home-gift-baskets',
            '/About-Us-Privacy-Policy',
            '/h/view/about01',
        ],
        implement_delivery_method: false,
    },
    SY: {
        id: '1018',
        code: 'SY',
        giftMessageConfig: {
            giftMessageMaxLines: 3,
            giftMessageMaxChars: 29,
            excludeFromTextLabel: false,
            signatureMaxChars: 18,
        },
        bloomreach: {
            account_id: null,
            domain_key: null,
            request_id: null,
            url: null,
            view_id: null,
        },
        domain: 'stockyards', // brand's web domain - string in the middle of  https://www.1800flowers.com
        storeId: 22051, // brand's unique store ID
        catalogId: 17802, // brands have many catalogs,but web has one unique catalog
        description: 'Stock Yards', // simple description of brand used for alt text
        other: {
            order_service_email: 'myorder@stockyards.com',
        },
        typography: {
            // Its a default font family that can used throughout the app
            fontFamily: [
                'lato',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
            secondaryFontFamily: [
                'Roboto',
                'Helvetica',
                'Arial',
                'sans-serif',
            ].join(','),
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},
        blockHeading: {},
        presentation_family: 'food',

        overrides: {},
        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#2f2f2f',
                900: '#444444',
                800: '#595959',
                700: '#6d6d6d',
                600: '#828282',
                500: '#595959',
                // 500: '#979797',
                400: '#acacac',
                300: '#c1c1c1',
                200: '#d5d5d5',
                100: '#ededed',
                contrastText: '#ffffff', // Made to default contrast color over
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#a1001a',
                900: '#aa1a31',
                800: '#b43348',
                700: '#bd4d5f',
                600: '#c76676',
                500: '#do808d',
                400: '#d999a3',
                300: '#e3b3ba',
                200: '#ecccd1',
                100: '#f6e6e8',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: '#2f2f2f',
                secondary: '#757575',
                disabled: '#B7B7B7',
                hint: '#000000',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '51px',

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#3c3c35', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#3c3c35', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',

            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            colorAlert: '#a1001a',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',

            // existing acceptable values
            black: '#1f1f1b',
            white: '#fff',
            bgFooter: '#f2f2f2',
            bgHeader: 'white',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#F5F5F5',
            bigBoldLabel: '#5b322c',
            colorIconHeader: '#000',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: '#1f1f1b',
            globalBannerTextColor: 'white',
            globalBannerTextDecoration: 'underline',
            linkMenu: '#000',
            orderConfirmationHeader: '#5b322c',
            orderConfirmationText: '#5b322c',
            rewardColor: '#00488a',
            primaryButton: '#a1001a',
            productTitleColor: '#3c3c35',
            sectionHeaderTextColor: '#202020',
            secondaryButton: '#1f1f1b',
            clubsTypebackground: '#F2F0EC',
            cardPagebackground: '#ebeadd',
            buttonBackgroundColor: '#333',
            bgChatMessage: '#3c3c35',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#3c3c35',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(255,255,255,0.82)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                salePrice: '#7c2529',
            },
            mobileMenuBG: {
                header: '#3f3c3c',
                main: '#fff',
                product: '#fff',
                alternate: '#3f3c3c',
                nonProduct: '#3f3c3c', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#3f3c3c',
            },
            mobileMenuColor: '#000000',
            desktopHeaderMenu: {  // TODO: replace this with brand's real values
                background: '#f4f2f7',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#595959',
            checkRadioBtn: '#a1001a',
            copyright: '#1f1f1b',
            mobileFooterBottomLink: '#1f1f1b',
            desktopBgFooter: '#f2f2f2',
            modalBg: '#f2f2f2',
            modalTile: '#1f1f1b',

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // OK to modify values as long as replacement is updated as well (unless specifically noted)
            cta1: '#3c3c35', // DEPRECATED
            cta2: '#47201D', // DEPRECATED
            cta3: '#660000', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#47201D', // DEPRECATED
            brown2: '#DBD0C2', // DEPRECATED
            brown3: '#DBD0C2', // DEPRECATED
            grey1: '#1f1f1b', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#a8a89a', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#ebebde', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#595959', // DEPRECATED

            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },

            nup: {
                margin: '0 auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '44px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '99%',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '100%',
                },
            },
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            contentColor: '#666666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            // celebrations Rewards
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',

            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#231f20',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#fff',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#F5F5F5',
            highlightColor: '#F5F5F5',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: '#F5F5F5',
            slideOutMiniCartFont: 'hind_siliguriregular, sans-serif',
            ctaBgColorNup4: '#ffffff',
            ctaColorNup4: '#2f2f2f',
            ctaBorderClrNup4: '#2f2f2f',
            earliestDeliveryColor: '#2B553B',
        },
        excludeSEO: [
            '/gifthistory-home-gift-baskets',
            '/About-Us-Privacy-Policy',
            '/s/view/about01',
        ],
        implement_delivery_method: false,
    },
    WLF: {
        id: '1020',
        code: 'WLF',
        giftMessageConfig: {
            giftMessageMaxLines: 3,
            giftMessageMaxChars: 29,
            excludeFromTextLabel: true,
            signatureMaxChars: 18,
        },
        bloomreach: {
            account_id: '5027',
            domain_key: '1800flowers_com',
            request_id: '4548275533545',
            url: 'wolfermans_com',
            view_id: 'Wolferman\'s Brand',
        },
        domain: 'wolfermans', // brand's web domain - string in the middle of  https://www.1800flowers.com
        storeId: 21551, // brand's unique store ID
        catalogId: 17302, // brands have many catalogs,but web has one unique catalog
        description: 'Wolferman', // simple description of brand used for alt text
        other: {
            order_service_email: 'service@wolfermans.com',
        },
        typography: {
            // Its a default font family that can used throughout the app
            fontFamily: [
                'assistantregular',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
            secondaryFontFamily: [
                'loraitalic',
                'Verdana',
                'Arial',
                'sans-serif',
            ].join(','),
            heading: {
                fontFamily: '"Lora Regular", Arial, Verdana, sans-serif',
                fontSize: {
                    desktop: '1.75rem',
                    mobile: '1.25rem',
                },
            },
            cta: {
                fontFamily: 'AssistantBold, sans-serif',
                fontSize: {
                    sm: '1rem',
                    md: '1.25rem',
                    lg: '1.5rem',
                },
            },
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},
        blockHeading: {},
        presentation_family: 'food',

        overrides: {},
        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#5c5757',
                900: '#444444',
                800: '#595959',
                700: '#6d6d6d',
                600: '#828282',
                500: '#595959',
                // 500: '#979797',
                400: '#acacac',
                300: '#c1c1c1',
                200: '#d5d5d5',
                100: '#ededed',
                contrastText: '#ffffff', // Made to default contrast color over
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#a1001a',
                900: '#aa1a31',
                800: '#b43348',
                700: '#bd4d5f',
                600: '#c76676',
                500: '#do808d',
                400: '#d999a3',
                300: '#e3b3ba',
                200: '#ecccd1',
                100: '#f6e6e8',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: '#1f1f1b',
                secondary: '#757575',
                disabled: '#B7B7B7',
                hint: '#000000',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '51px',

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#5c5757', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            colorAlert: '#f71548',
            colorAlertContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            cardPagebackground: '#ebeadd',
            colorSuccess: '#43a047',
            contentHeading: '#6e9930',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            colorValid: '#24b200',
            borderLeft: '#52287e',
            black: '#1f1f1b',
            white: '#fff',

            backgroundColor: '#f9f6f6',
            buttonBackgroundColor: '#333',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#f2f2f2',
            bgHeader: 'white',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#2f2f2f',
            bigBoldLabel: '#595959',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#E9F1EF',
            colorHeadingSympathy: '#70548f',
            colorIconHeader: '#000',
            bgChatMessage: '#5c5757',
            desktopHeaderMenu: {  // TODO: replace this with brand's real values
                background: '#f4f2f7',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            deliverySentence: '#52287e',
            productAvailibilityPDP: '#a1001a',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            OBHeadingColor: '#663399',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: 'black',
            globalBannerTextColor: 'white',
            globalBannerTextDecoration: 'none',
            linkMenu: '#595959',
            productLine: '#BDBBBB',
            modalBorder: '#ececec',
            select: '#4b5d6e',
            orderConfirmationHeader: '#595959',
            orderConfirmationText: 'black',
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#4b5d6e',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                salePrice: '#b71f2e',
                // For now we are pulling these colors from content stack
                // skus: { // only for HD use
                //     availableToShipTextColor: '#999999',
                //     skuBorderColor: '#c6b66f',
                //     priceDiscountColor: '#506985',
                //     checkedRadioColor: '#ff9900',
                //     uncheckedRadioColor: '#f5f8fa',
                //     radioHoverColor: '#ebf1f5',
                //     skuLabelAmountColor: '#7C7C7C',
                // },
            },
            rewardColor: '#00488a',
            primaryButton: '#a1001a',
            successButton: '#a1001a',
            productTitleColor: '#2f2f2f',
            sectionHeaderTextColor: '#202020',
            secondaryButton: '#595959',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#3c3c35',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(255,255,255,0.82)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            submit: '#333333',
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#595959',
            checkRadioBtn: '#a1001a',
            copyright: '#747472',
            mobileMenuColor: '#000',
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#909090',
            },
            mobileFooterBottomLink: '#747472',
            desktopBgFooter: '#f2f2f2',
            modalBg: '#f2f2f2',
            modalTile: '#f2f2f2',

            cta1: '#2f2f2f', // DEPRECATED
            cta2: '#a94', // DEPRECATED
            cta3: '#3c3c35', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#5b322c', // DEPRECATED
            brown2: '#311714', // DEPRECATED
            brown3: '#600600', // DEPRECATED
            brown4: '#d9d0c1', // DEPRECATED
            brown5: 'rgba(255,255,255,0.82)', // DEPRECATED
            brown6: '#ded8d8', // DEPRECATED
            grey1: '#2f2f2f', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#909090', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#e3e3e3', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#595959', // DEPRECATED
            grey11: '#e4e4e4', // DEPRECATED

            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '0 auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '44px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '99%',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '100%',
                },
            },
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#FF0000',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#525252',
            contentColor: '#666666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            // celebrations Rewards
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',

            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#5c5757',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#E9F1EF',
            highlightColor: '#E9F1EF',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: 'rgba(201, 220, 214, 0.4)',
            slideOutMiniCartFont: 'assistantregular, sans-serif',
            slideOutMiniCartFlashSaleFont: 'AssistantBold, sans-serif',
            slideOutMiniCartMainColor: '#1f1f1b',
            ctaBgColorNup4: '#ffffff',
            ctaColorNup4: '#2f2f2f',
            ctaBorderClrNup4: '#2f2f2f',
            earliestDeliveryColor: '#356050',
        },
        excludeSEO: [
            '/gifthistory-home-gift-baskets',
            '/About-Us-Privacy-Policy',
            '/h/view/about01',
        ],
        implement_delivery_method: false,
    },
    FB: {
        id: '1010',
        code: 'FB',
        domain: 'fruitbouquets',
        storeId: 20551,
        catalogId: 16302,
        description: 'Fruit Bouquets',
        other: {
            order_service_email: '',
        },
        giftMessageConfig: {
            giftMessageMaxLines: 1,
            giftMessageMaxChars: 180,
            excludeFromTextLabel: false,
            signatureMaxChars: 14,
        },
        typography: {
            fontFamily: [
                'Roboto',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},
        blockHeading: {},
        overrides: {},

        presentation_family: 'flower',

        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#5f3c86',
                900: '#6f5092',
                800: '#7f639e',
                700: '#8f77aa',
                600: '#9f8ab6',
                500: '#af9ec3',
                400: '#bfb1cf',
                300: '#cfc5db',
                200: '#dfd8e7',
                100: '#efecf3',
                contrastText: '#fff',
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#B68D1B',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#d9f5dc',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: 'rgba(0, 0, 0, 0.87)',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                hint: 'rgba(0, 0, 0, 0.38)',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '52px',

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#7340a0', // replaces cta1
            colorPrimaryContrast: '#fff', // DOES NOT PASS WCAG AA/AAA (with #FFF contrast,only fails WCAG AAA with #000),
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            colorAlert: '#f71548',
            contentColor: '#666666',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            colorValid: '#24b200',

            black: '#1f1f1b',
            white: '#fff',
            borderLeft: '#52287e',
            backgroundColor: '#f9f6f6',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#f2f2f2',
            bgHeader: '#65388b',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#EBEBEB',
            bigBoldLabel: '#1f1f1b',
            colorIconHeader: '#000',
            contentHeading: '#6e9930',
            cardPagebackground: '#ebeadd',
            colorHeadingSympathy: '#70548f',
            deliverySentence: '#52287e',
            buttonBackgroundColor: '#333',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            OBHeadingColor: '#663399',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: '#1f1f1b',
            globalBannerTextColor: 'white',
            select: '#646464',
            globalBannerTextDecoration: 'underline',
            linkMenu: '#324765',
            orderConfirmationHeader: '#1f1f1b',
            orderConfirmationText: 'black',
            rewardColor: '#00488a',
            primaryButton: '#1bc86d',
            successButton: '#1bc86d',
            submit: '#33333',
            productLine: '#BDBBBB',
            modalBorder: '#ececec',
            productTitleColor: '#1f1f1b',
            productAvailibilityPDP: '#a1001a',
            sectionHeaderTextColor: '#212121',
            secondaryButton: '#324765',
            bgChatMessage: '#7340a0',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#660000',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(48, 69, 98, .85)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
            },
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#a8a89a',
                checkoutAddonBackground: '#fff',
                checkoutAddonColor: '#000',
            },
            desktopHeaderMenu: {
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            mobileMenuColor: '#fff',
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#65388b',
            checkRadioBtn: '#65388b',
            copyright: '#606060',
            mobileFooterBottomLink: '#65388b',
            desktopBgFooter: '#ededeb',
            modalBg: '#65388b',
            modalTile: '#65388b',

            cta1: '#1f1f1b', // DEPRECATED
            cta2: '#47201D', // DEPRECATED
            cta3: '#660000', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#47201D', // DEPRECATED
            brown2: '#DBD0C2', // DEPRECATED
            brown3: '#DBD0C2', // DEPRECATED
            grey1: '#1f1f1b', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#a8a89a', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#ebebde', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#b6b5b5', // DEPRECATED

            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#7c7c7d',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#7c7c7d',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '50px auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '44px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '240px',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '186px',
                },
            },
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            // celebrations Rewards
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes Page
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#156639',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Listeners Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#65388b',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#000',
            salePriceColor: '#a1001a',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#F5F5F5',
            highlightColor: '#EBEBEB',
            earliestDeliveryColor: '#196410',
        },
        implement_delivery_method: true,
    },
    FBQ: {
        id: '1010',
        code: 'FB',
        domain: 'fruitbouquets',
        storeId: 20551,
        catalogId: 16302,
        description: 'Fruit Bouquets',
        other: {
            order_service_email: '',
        },
        giftMessageConfig: {
            giftMessageMaxLines: 1,
            giftMessageMaxChars: 180,
            excludeFromTextLabel: false,
            signatureMaxChars: 14,
        },
        typography: {
            fontFamily: [
                'Roboto',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},
        blockHeading: {},
        overrides: {},

        presentation_family: 'flower',

        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#5f3c86',
                900: '#6f5092',
                800: '#7f639e',
                700: '#8f77aa',
                600: '#9f8ab6',
                500: '#af9ec3',
                400: '#bfb1cf',
                300: '#cfc5db',
                200: '#dfd8e7',
                100: '#efecf3',
                contrastText: '#fff',
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#d9f5dc',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: 'rgba(0, 0, 0, 0.87)',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                hint: 'rgba(0, 0, 0, 0.38)',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '52px',

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#7340a0', // replaces cta1
            colorPrimaryContrast: '#fff', // DOES NOT PASS WCAG AA/AAA (with #FFF contrast,only fails WCAG AAA with #000),
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            colorAlert: '#f71548',
            contentColor: '#666666',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            colorValid: '#24b200',

            black: '#1f1f1b',
            white: '#fff',
            borderLeft: '#52287e',
            backgroundColor: '#f9f6f6',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#f2f2f2',
            bgHeader: '#65388b',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#EBEBEB',
            bigBoldLabel: '#1f1f1b',
            colorIconHeader: '#000',
            contentHeading: '#6e9930',
            cardPagebackground: '#ebeadd',
            colorHeadingSympathy: '#70548f',
            deliverySentence: '#52287e',
            buttonBackgroundColor: '#333',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            OBHeadingColor: '#663399',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: '#1f1f1b',
            globalBannerTextColor: 'white',
            select: '#646464',
            globalBannerTextDecoration: 'underline',
            linkMenu: '#324765',
            orderConfirmationHeader: '#1f1f1b',
            orderConfirmationText: 'black',
            rewardColor: '#00488a',
            primaryButton: '#1bc86d',
            successButton: '#1bc86d',
            submit: '#33333',
            productLine: '#BDBBBB',
            modalBorder: '#ececec',
            productTitleColor: '#1f1f1b',
            productAvailibilityPDP: '#a1001a',
            sectionHeaderTextColor: '#212121',
            secondaryButton: '#324765',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#660000',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(48, 69, 98, .85)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                salePrice: '#a1001a',
            },
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#a8a89a',
            },
            desktopHeaderMenu: {
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            mobileMenuColor: '#fff',
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#65388b',
            checkRadioBtn: '#65388b',
            copyright: '#606060',
            mobileFooterBottomLink: '#65388b',
            desktopBgFooter: '#ededeb',
            modalBg: '#65388b',
            modalTile: '#65388b',

            cta1: '#1f1f1b', // DEPRECATED
            cta2: '#47201D', // DEPRECATED
            cta3: '#660000', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#47201D', // DEPRECATED
            brown2: '#DBD0C2', // DEPRECATED
            brown3: '#DBD0C2', // DEPRECATED
            grey1: '#1f1f1b', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#a8a89a', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#ebebde', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#b6b5b5', // DEPRECATED

            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '50px auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '44px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '240px',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '186px',
                },
            },
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            // celebrations Rewards
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes Page
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Listeners Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#65388b',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#000',
            salePriceColor: '#a1001a',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#F5F5F5',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: 'rgba(201, 220, 214, 0.4)',
            slideOutMiniCartFont: 'Roboto, sans-serif',
            ctaBgColorNup4: '#ffffff',
            ctaColorNup4: '#2f2f2f',
            ctaBorderClrNup4: '#2f2f2f',
            earliestDeliveryColor: '#196410',
        },
        implement_delivery_method: true,
    },
    BRY: {
        id: '1029',
        code: 'BRY',
        domain: 'berries',
        storeId: 25052,
        catalogId: 22303,
        description: 'Sharis Berries',
        presentation_family: 'flower',
        other: {
            order_service_email: '',
        },
        giftMessageConfig: {
            giftMessageMaxLines: 1,
            giftMessageMaxChars: 180,
            excludeFromTextLabel: false,
            signatureMaxChars: 14,
        },
        breakpoints: {},
        overrides: {},
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        blockHeading: {},
        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#223970',
                900: '#6f5092',
                800: '#7f639e',
                700: '#8f77aa',
                600: '#9f8ab6',
                500: '#af9ec3',
                400: '#bfb1cf',
                300: '#cfc5db',
                200: '#dfd8e7',
                100: '#efecf3',
                contrastText: '#fff',
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#d9f5dc',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: 'rgba(0, 0, 0, 0.87)',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                hint: 'rgba(0, 0, 0, 0.38)',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '52px',

            // replacement keys for clarity and versatility
            colorPrimary: '#D03E38', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            colorAlert: '#f71548',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            // existing acceptable values
            black: '#1f1f1b',
            white: '#fff',
            bgFooter: '#f2f2f2',
            bgHeader: '#000000',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#FFEDED',
            bigBoldLabel: '#1f1f1b',
            colorIconHeader: '#000',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: '#1f1f1b',
            globalBannerTextColor: 'white',
            globalBannerTextDecoration: 'underline',
            linkMenu: '#324765',
            orderConfirmationHeader: '#1f1f1b',
            orderConfirmationText: 'black',
            rewardColor: '#00488a',
            primaryButton: '#1bc86d',
            successButton: '#1bc86d',
            productTitleColor: '#1f1f1b',
            sectionHeaderTextColor: '#212121',
            secondaryButton: '#324765',
            bgChatMessage: '#223970',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#660000',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(48, 69, 98, .85)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                salePrice: '#b6002f',
            },
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#a8a89a',
            },
            desktopHeaderMenu: {
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#65388b',
            checkRadioBtn: '#65388b',
            copyright: '#606060',
            mobileFooterBottomLink: '#65388b',
            desktopBgFooter: '#65388b',
            modalBg: '#65388b',
            modalTile: '#65388b',
            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // OK to modify values as long as replacement is updated as well (unless specifically noted)
            cta1: '#1f1f1b', // DEPRECATED
            cta2: '#47201D', // DEPRECATED
            cta3: '#660000', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#47201D', // DEPRECATED
            brown2: '#DBD0C2', // DEPRECATED
            brown3: '#DBD0C2', // DEPRECATED
            grey1: '#1f1f1b', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#a8a89a', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#ebebde', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#b6b5b5', // DEPRECATED
            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },

            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorInfo: '#1976d2',
            clubsTypebackground: '#F2F0EC',
            contentColor: '#666666',
            colorValid: '#24b200',

            borderLeft: '#52287e',
            backgroundColor: '#f9f6f6',
            borderBottom: '1px dotted #7e7e7e',
            contentHeading: '#6e9930',
            cardPagebackground: '#ebeadd',
            colorHeadingSympathy: '#70548f',
            deliverySentence: '#52287e',
            buttonBackgroundColor: '#333',
            OBHeadingColor: '#663399',
            select: '#646464',
            submit: '#33333',
            productLine: '#BDBBBB',
            modalBorder: '#ececec',
            productAvailibilityPDP: '#a1001a',
            mobileMenuColor: '#fff',
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '0 auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            // celebrations Rewards
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes Page
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Listeners Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#65388b',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#fff',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#F5F5F5',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: 'rgba(201, 220, 214, 0.4)',
            slideOutMiniCartFont: 'Helvetica,Arial,sans-serif',
            ctaBgColorNup4: '#ffffff',
            ctaColorNup4: '#2f2f2f',
            ctaBorderClrNup4: '#2f2f2f',
            earliestDeliveryColor: '#156400',
        },
        typography: {
            fontFamily: [
                'Helvetica',
                'Arial',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 15,
        },
    },
    TMP: {
        id: '1027',
        code: 'TMP',
        domain: 'goodsey',
        storeId: 24552,
        catalogId: 21303,
        description: 'TheMarket',
        presentation_family: 'flower',
        other: {
            order_service_email: '',
        },
        giftMessageConfig: {
            giftMessageMaxLines: 3,
            giftMessageMaxChars: 30,
            excludeFromTextLabel: true,
            signatureMaxChars: 24,
        },
        breakpoints: {},
        overrides: {},
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        blockHeading: {},
        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#5f3c86',
                900: '#6f5092',
                800: '#7f639e',
                700: '#8f77aa',
                600: '#9f8ab6',
                500: '#af9ec3',
                400: '#bfb1cf',
                300: '#cfc5db',
                200: '#dfd8e7',
                100: '#efecf3',
                contrastText: '#fff',
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#d9f5dc',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: 'rgba(0, 0, 0, 0.87)',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                hint: 'rgba(0, 0, 0, 0.38)',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '52px',

            // replacement keys for clarity and versatility
            colorPrimary: '#D03E38', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            colorAlert: '#f71548',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            // existing acceptable values
            black: '#1f1f1b',
            white: '#fff',
            bgFooter: '#f2f2f2',
            bgHeader: '#000000',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#EBEBEB',
            bigBoldLabel: '#1f1f1b',
            colorIconHeader: '#000',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: '#1f1f1b',
            globalBannerTextColor: 'white',
            globalBannerTextDecoration: 'underline',
            linkMenu: '#324765',
            orderConfirmationHeader: '#1f1f1b',
            orderConfirmationText: 'black',
            rewardColor: '#00488a',
            primaryButton: '#1bc86d',
            successButton: '#1bc86d',
            productTitleColor: '#1f1f1b',
            sectionHeaderTextColor: '#212121',
            secondaryButton: '#324765',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#660000',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(48, 69, 98, .85)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                salePrice: '#b6002f',
            },
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#a8a89a',
            },
            desktopHeaderMenu: {
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#65388b',
            checkRadioBtn: '#65388b',
            copyright: '#606060',
            mobileFooterBottomLink: '#65388b',
            desktopBgFooter: '#65388b',
            modalBg: '#65388b',
            modalTile: '#65388b',
            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // OK to modify values as long as replacement is updated as well (unless specifically noted)
            cta1: '#1f1f1b', // DEPRECATED
            cta2: '#47201D', // DEPRECATED
            cta3: '#660000', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#47201D', // DEPRECATED
            brown2: '#DBD0C2', // DEPRECATED
            brown3: '#DBD0C2', // DEPRECATED
            grey1: '#1f1f1b', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#a8a89a', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#ebebde', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#b6b5b5', // DEPRECATED
            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },

            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorInfo: '#1976d2',
            clubsTypebackground: '#F2F0EC',
            contentColor: '#666666',
            colorValid: '#24b200',

            borderLeft: '#52287e',
            backgroundColor: '#f9f6f6',
            borderBottom: '1px dotted #7e7e7e',
            contentHeading: '#6e9930',
            cardPagebackground: '#ebeadd',
            colorHeadingSympathy: '#70548f',
            deliverySentence: '#52287e',
            buttonBackgroundColor: '#333',
            OBHeadingColor: '#663399',
            select: '#646464',
            submit: '#33333',
            productLine: '#BDBBBB',
            modalBorder: '#ececec',
            productAvailibilityPDP: '#a1001a',
            mobileMenuColor: '#fff',
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            // celebrations Rewards
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes Page
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Listeners Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#65388b',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#fff',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#F5F5F5',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: 'rgba(201, 220, 214, 0.4)',
            ctaBgColorNup4: '#ffffff',
            ctaColorNup4: '#2f2f2f',
            ctaBorderClrNup4: '#2f2f2f',
            earliestDeliveryColor: '#156400',
        },
        typography: {
            fontFamily: [
                'Helvetica',
                'Arial',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 15,
        },
    },
    HLN: {
        id: '1026',
        code: 'HLN',
        domain: 'goodsey',
        storeId: 20053,
        catalogId: 18302,
        description: 'TheMarket',
        presentation_family: 'flower',
        other: {
            order_service_email: '',
        },
        breakpoints: {},
        overrides: {},
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        blockHeading: {},
        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#5f3c86',
                900: '#6f5092',
                800: '#7f639e',
                700: '#8f77aa',
                600: '#9f8ab6',
                500: '#af9ec3',
                400: '#bfb1cf',
                300: '#cfc5db',
                200: '#dfd8e7',
                100: '#efecf3',
                contrastText: '#fff',
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#d9f5dc',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: 'rgba(0, 0, 0, 0.87)',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                hint: 'rgba(0, 0, 0, 0.38)',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '52px',

            // replacement keys for clarity and versatility
            colorPrimary: '#D03E38', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            colorAlert: '#f71548',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            // existing acceptable values
            black: '#1f1f1b',
            white: '#fff',
            bgFooter: '#f2f2f2',
            bgHeader: '#000000',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#EBEBEB',
            bigBoldLabel: '#1f1f1b',
            colorIconHeader: '#000',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: '#1f1f1b',
            globalBannerTextColor: 'white',
            globalBannerTextDecoration: 'underline',
            linkMenu: '#324765',
            orderConfirmationHeader: '#1f1f1b',
            orderConfirmationText: 'black',
            rewardColor: '#00488a',
            primaryButton: '#1bc86d',
            successButton: '#1bc86d',
            productTitleColor: '#1f1f1b',
            sectionHeaderTextColor: '#212121',
            secondaryButton: '#324765',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#660000',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(48, 69, 98, .85)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                salePrice: '#b6002f',
            },
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#a8a89a',
            },
            desktopHeaderMenu: {
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#65388b',
            checkRadioBtn: '#65388b',
            copyright: '#606060',
            mobileFooterBottomLink: '#65388b',
            desktopBgFooter: '#65388b',
            modalBg: '#65388b',
            modalTile: '#65388b',
            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // OK to modify values as long as replacement is updated as well (unless specifically noted)
            cta1: '#1f1f1b', // DEPRECATED
            cta2: '#47201D', // DEPRECATED
            cta3: '#660000', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#47201D', // DEPRECATED
            brown2: '#DBD0C2', // DEPRECATED
            brown3: '#DBD0C2', // DEPRECATED
            grey1: '#1f1f1b', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#a8a89a', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#ebebde', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#b6b5b5', // DEPRECATED
            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },

            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorInfo: '#1976d2',
            clubsTypebackground: '#F2F0EC',
            contentColor: '#666666',
            colorValid: '#24b200',

            borderLeft: '#52287e',
            backgroundColor: '#f9f6f6',
            borderBottom: '1px dotted #7e7e7e',
            contentHeading: '#6e9930',
            cardPagebackground: '#ebeadd',
            colorHeadingSympathy: '#70548f',
            deliverySentence: '#52287e',
            buttonBackgroundColor: '#333',
            OBHeadingColor: '#663399',
            select: '#646464',
            submit: '#33333',
            productLine: '#BDBBBB',
            modalBorder: '#ececec',
            productAvailibilityPDP: '#a1001a',
            mobileMenuColor: '#fff',
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            // celebrations Rewards
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes Page
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Listeners Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#65388b',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#fff',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#F5F5F5',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: 'rgba(201, 220, 214, 0.4)',
            ctaBgColorNup4: '#ffffff',
            ctaColorNup4: '#2f2f2f',
            ctaBorderClrNup4: '#2f2f2f',
            earliestDeliveryColor: '#156400',
        },
        typography: {
            fontFamily: [
                'Helvetica',
                'Arial',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 15,
        },
    },
    PMA: {
        id: '1030',
        code: 'PMA',
        domain: 'flowers', // temporal while CMS create the PMALL
        storeId: 20051, // brand's unique store ID
        catalogId: 13302,
        description: 'Personalization Mall',
        presentation_family: 'flower',
        other: {
            order_service_email: '',
        },
    },
    PML: {
        id: '1030',
        code: 'PML',
        fsOrgId: null,
        domain: 'personalizationmall',
        storeId: null,
        catalogId: null,
        description: 'Personalization Mall',
        presentation_family: 'personalization', // it will be control by CS
        giftMessageConfig: {
            giftMessageMaxLines: 1,
            giftMessageMaxChars: 180,
            excludeFromTextLabel: true,
            signatureMaxChars: 14,
        },
        bloomreach: {
            account_id: null,
            domain_key: null,
            request_id: null,
            url: null,
            view_id: null,
        },
        other: {
            order_service_email: 'customerservice@personalizationmall.com',
        },
        typography: {
            // Its a default font family that can used throughout the app
            fontFamily: [
                'Helvetica',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
            secondaryFontFamily: [
                'Helvetica',
                'sans-serif',
            ].join(','),
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},
        blockHeading: {},
        overrides: {},
        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#2f2f2f',
                900: '#444444',
                800: '#595959',
                700: '#6d6d6d',
                600: '#828282',
                500: '#595959',
                400: '#acacac',
                300: '#c1c1c1',
                200: '#d5d5d5',
                100: '#ededed',
                contrastText: '#ffffff', // Made to default contrast color over
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#a1001a',
                900: '#aa1a31',
                800: '#b43348',
                700: '#bd4d5f',
                600: '#c76676',
                500: '#do808d',
                400: '#d999a3',
                300: '#e3b3ba',
                200: '#ecccd1',
                100: '#f6e6e8',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: '#000000',
                secondary: '#757575',
                disabled: '#B7B7B7',
                hint: '#000000',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#ae201f', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#444', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            colorAlert: '#f71548',
            colorAlertContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            cardPagebackground: '#ebeadd',
            colorSuccess: '#43a047',
            contentHeading: '#6e9930',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            borderLeft: '#52287e',
            black: '#1f1f1b',
            white: '#fff',

            backgroundColor: '#f9f6f6',
            buttonBackgroundColor: '#ae201f',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#f2f2f2',
            bgHeader: 'white',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#2f2f2f',
            bigBoldLabel: '#595959',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#EBEBEB',
            colorHeadingSympathy: '#70548f',
            colorIconHeader: '#000',
            productAvailibilityPDP: '#ae201f',
            desktopHeaderMenu: {  // TODO: replace this with brand's real values
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            deliverySentence: '#52287e',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            OBHeadingColor: '#663399',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: 'black',
            globalBannerTextColor: 'white',
            globalBannerTextDecoration: 'none',
            linkMenu: '#595959',
            orderConfirmationHeader: '#595959',
            orderConfirmationText: 'black',
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#b01116',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                salePrice: '#ae201f',
            },
            rewardColor: '#00488a',
            primaryButton: '#ae201f',
            successButton: '#1bc86d',
            productTitleColor: '#2f2f2f',
            sectionHeaderTextColor: '#202020',
            secondaryButton: '#595959',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#3c3c35',
            snipeTextPromo: '#ae201f',
            snipeTextBackground: 'rgba(255,255,255,0.82)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            submit: '#333333',
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#595959',
            checkRadioBtn: '#ae201f',
            copyright: '#747472',
            mobileMenuColor: '#000',
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#909090',
            },
            mobileFooterBottomLink: '#747472',
            desktopBgFooter: '#ffffff',
            modalBg: '#f2f2f2',
            modalTile: '#f2f2f2',
            celebrationsColor: '#7ac3a6',
            passportColor: '#2d7dba',

            cta1: '#2f2f2f', // DEPRECATED
            cta2: '#a94', // DEPRECATED
            cta3: '#3c3c35', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#5b322c', // DEPRECATED
            brown2: '#311714', // DEPRECATED
            brown3: '#600600', // DEPRECATED
            brown4: '#d9d0c1', // DEPRECATED
            brown5: 'rgba(255,255,255,0.82)', // DEPRECATED
            brown6: '#ded8d8', // DEPRECATED
            grey1: '#2f2f2f', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#909090', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#e3e3e3', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#595959', // DEPRECATED
            grey11: '#e4e4e4', // DEPRECATED

            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '20px auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '20px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '240px',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '100%',
                },
            },
            genericBanner: {
                margin: '0px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            contentColor: '#666666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            // celebrations Rewards
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',

            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            homePageTextColor: '#5b322c',
            productPriceColor: '#666',
            footermainHeading: '#000099',
            checkoutHeaderBg: '#ffffff',
            secureCheckoutLogo: '',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#F5F5F5',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: 'rgba(201, 220, 214, 0.4)',
            ctaBgColorNup4: '#ffffff',
            ctaColorNup4: '#2f2f2f',
            ctaBorderClrNup4: '#2f2f2f',
            earliestDeliveryColor: '#156400',
        },
        excludeSEO: [],
        implement_delivery_method: false,
    },
    VC: {
        id: '1031',
        code: 'VC',
        fsOrgId: null,
        domain: 'vitalchoice',
        storeId: 22051,
        catalogId: 17802,
        description: 'Vital Choice',
        presentation_family: 'food', // it will be control by CS
        giftMessageConfig: {
            giftMessageMaxLines: 3,
            giftMessageMaxChars: 29,
            excludeFromTextLabel: true,
            signatureMaxChars: 18,
        },
        block_heading: {},
        bloomreach: {
            account_id: null,
            domain_key: null,
            request_id: null,
            url: null,
            view_id: null,
        },
        other: {
            order_service_email: 'customercare@vitalchoice.com',
        },
        typography: {
            // Its a default font family that can used throughout the app
            fontFamily: [
                'Helvetica',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
            secondaryFontFamily: [
                'Helvetica',
                'sans-serif',
            ].join(','),
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},

        overrides: {},
        palette: {
            product: {
                name: '#1f1f1b',
                retailPrice: '#1f1f1b',
                crossedRetailPrice: '#1f1f1b',
                salePrice: '#a1001a',
            },
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#2f2f2f',
                900: '#444444',
                800: '#595959',
                700: '#6d6d6d',
                600: '#828282',
                500: '#595959',
                400: '#acacac',
                300: '#c1c1c1',
                200: '#d5d5d5',
                100: '#ededed',
                contrastText: '#ffffff', // Made to default contrast color over
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#cf3d02',
                900: '#e14b0f',
                800: '#b43348',
                700: '#bd4d5f',
                600: '#c76676',
                500: '#do808d',
                400: '#d999a3',
                300: '#e3b3ba',
                200: '#ecccd1',
                100: '#f6e6e8',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: '#000000',
                secondary: '#757575',
                disabled: '#B7B7B7',
                hint: '#000000',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#cf3d02', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#444', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            colorAlert: '#f71548',
            colorAlertContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            cardPagebackground: '#ebeadd',
            colorSuccess: '#43a047',
            contentHeading: '#6e9930',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            borderLeft: '#52287e',
            black: '#1f1f1b',
            white: '#fff',

            backgroundColor: '#f9f6f6',
            buttonBackgroundColor: '#cf3d02',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#f2f2f2',
            bgHeader: 'white',
            bgPhoneIcon: '#ae201f',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#ae201f',
            bgTile2: '#2f2f2f',
            bigBoldLabel: '#595959',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#FDF5F2',
            colorHeadingSympathy: '#70548f',
            colorIconHeader: '#000',
            productAvailibilityPDP: '#cf3d02',
            bgChatMessage: '#cf3d02',
            desktopHeaderMenu: {  // TODO: replace this with brand's real values
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            deliverySentence: '#52287e',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            OBHeadingColor: '#663399',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: 'black',
            globalBannerTextColor: 'white',
            globalBannerTextDecoration: 'none',
            linkMenu: '#595959',
            orderConfirmationHeader: '#595959',
            orderConfirmationText: 'black',
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#b01116',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#ae201f',
                    goButtonColor: '#fff',
                },
                salePrice: '#a1001a',
            },
            rewardColor: '#00488a',
            primaryButton: '#cf3d02',
            successButton: '#1bc86d',
            productTitleColor: '#2f2f2f',
            sectionHeaderTextColor: '#202020',
            secondaryButton: '#595959',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#3c3c35',
            snipeTextPromo: '#cf3d02',
            snipeTextBackground: 'rgba(255,255,255,0.82)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            submit: '#333333',
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#595959',
            checkRadioBtn: '#cf3d02',
            copyright: '#747472',
            mobileMenuColor: '#000',
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#909090',
            },
            mobileFooterBottomLink: '#747472',
            desktopBgFooter: '#ffffff',
            modalBg: '#f2f2f2',
            modalTile: '#f2f2f2',
            celebrationsColor: '#7ac3a6',
            passportColor: '#2d7dba',

            cta1: '#2f2f2f', // DEPRECATED
            cta2: '#a94', // DEPRECATED
            cta3: '#3c3c35', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#5b322c', // DEPRECATED
            brown2: '#311714', // DEPRECATED
            brown3: '#600600', // DEPRECATED
            brown4: '#d9d0c1', // DEPRECATED
            brown5: 'rgba(255,255,255,0.82)', // DEPRECATED
            brown6: '#ded8d8', // DEPRECATED
            grey1: '#2f2f2f', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#909090', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#e3e3e3', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#595959', // DEPRECATED
            grey11: '#e4e4e4', // DEPRECATED

            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '20px auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '20px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '240px',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '100%',
                },
            },
            genericBanner: {
                margin: '0px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            contentColor: '#666666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            // celebrations Rewards
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes
            imocTitleColor: '#f15a29',
            cardTitleColor: '#f15a29',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',

            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            homePageTextColor: '#5b322c',
            productPriceColor: '#666',
            footermainHeading: '#000099',
            checkoutHeaderBg: '#ffffff',
            secureCheckoutLogo: '',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#F9E5DD',
            highlightColor: '#F9E5DD',
            configuratorBoxSelected: '#CF3D02',
            footerLinkImgBg: 'rgba(201, 220, 214, 0.4)',
            // slideOutMiniCartFlashSaleFont: 'AssistantBold, sans-serif',
            slideOutMiniCartMainColor: '#1f1f1b',
            ctaBgColorNup4: '#ffffff',
            ctaColorNup4: '#2f2f2f',
            ctaBorderClrNup4: '#2f2f2f',
            earliestDeliveryColor: '#2B6C82',
        },
        excludeSEO: [],
        implement_delivery_method: false,
    },
    FL: {
        id: '1034',
        code: 'FL',
        domain: 'florists', // brand's web domain - string in the middle of  https://www.1800flowers.com
        storeId: 20051, // brand's unique store ID
        catalogId: 13302, // brands have many catalogs,but web has one unique catalog
        description: '1-800-Flowers', // simple description of brand used for alt text
        other: {
            order_service_email: '',
        },
        giftMessageConfig: {
            giftMessageMaxLines: 1,
            giftMessageMaxChars: 180,
            excludeFromTextLabel: false,
            signatureMaxChars: 14,
        },
        bloomreach: {
            account_id: null,
            domain_key: null,
            request_id: null,
            url: null,
            view_id: null,
        },
        typography: {
            fontFamily: [
                'LatoMedium',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
            secondaryFontFamily: [
                'P22 Mackinac Pro',
                'Serif',
            ].join(','),
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},
        blockHeading: {},
        overrides: {},

        presentation_family: 'flower',

        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#0e4728',
                900: '#0e4728',
                800: '#1a6340',
                700: '#22724d',
                600: '#28825a',
                500: '#2c8f65',
                400: '#3f9f78',
                300: '#5bb08d',
                200: '#86c7ac',
                100: '#b5ddcc',
                contrastText: '#fff',
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#f8eee5',
                900: '#583c2f',
                800: '#684a3a',
                700: '#765743',
                600: '#87644d',
                500: '#946f55',
                400: '#a6856f',
                300: '#b89c89',
                200: '#cfb9aa',
                100: '#e5d4c9',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
                guidedNav: '#f71449',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#d9f5dc',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#dedede',
                green: '#6E9930',
                red: '#d90059',
                blue: '#371A6E',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
            },
            text: {
                primary: 'rgba(0, 0, 0, 0.87)',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                hint: 'rgba(0, 0, 0, 0.38)',
                fontSize10: '10px',
                fontSize11: '11px',
                fontSize12: '12px',
                fontSize13: '13px',
                fontSize14: '14px',
                fontSize15: '15px',
                fontSize18: '18px',
                fontSize20: '20px',
                fontSize23: '23px',
                fontSize24: '24px',
                fontSize17: '17px',
                fontSize32: '32px',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '52px',
            celebrationsColor: '#7ac3a6',
            passportColor: '#2d7dba',

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#0e4728', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#e8eaec',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            colorAlert: '#f71548',
            contentColor: '#666666',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            colorValid: '#24b200',

            black: '#1f1f1b',
            white: '#fff',
            borderLeft: '#52287e',
            backgroundColor: '#f9f6f6',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#f2f2f2',
            bgHeader: '#65388b',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bgContrastPrimaryColor: '#EBEBEB',
            bigBoldLabel: '#1f1f1b',
            colorIconHeader: '#000',
            contentHeading: '#6e9930',
            cardPagebackground: '#ebeadd',
            colorHeadingSympathy: '#70548f',
            deliverySentence: '#52287e',
            buttonBackgroundColor: '#333',
            disableButton: '#d8d8d8',
            gfBgColor: '#9872bb',
            OBHeadingColor: '#663399',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: '#1f1f1b',
            globalBannerTextColor: 'white',
            select: '#646464',
            globalBannerTextDecoration: 'underline',
            linkMenu: '#324765',
            orderConfirmationHeader: '#1f1f1b',
            orderConfirmationText: 'black',
            rewardColor: '#00488a',
            primaryButton: '#1bc86d',
            successButton: '#1bc86d',
            submit: '#333333',
            productLine: '#BDBBBB',
            modalBorder: '#ececec',
            productTitleColor: '#1f1f1b',
            productAvailibilityPDP: '#a1001a',
            sectionHeaderTextColor: '#212121',
            secondaryButton: '#324765',
            bgChatMessage: '#663789',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#660000',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(48, 69, 98, .85)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                skus: {
                    skuBorderColor: '#000',
                    padding: {
                        mobile: '0px 10px',
                    },
                },
                borderRadius: '2px',
                promoSection: {
                    marginDesktop: 32,
                    marginMobile: 24,
                    borderRadius: '0px',
                    textPadding: '24px',
                    fontSize: '24px',
                    fontWeight: 500,
                    lineHeight: '36px',
                    letterSpacing: '0.01em',
                    textAlign: 'center',
                    paddingTextMobile: '24px 16px 24px 16px',
                    mobileFontSize: '22px',
                    mobileLineHeight: '33px',
                    descLineHeight: '24px',
                    descMobileLineHeight: '20px',
                    descLetterSpacing: '0.02em',
                    descPaddingTop: '8px',
                    descMobileFontSize: '14px',
                },
                largePromo: {
                    color: '#000000',
                    heading: {
                        fontSize: '24px',
                        fontWeight: 500,
                        letterSpacing: '0.16px',
                        textAlign: 'left',
                        secondFontWeight: 600,
                        secondLetterSpacing: '2.24px',
                        textTransform: '24px',
                    },
                    subHeading: {
                        letterSpacing: '0.32px',
                        lineHeight: '24px',
                        textAlign: 'left',
                        fontWeight: 500,
                        fontWeightSpan: 400,
                    },
                    gap: {
                        promo: '24px',
                        subHeading: '16px',
                        subHeadingNested: '8px',

                    },
                    padding: {
                        desktop: ' 64px 62px 64px 62px',
                        tablet: '40px',
                        mobile: '24px 16px 24px 16px',
                    },
                    desktopMargin: '0px 0px 32px 0px',
                    mobileMargin: '0px 0px 16px 0px',
                },
            },
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#a8a89a',
            },
            desktopHeaderMenu: {
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            mobileMenuColor: '#fff',
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#65388b',
            checkRadioBtn: '#65388b',
            copyright: '#606060',
            mobileFooterBottomLink: '#65388b',
            desktopBgFooter: '#65388b',
            modalBg: '#65388b',
            modalTile: '#65388b',

            cta1: '#1f1f1b', // DEPRECATED
            cta2: '#47201D', // DEPRECATED
            cta3: '#660000', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#47201D', // DEPRECATED
            brown2: '#DBD0C2', // DEPRECATED
            brown3: '#DBD0C2', // DEPRECATED
            grey1: '#1f1f1b', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#a8a89a', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#ebebde', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#b6b5b5', // DEPRECATED

            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#5E5E5E',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#585858',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                payment: {
                    background: '#fff',
                    venmoBackgroundColor: '#3d95ce',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '50px auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '44px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '240px',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '186px',
                },
            },
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '0px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            // celebrations Rewards
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes Page
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Listeners Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#65388b',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#fff',
            salePriceColor: '#a1101a',
            ...twilioStyles,
            giftFinderErrorColor: '#D9001B',
            giftFinderSelectColor: '#333333',
            filterDropdownHoverColor: '#F4F2F7',
            noProductsTextColor: '#EC0909',
            footerLinkImgBg: '#E8F1ED',
            slideOutMiniCartFont: 'LatoMedium,sans-serif',
            ctaBgColorNup4: '#bca1c6',
            ctaColorNup4: '#ffffff',
            ctaBorderClrNup4: '#bca1c6',
            legalLinks: '#fff',
            earliestDeliveryColor: '#2B6C82',
        },
        implement_delivery_method: true,
    },
};

export default brandsConfig;
