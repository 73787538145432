/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Define the action types related to MBP UI App
 */
const MBP_UI_LOAD_APP_CLIENT = 'mbp-pwa-ui/LOAD/APP/CLIENT';
const MBP_UI_LOAD_APP_SERVER = 'mbp-pwa-ui/LOAD/APP/SERVER';
const MBP_UI_CLOSE_APP = 'mbp-pwa-ui/CLOSE/APP';
const APP_SET_CURRENT_BRAND_ID = 'mbp-pwa-ui/SET/CURRENT/BRAND/ID';
const APP_SET_CURRENT_URL_ID = 'mbp-pwa-ui/SET/CURRENT/URL/ID';
const PAGE_SHELL_LOADED = 'mbp-pwa-ui/shell/LOADED';
const MBP_UI_SSR_INIT = 'mbp-pwa-ui/INIT/SSR';
const MBP_UI_SET_BOT_STATE = 'mbp-pwa-ui/SET/BOT/STATE';
const MBP_UI_SET_SSR_STATE = 'mbp-pwa-ui/SET/SSR/STATE';
const MBP_UI_SET_SSR_HOSTNAME = 'mbp-pwa-ui/SET/SSR/HOSTNAME';
const MBP_UI_SET_SSR_PATH = 'mbp-pwa-ui/SET/SSR/PATH';
const MBP_UI_SET_SSR_SEARCH = 'mbp-pwa-ui/SET/SSR/SEARCH';
const MBP_UI_SET_SSR_DEVICE_TYPE = 'mbp-pwa-ui/SET/SSR/DEVICE/TYPE';
const MBP_UI_SET_APP_SHELL_TYPE = 'mbp-pwa-ui/SET/APP/SHELL/TYPE';
const MBP_UI_APP_GET_SEARCH_RESULT = 'MBP_UI_APP_GET_SEARCH_RESULT';
const MBP_UI_APP_SET_SEARCH_DATA = 'MBP_UI_APP_SET_SEARCH_DATA';
const MBP_UI_APP_SET_SEARCH_ClEAR = 'MBP_UI_APP_SET_SEARCH_ClEAR';

const MBP_UI_VALIDATE_PRODUCT_FILTER_ZIPCODE = 'mbp-pwa-ui/VALIDATE/PRODUCT/FILTER/ZIPCODE';
const MBP_UI_SET_PRODUCT_FILTER_ZIPCODE_VALIDITY = 'mbp-pwa-ui/IS/PRODUCT/FILTER/ZIPCODE/VALID';
const MBP_UI_RESET_PRODUCT_FILTER_ZIPCODE_VALIDITY = 'mbp-pwa-ui/RESET/PRODUCT/FILTER/ZIPCODE/VALIDITY';
const MBP_UI_SET_VALIDATED_ZIPCODE = 'mbp-pwa-ui/SET/VALIDATED/ZIPCODE';
const MBP_UI_SET_VALIDATED_STATE_OF_ZIPCODE = 'mbp-pwa-ui/SET/VALIDATED/STATE/ZIPCODE';
const MBP_UI_CLEAR_VALIDATED_ZIPCODE = 'mbp-pwa-ui/CLEAR/VALIDATED/ZIPCODE';
const MBP_UI_SET_SUBMITTED_PRODUCT_FILTER_ZIPCODE = 'mbp-pwa-ui/SET/SUBMITTED/PRODUCT/FILTER/ZIPCODE';
const MBP_UI_CLEAR_SUBMITTED_PRODUCT_FILTER_ZIPCODE = 'mbp-pwa-ui/CLEAR/SUBMITTED/PRODUCT/FILTER/ZIPCODE';
const MBP_UI_SET_LOCATION_TYPE = 'mbp-pwa-ui/SET/LOCATION/TYPE';
const MBP_UI_CLEAR_LOCATION_TYPE = 'mbp-pwa-ui/CLEAR/LOCATION/TYPE';
const MBP_UI_SET_PRODUCT_FILTER_OCCASION = 'mbp-pwa-ui/SET/PRODUCT/FILTER/OCCASION';
const MBP_UI_SET_PRODUCT_FILTER_PROFILE = 'mbp-pwa-ui/SET/PRODUCT/FILTER/PROFILE';
const MBP_UI_CLEAR_PRODUCT_FILTER_OCCASION = 'mbp-pwa-ui/CLEAR/PRODUCT/FILTER/OCCASION';
const MBP_UI_LOCK_CATEGORY = 'mbp-pwa-ui/LOCK/CATEGORY';
const MBP_UI_UNLOCK_CATEGORY = 'mbp-pwa-ui/UNLOCK/CATEGORY';
const MBP_UI_CATEGORY_LOADED = 'mbp-pwa-ui/CATEGORY/LOADED';
const MBP_UI_SET_USER_SELECTED_ADDRESS = 'mbp-pwa-ui/SET/GUIDED/NAV/ADDRESS';
const MBP_UI_CLEAR_USER_SELECTED_ADDRESS = 'mbp-pwa-ui/CLEAR/GUIDED/NAV/ADDRESS';
const MBP_UI_SET_ADDRESS_SOURCE = 'mbp-pwa-ui/SET/ADDRESS/SOURCE';
const APP_SET_BANNER_CODE = 'APP_SET_BANNER_CODE';
const APP_CLEAR_BANNER_CODE = 'APP_CLEAR_BANNER_CODE';
const APP_PASSPORT_BUNDLE_ITEM_ADD_TO_CART = 'APP_PASSPORT_BUNDLE_ITEM_ADD_TO_CART';
const APP_PASSPORT_BUNDLE_ITEM_REMOVE_FROM_CART = 'APP_PASSPORT_BUNDLE_ITEM_REMOVE_FROM_CART';
const MBPPRODUCT_SET_PDP_WINE = 'mbp-pwa-ui/PRODUCT/SET/PRODUCT/WINE/';
const MBPPRODUCT_RESET_PDP_WINE = 'mbp-pwa-ui/PRODUCT/RESET/PRODUCT/WINE/';
const WINE_VERIFY = 'mbp-pwa-ui/PRODUCT/GET/PRODUCT/WINE/VERIFY';
const WINE_VERIFY_DONE = 'mbp-pwa-ui/PRODUCT/GET/PRODUCT/WINE/VERIFY/DONE';
const WINE_VERIFY_FAILED = 'mbp-pwa-ui/PRODUCT/GET/PRODUCT/WINE/VERIFY/FAILED';
const APP_SET_BRAND_MOVIE_SKUS = 'mbp-pwa-ui/SET/BRAND/MOVIE/SKUS';
const SET_GCI_DATA = 'mbp-pwa-ui/SET/GCI/DATA';
const CLEAR_GCI_DATA = 'mbp-pwa-ui/CLEAR/GCI/DATA';
const FETCH_GCI_KEY = 'mbp-pwa-ui/FETCH/GCI/KEY';
const SET_GCI_KEY = 'mbp-pwa-ui/SET/GCI/KEY';
const SET_EMAIL_SUBSCRIPTION_DATA = 'SET_EMAIL_SUBSCRIPTION_DATA';
const RESET_EMAIL_SUBSCRIPTION_DATA = 'RESET_EMAIL_SUBSCRIPTION_DATA';
const CREATE_EMAIL_SUBSCRIPTION = 'CREATE_EMAIL_SUBSCRIPTION';
const APP_RESET_AGE_FLAG = 'APP_RESET_AGE_FLAG';
const APP_SET_AGE_FLAG_TRUE = 'APP_SET_AGE_FLAG_TRUE';
const APP_SET_AGE_FLAG_FALSE = 'APP_SET_AGE_FLAG_FALSE';
const APP_SET_ADDTOCART_POSITION = 'APP_SET_ADDTOCART_POSITION';
const SET_COUNTRY = 'mbp-pwa-ui/SET/COUNTRY';
const APP_SET_PRODUCT_RETAIL_PRICE = 'APP_SET_PRODUCT_RETAIL_PRICE';
const APP_SET_CHATBOT_OPEN_STATE = 'APP_SET_CHATBOT_OPEN_STATE';
const APP_SET_MINICART_UPSELL_STATE = 'APP_SET_MINICART_UPSELL_STATE';
const APP_SET_MINICART_MODAL_STATE = 'APP_SET_MINICART_MODAL_STATE';
const APP_SET_USER_CONFIRMATION_FOR_POPUP = 'APP_SET_USER_CONFIRMATION_FOR_POPUP';
const SET_GUIDED_NAV_MODAL_HAS_BEEN_LOADED = 'SET_GUIDED_NAV_MODAL_HAS_BEEN_LOADED';
const SET_CART_SIDE_PANEL_EXPOSED = 'SET_CART_SIDE_PANEL_EXPOSED';
const CHAT_EVENTING_DATA = 'CHAT_EVENTING_DATA';
const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
const REMOVE_GCI_KEY = 'mbp-pwa-ui/REMOVE/GCI/KEY';
const APP_SET_TWILLIO_MESSAGE_CHAT_BOT = 'APP_SET_TWILLIO_MESSAGE_CHAT_BOT';
const TOGGLE_CART_SIDE_PANEL = 'TOGGLE_CART_SIDE_PANEL';
const SUBSCRIPTION_VERIFY_ADDRESS = 'SUBSCRIPTION_VERIFY_ADDRESS';
const SUBSCRIPTION_DETAIL = 'SUBSCRIPTION_DETAIL';
const SET_DONATIONS_CATEGORY = 'mbp-pwa-ui/SET/DONATIONS';
const SET_SIDE_MINI_CART = 'mbp-pwa-ui/SET_SIDE_MINI_CART';
const MBP_UI_SET_VALID_ZIPCODE_SUBMITTED_ONCE = 'mbp-pwa-ui/SET_VALID_ZIPCODE_SUBMITTED_ONCE';
const MBP_UI_SET_GUIDED_NAV_LOCKED_PRODUCT_CLICK = 'mbp-pwa-ui/GUIDED_NAV_LOCKED_PRODUCT_CLICK';
const SET_APP_PASSPORT_HOLDOUT_GROUP = 'mbp-pwa-ui/SET_APP_PASSPORT_HOLDOUT_GROUP';
const SET_APP_PASSPORT_HOLDOUT_GROUP_TRACKING = 'mbp-pwa-ui/SET_APP_PASSPORT_HOLDOUT_GROUP_TRACKING';
const SET_CART_SLIDER_OPEN = 'mbp-pwa-ui/SET_CART_SLIDER_OPEN';
const SET_ABC_CHAT_LINK = 'SET_ABC_CHAT_LINK';
const APP_SET_GUIDED_NAV_PRODUCT_UNLOCK = 'APP_SET_GUIDED_NAV_PRODUCT_UNLOCK';
const APP_SET_WINE_AGE_VERIFY = 'APP_SET_WINE_AGE_VERIFY';
const APP_SET_PRODUCT_UN_AVAILABLE = 'APP_SET_PRODUCT_UN_AVAILABLE';
const APP_SET_SELECTED_INLINE_ADDONS = 'APP_SET_SELECTED_INLINE_ADDONS';
const SET_LOGIN_MODULE_VISIBILITY = 'SET_LOGIN_MODULE_VISIBILITY';
const APP_SET_SELECTED_BYO_ITEMS = 'APP_SET_SELECTED_BYO_ITEMS';
const APP_SET_EMAIL_CAPTURE_STATUS = 'APP_SET_EMAIL_CAPTURE_STATUS';
const APP_SET_HOLDOUT_PERSONALIZATION_ENABLED = 'APP_SET_HOLDOUT_PERSONALIZATION_ENABLED';
const APP_SET_EMAIL_OPT_IN_SF_RESPONSE = 'APP_SET_EMAIL_OPT_IN_SF_RESPONSE';
const APP_RESET_EMAIL_OPT_IN_SF_RESPONSE = 'APP_RESET_EMAIL_OPT_IN_SF_RESPONSE';
const APP_SET_EMAIL_OPT_IN_REFETCH_TIMESTAMP = 'APP_SET_EMAIL_OPT_IN_REFETCH_TIMESTAMP';
const SET_DIALOG_OPEN = 'SET_DIALOG_OPEN';
const SET_DIALOG_TYPE = 'SET_DIALOG_TYPE';
const SET_OFFERGATEWAY_DATA = 'SET_OFFERGATEWAY_DATA';
const SET_OFFERGATEWAY_CTA_LABEL = 'SET_OFFERGATEWAY_CTA_LABEL';
const MBP_UI_SET_AUTO_FILL_ADDRESS = 'mbp-pwa-ui/MBP_UI_SET_AUTO_FILL_ADDRESS';
const SET_WRAP_UP_PRODUCT_REF_TITLE = 'SET_WRAP_UP_PRODUCT_REF_TITLE';

export default {
    MBP_UI_SET_ADDRESS_SOURCE,
    MBP_UI_SET_VALID_ZIPCODE_SUBMITTED_ONCE,
    MBP_UI_LOAD_APP_CLIENT,
    MBP_UI_LOAD_APP_SERVER,
    MBP_UI_CLOSE_APP,
    MBPPRODUCT_SET_PDP_WINE,
    MBPPRODUCT_RESET_PDP_WINE,
    APP_SET_CURRENT_URL_ID,
    APP_SET_CURRENT_BRAND_ID,
    PAGE_SHELL_LOADED,
    MBP_UI_SSR_INIT,
    MBP_UI_SET_BOT_STATE,
    MBP_UI_SET_SSR_STATE,
    MBP_UI_SET_SSR_HOSTNAME,
    MBP_UI_SET_SSR_PATH,
    MBP_UI_SET_SSR_SEARCH,
    MBP_UI_SET_SSR_DEVICE_TYPE,
    MBP_UI_SET_APP_SHELL_TYPE,
    MBP_UI_APP_GET_SEARCH_RESULT,
    MBP_UI_APP_SET_SEARCH_DATA,
    MBP_UI_APP_SET_SEARCH_ClEAR,
    WINE_VERIFY_DONE,
    WINE_VERIFY_FAILED,
    WINE_VERIFY,
    APP_RESET_AGE_FLAG,
    APP_SET_AGE_FLAG_TRUE,
    APP_SET_AGE_FLAG_FALSE,
    APP_SET_ADDTOCART_POSITION,
    MBP_UI_SET_GUIDED_NAV_LOCKED_PRODUCT_CLICK,
    MBP_UI_VALIDATE_PRODUCT_FILTER_ZIPCODE,
    MBP_UI_SET_PRODUCT_FILTER_ZIPCODE_VALIDITY,
    MBP_UI_RESET_PRODUCT_FILTER_ZIPCODE_VALIDITY,
    MBP_UI_SET_VALIDATED_ZIPCODE,
    MBP_UI_CLEAR_VALIDATED_ZIPCODE,
    MBP_UI_SET_SUBMITTED_PRODUCT_FILTER_ZIPCODE,
    MBP_UI_CLEAR_SUBMITTED_PRODUCT_FILTER_ZIPCODE,
    MBP_UI_SET_LOCATION_TYPE,
    MBP_UI_CLEAR_LOCATION_TYPE,
    MBP_UI_SET_PRODUCT_FILTER_OCCASION,
    MBP_UI_SET_PRODUCT_FILTER_PROFILE,
    MBP_UI_CLEAR_PRODUCT_FILTER_OCCASION,
    MBP_UI_LOCK_CATEGORY,
    MBP_UI_UNLOCK_CATEGORY,
    MBP_UI_CATEGORY_LOADED,
    MBP_UI_SET_USER_SELECTED_ADDRESS,
    MBP_UI_CLEAR_USER_SELECTED_ADDRESS,
    SET_EMAIL_SUBSCRIPTION_DATA,
    CREATE_EMAIL_SUBSCRIPTION,
    RESET_EMAIL_SUBSCRIPTION_DATA,
    APP_SET_BANNER_CODE,
    APP_CLEAR_BANNER_CODE,
    APP_PASSPORT_BUNDLE_ITEM_ADD_TO_CART,
    APP_PASSPORT_BUNDLE_ITEM_REMOVE_FROM_CART,
    APP_SET_BRAND_MOVIE_SKUS,
    SET_GCI_DATA,
    CLEAR_GCI_DATA,
    FETCH_GCI_KEY,
    SET_GCI_KEY,
    SET_COUNTRY,
    APP_SET_PRODUCT_RETAIL_PRICE,
    APP_SET_CHATBOT_OPEN_STATE,
    APP_SET_USER_CONFIRMATION_FOR_POPUP,
    SET_GUIDED_NAV_MODAL_HAS_BEEN_LOADED,
    SET_CART_SIDE_PANEL_EXPOSED,
    APP_SET_MINICART_UPSELL_STATE,
    APP_SET_MINICART_MODAL_STATE,
    CHAT_EVENTING_DATA,
    SET_SCROLL_POSITION,
    TOGGLE_CART_SIDE_PANEL,
    REMOVE_GCI_KEY,
    APP_SET_TWILLIO_MESSAGE_CHAT_BOT,
    SUBSCRIPTION_VERIFY_ADDRESS,
    SUBSCRIPTION_DETAIL,
    SET_DONATIONS_CATEGORY,
    SET_SIDE_MINI_CART,
    SET_APP_PASSPORT_HOLDOUT_GROUP,
    SET_APP_PASSPORT_HOLDOUT_GROUP_TRACKING,
    SET_CART_SLIDER_OPEN,
    SET_ABC_CHAT_LINK,
    APP_SET_GUIDED_NAV_PRODUCT_UNLOCK,
    APP_SET_WINE_AGE_VERIFY,
    APP_SET_PRODUCT_UN_AVAILABLE,
    APP_SET_SELECTED_INLINE_ADDONS,
    MBP_UI_SET_VALIDATED_STATE_OF_ZIPCODE,
    SET_LOGIN_MODULE_VISIBILITY,
    APP_SET_SELECTED_BYO_ITEMS,
    APP_SET_EMAIL_CAPTURE_STATUS,
    APP_SET_HOLDOUT_PERSONALIZATION_ENABLED,
    APP_SET_EMAIL_OPT_IN_SF_RESPONSE,
    APP_RESET_EMAIL_OPT_IN_SF_RESPONSE,
    APP_SET_EMAIL_OPT_IN_REFETCH_TIMESTAMP,
    SET_DIALOG_OPEN,
    SET_DIALOG_TYPE,
    SET_OFFERGATEWAY_DATA,
    SET_OFFERGATEWAY_CTA_LABEL,
    MBP_UI_SET_AUTO_FILL_ADDRESS,
    SET_WRAP_UP_PRODUCT_REF_TITLE,
};
