/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, {
    useEffect, useRef, useState,
} from 'react';
import {
    any, object, func, string, bool,
} from 'prop-types';
import mbpUtil from 'mbp-api-util';
import mbpLogger from 'mbp-logger';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getGuestUserAccessToken } from '../../../state/ducks/Member/ducks/Auth/Plugin/auth/guest';

let eventLogic;
const ConversationalUIChatBot = ({
    deviceType, brand, browser, operatingSystem, brandTheme, pageType, eventLogicFlag, location,
}) => {
    const iframeRef = useRef(null);
    const pageTypeRef = useRef(pageType);
    const [isIframeMaximized, setIsIframeMaximized] = useState(false);
    const isMobile = deviceType !== 'desktop';
    const [isIframeLoaded, setIsIframeLoaded] = useState(true);
    const trustedOrigin = `${mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST')}`;
    const cartItems = useSelector((state) => state?.cart?.recipients);
    const cartHasPassport = useRef('false');
    const orderDetails = useSelector((state) => state?.cart);
    const ORDER_PLACED = 'Published';
    const [orderSize, setOrderSize] = useState(0);
    const [currentLocation, setCurrentLocation] = useState(undefined);
    const accessToken = useSelector(getAccessToken);

    useEffect(() => {
        if (isIframeMaximized) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [isIframeMaximized]);

    useEffect(() => {
        if (accessToken) {
            const auth = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member'))?.auth);
            const iframe = iframeRef.current;
            if (iframe && iframe?.contentWindow) {
                iframe.contentWindow.postMessage(
                    { type: 'update-access-token', data: auth },
                    `${trustedOrigin}`,
                );
            }
        }
    }, [accessToken]);

    useEffect(() => {
        cartItems?.forEach((cartItem) => {
            cartItem?.cartItems?.forEach((item) => {
                if (item?.itemInfo?.lineItemType === 'PASSPORT') {
                    cartHasPassport.current = 'true';
                }
            });
        });
        const iframe = iframeRef.current;
        if (iframe && iframe?.contentWindow) {
            iframe.contentWindow.postMessage(
                { type: 'update-cart-passport-status', data: { cartHasPassport: cartHasPassport.current } },
                `${trustedOrigin}`,
            );
        }
    }, [cartItems]);

    useEffect(() => {
        pageTypeRef.current = pageType;
        const iframe = iframeRef.current;
        if (iframe && iframe?.contentWindow) {
            iframe.contentWindow.postMessage(
                { type: 'update-page-info', data: pageTypeRef.current },
                `${trustedOrigin}`,
            );
        }
    }, [pageType]);

    /**
         * send current url to the connected agent whenever the user change the location
         */
    useEffect(() => {
        const path = location?.pathname;
        if (currentLocation !== path) {
            setCurrentLocation(window?.location?.href);
            const customParameters = {
                currentUrl: window?.location?.href,
            };
            const iframe = iframeRef.current;
            if (iframe && iframe.contentWindow) {
                iframe.contentWindow.postMessage(
                    { type: 'update-visiting-page', data: customParameters },
                    `${trustedOrigin}`,
                );
            }
        }
    }, [location?.pathname]);

    /**
     * send order details to the connected agent whenever the user add or remove something.
     */
    useEffect(() => {
        if (orderDetails && orderDetails.orderSize !== orderSize) {
            const itemAdded = orderDetails.orderSize > orderSize;
            setOrderSize(orderDetails.orderSize);
            const customParameters = {
                isItemAdded: itemAdded,
                orderValue: orderDetails.cartTotal,
                orderNumber: orderDetails.orderIdWithSourceId,
            };
            const iframe = iframeRef.current;
            if (iframe && iframe?.contentWindow) {
                iframe.contentWindow.postMessage(
                    { type: 'update-cart-details', data: customParameters },
                    `${trustedOrigin}`,
                );
            }
        }
    }, [orderDetails]);

    useEffect(() => {
        if (orderDetails?.cartStatus && orderDetails?.cartStatus === ORDER_PLACED) {
            const customParameters = {
                wasOrderPlaced: 'true',
                orderNumber: orderDetails?.cartId,
            };
            const iframe = iframeRef.current;
            if (iframe && iframe?.contentWindow) {
                iframe.contentWindow.postMessage(
                    { type: 'update-order-placed-status', data: customParameters },
                    `${trustedOrigin}`,
                );
            }
        }
    }, [orderDetails?.cartStatus]);

    useEffect(() => {
        const handleMessage = async (event) => {
            try {
                const iframe = iframeRef.current;
                const token = await getGuestUserAccessToken();

                if (eventLogicFlag) {
                    if (event.data.type === 'pwa-events-logic') {
                        const functionBody = JSON.parse(event.data.data);
                        // eslint-disable-next-line no-new-func
                        eventLogic = new Function('event', 'brand', 'browser', 'deviceType', 'operatingSystem', 'brandTheme', 'trustedOrigin', 'setIsIframeMaximized', 'iframeRef', 'pageTypeRef', 'isMobile', 'token', functionBody);
                    }
                    if (eventLogic) {
                        eventLogic(event, brand, browser, deviceType, operatingSystem, brandTheme, trustedOrigin, setIsIframeMaximized, iframeRef, pageTypeRef, isMobile, token);
                    }
                } else {
                    if (event.data.type === 'conv-ui-session-info') {
                        const url = window.location.href;
                        const auth = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member'))?.auth);
                        const profile = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member'))?.profile);
                        const enterprise = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member'))?.enterpriseId);
                        const browseruuid = localStorage?.getItem('browserUUID') || '';
                        event.source.postMessage({
                            type: 'session-storage-data',
                            data: {
                                url,
                                brand,
                                browser,
                                deviceType,
                                operatingSystem,
                                brandTheme,
                                auth,
                                profile,
                                enterprise,
                                browseruuid,
                                token,
                            },
                        }, `${trustedOrigin}/chat/flora`);
                    }
                    if (event.data.type === 'search-suggestion-submitted') {
                        if (iframe && iframe.contentWindow) {
                            iframe.contentWindow.postMessage(
                                { type: 'update-chat-message-search', data: event.data },
                                `${trustedOrigin}`,
                            );
                        }
                    }
                    if (event.data.type === 'footer-suggestion-submitted') {
                        if (iframe && iframe.contentWindow) {
                            iframe.contentWindow.postMessage(
                                { type: 'update-chat-message-footer', data: event.data },
                                `${trustedOrigin}`,
                            );
                        }
                    }
                    if (event.data.type === 'get-access-token' && iframe?.contentWindow) {
                        iframe.contentWindow.postMessage(
                            { type: 'update-access-token', data: token },
                            trustedOrigin,
                        );
                    }
                    if (event.data.type === 'get-page-info') {
                        if (iframe && iframe.contentWindow) {
                            iframe.contentWindow.postMessage(
                                { type: 'update-page-info', data: pageTypeRef.current },
                                `${trustedOrigin}`,
                            );
                        }
                    }
                    if (event.data.type === 'chat-iframe-maximised') {
                        if (isMobile) {
                            setIsIframeMaximized(() => true);
                            iframeRef.current.style.top = '0';
                            iframeRef.current.style.height = '100dvh';
                            iframeRef.current.style.width = '100vw';

                            iframeRef.current.style.right = null;
                            iframeRef.current.style.bottom = null;
                        } else {
                            iframeRef.current.style.height = '725px';
                            iframeRef.current.style.width = '400px';
                            iframeRef.current.style.bottom = '-5%';
                        }
                    }
                    if (event.data.type === 'chat-iframe-minimised') {
                        setIsIframeMaximized(() => false);
                        iframeRef.current.style.height = '80px';
                        iframeRef.current.style.width = '80px';
                        iframeRef.current.style.top = '';
                        iframeRef.current.style.bottom = '10px';
                        iframeRef.current.style.right = '0px';
                    }
                    if (event.data.type === 'chat-preview-msg-closed') {
                        iframeRef.current.style.height = '80px';
                        iframeRef.current.style.width = '80px';
                    }
                    if (event.data.type === 'preview-msg-ready') {
                        iframeRef.current.style.width = '300px';
                        iframeRef.current.style.height = '86px';
                    }
                }
            } catch (error) {
                mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    errorMessage: error,
                    message: `FloraChat: Could not post value - ERROR: ${error}`,
                });
            }
        };
        if (isIframeLoaded) {
            window?.addEventListener('message', handleMessage);
        }
        return () => {
            window?.removeEventListener('message', handleMessage);
        };
    }, [isIframeLoaded, trustedOrigin, eventLogicFlag]);

    const handleIframeLoad = () => setIsIframeLoaded(true);
    const handleIframeError = () => setIsIframeLoaded(false);

    return isIframeLoaded ? (
        <iframe
            id="18fchatbot"
            ref={iframeRef}
            title="18fchatbot"
            className="test"
            allowFullScreen
            style={{
                overflow: 'hidden',
                boxShadow: 'none',
                border: 'none',
                position: 'fixed',
                zIndex: 2243000,
                bottom: '10px',
                right: '0%',
                width: '80px',
                height: '80px',
            }}
            src={`${trustedOrigin}/chat/flora`}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
        />
    ) : null;
};

ConversationalUIChatBot.propTypes = {
    deviceType: string.isRequired,
    brand: object.isRequired,
    browser: func.isRequired,
    operatingSystem: func.isRequired,
    brandTheme: any.isRequired,
    pageType: string.isRequired,
    eventLogicFlag: bool.isRequired,
    location: object.isRequired,
};

export default ConversationalUIChatBot;
