/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    put, call, select, takeLatest, getContext,
} from 'redux-saga/effects';
import mbpUtil from 'mbp-api-util';

import memberDucks from '../../../Member/ducks';

import { getSSRConfig } from '../../App-Selectors';
import * as brandSelectors from '../Brand/Brand-Selectors';
import * as configActions from './Config-Actions';
import * as configHelpers from './Config-Helpers';
import { logUserLoginInSessionReady } from '../../../Member/ducks/Auth/Auth-Actions';

const {
    common: {
        commonSelectors: {
            getProfileInfo,
            getUserType,
            getUserRole,
        },
    },
    profile: {
        profileActions: {
            setProfileEmailId,
            loadedProfileUserRole,
        },
    },
} = memberDucks;

export function* fetchFeatureFlags() {
    const sdkFeatureFlags = yield getContext('sdkFeatureFlags');
    const cookies = yield getContext('cookies');
    const mbpLogger = yield getContext('mbpLogger');

    const topic = 'app-feature-flags';
    const isSSR = (typeof window === 'undefined');

    const brand = yield select(brandSelectors.getBrand);
    const userType = yield select(getUserType);
    const userStatus = yield select(getUserRole);
    const profile = yield select(getProfileInfo);
    const {
        deviceType,
        userKey,
        isBot,
        userAgent,
        // siteVariant,
        isUSCustomer,
    } = yield select(getSSRConfig);

    let sessionUserKey = userKey;
    if (!isSSR) {
        // SSR will always assign this value and it will always be
        // prepopulated
        // TODO: prove its impossible to end up here and remove
        sessionUserKey = cookies.get('userKey');

        if (!sessionUserKey) {
            sessionUserKey = configHelpers.getUniqueKey();
            cookies.set('userKey', userKey, {
                path: '/',
                encode: String,
            });
        }
    }

    const browser = configHelpers.getBrowser();
    const operatingSystem = configHelpers.getOperatingSystem();
    const route = cookies.get('route');

    // const version = siteVariant || route || 'ui';
    const email = (profile.email) ? profile.email : '';

    let flags = {};

    const payload = {
        userKey: sessionUserKey || 'anonymous',
        userType: userType || 'G',
        userStatus,
        email,
        firstName: profile.firstName,
        lastName: profile.lastName,
        browser,
        operatingSystem,
        brand: brand.domain,
        version: isUSCustomer ? 'us' : 'null', // TODO: Move to unique property.
        device: deviceType,
        isBot,
        userAgent,
    };

    try {
        const flagEnv = mbpUtil.getEnv('APP_FLAG_ENV') || 'mbp-ui';
        const response = yield call(
            sdkFeatureFlags.getFeatureFlags,
            {},
            flagEnv,
            payload,
        );

        flags = response.data;
    } catch (error) {
        mbpLogger.logError({
            error,
            module: 'Config-Operations',
            function: 'fetchFeatureFlags saga',
            payload,
        });

        yield put(configActions.configLoaded(brand.domain, topic, {}));
        return;
    }

    if (flags['is-feature-flags-disabled']) {
        yield put(configActions.configLoaded(brand.domain, topic, {}));
        return;
    }
    yield put(configActions.configLoaded(brand.domain, topic, flags));
}

function* watchActionsToUpdateFeatureFlags() {
    // take latest not only forks itself but also forks the reactionary invocation.
    // docs: https://redux-saga.js.org/docs/api/#takelatestpattern-saga-args
    yield takeLatest([
        setProfileEmailId().type,
        loadedProfileUserRole().type,
        logUserLoginInSessionReady().type,
    ], fetchFeatureFlags);
}

const watchers = [
    watchActionsToUpdateFeatureFlags,
];

export {
    watchers,
};
