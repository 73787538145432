/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { channel, END } from 'redux-saga';
import {
    take, fork, call, put, select, takeLatest,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';

import * as addToCartActions from './AddToCart-Actions';
import { getFeatureFlags } from '../App/ducks/Config/Config-Selectors';
import { getProfileInfo, getIsAuthenticatedStatus } from '../Member/ducks/Common/Common-Selectors';
import {
    getLocationType, getBrandMovieSkus,
    getSavedAddress, getProductRetailPrice, getUserSubmittedProductFilterZipcode,
} from '../App/App-Selectors';
import {
    setProductRetailPrice, setUserSubmittedProductFilterZipcode,
    setLocationType,
    setUserSelectedAddress,
    setSelectedBYOItems,
    clearGCIData,
} from '../App/App-Actions';
import { retrieveCart } from '../Cart/Cart-Operations';
import { checkJWT } from '../Member/ducks/Auth/Auth-Operations';
import { persistCheckout } from '../../../app/helpers/EnterpriseID/CustomerDataPersistance/customerDataPersistance';
import { getAddToCartGiftMessage, getLastAddedOrderItemDetails } from './AddToCart-Selectors';
import { getBaseCode } from '../../../app/helpers/tracking/product/dataLayerProductHelpers';
import { trackEvent } from '../TagManager/ducks/TagManager/TagManager-Actions';
import { trackCartUpdate } from '../TagManager/ducks/TagManager/TagManager-Helpers';
import { clearAuthDependentState, triggerInterstitialLogin } from '../Member/ducks/Auth/Auth-Actions';
import {
    convertAddressByAddressSource, getZipByAddressSource, isSCIAddressAutoFillable,
} from './helpers/preCheckoutAddressNormalizer';

// Checkout
import * as commonSelectors from '../Common/Common-Selectors';
import { callHandleCartFailure } from '../Cart/Cart-Actions';
import { orderOperations } from '../Order';
import orderClient from '../../../apis/checkout-apis/orderClient';
import PayloadGeneratorFromOrderHistory from './helpers/payloadGeneratorFromOrderHistory';
import { subscribePassportBundle } from '../Passport/Passport-Action';
import { setProfileUserRole } from '../Member/ducks/Profile/Profile-Actions';
import {
    addOnAndWrapupPayloadAddToCartCS, addOnPayloadAddToCartCS, payloadAddToCartCS, wrapupPayloadAddToCartCS,
} from './helpers/payloadGeneratorClickStreamCart';
import { circuitBreaker } from '../Member/ducks/Auth/helper/helper';
import { getCheckoutFeatureFlags } from '../Checkout/ducks/App/App-Selectors';
import cartServices from '../../../apis/cart-service-apis/cartServices';
import { removePmallItem, updatePmallImages } from '../Common/ducks/PmallPersonalization/PmallPersonalization-Actions';
import { getCountryCodeFromTwoToThreeDigit } from '../Checkout/helpers/countryCode';
import getBrandPresentationFamily from '../../../app/helpers/getBrandPresentationFamily';
import { emitCustomTrackingEvent } from '../TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';

// mbpAddToCart setup
const mbpAddToCartChannel = channel();

export const handleMbpAddToCartTestEvent = (e) => {
    if (e?.detail.brandCode
        && e?.detail.productCode) {
        const { brandCode, productCode, isPassport = false } = e.detail;
        mbpAddToCartChannel.put(addToCartActions.mbpAddToCart(brandCode, productCode, isPassport));
    }
};

// eslint-disable-next-line require-yield
export function* startMbpAddToCartChannel() {
    if (typeof document !== 'undefined') {
        document.addEventListener('mbpAddToCart', handleMbpAddToCartTestEvent);
    }
}

function* addToCartOnEvent() {
    try {
        yield call(startMbpAddToCartChannel);
    } catch (err) {
        mbpLogger.logError({
            function: 'addToCartOnEvent',
            module: 'mbp-pwa-ui',
            message: 'Error injecting mbpAddToCart script for addToCart',
            jsError: err,
        });
    }
}

export function* handleAuthorizationApiFailures(resumeFunction, resumeFunctionParameter) {
    const logOrderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(getFeatureFlags);

    if (flagState['is-checkout-monitoring-enabled']) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'Auth-Operations.js',
            function: 'handleAuthorizationApiFailures',
            message: 'handleAuthorizationApiFailures start',
            orderId: logOrderId,
        });
    }

    yield put(clearAuthDependentState(true));

    yield call(checkJWT);

    yield call(resumeFunction, resumeFunctionParameter);

    if (flagState['is-checkout-monitoring-enabled']) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'Auth-Operations.js',
            function: 'handleAuthorizationApiFailures',
            message: 'handleAuthorizationApiFailures end',
            orderId: logOrderId,
        });
    }
}

// API
const postAddToCart = ({
    JWT_TOKEN, item, recipients, giftHistoryOrderItemId, personalization,
}) => orderClient.addToCart(
    {},
    JWT_TOKEN,
    recipients,
    item,
    giftHistoryOrderItemId,
    personalization,
);

const postAddAddonsToCart = ({
    JWT_TOKEN,
    addonData,
    orderItemId,
    orderId,
}) => orderClient.addAddonItemsToCart({}, JWT_TOKEN, orderItemId, addonData, orderId);

const postAddWrapupsToCart = ({
    JWT_TOKEN,
    wrapupData,
    orderId,
}) => orderClient.addWrapupsToCart({}, JWT_TOKEN, wrapupData, orderId);

const prepareBuyAgainOrder = async ({
    JWT_TOKEN,
    orderId,
}) => {
    try {
        return await orderClient.orderPrepare({}, JWT_TOKEN, orderId);
    } catch (ex) {
        mbpLogger.logError({
            orderId,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'prepareBuyAgainOrder',
            jsError: ex,
            message: 'Prepare Cart call',
        });
        return null;
    }
};

// Update Gift Message When Item is added to cart
function* updateGiftMessage(jwtToken, orderId, orderItemId, giftMessage, occasionCode) {
    try {
        yield call(
            orderClient.updateCart,
            {},
            jwtToken,
            orderItemId,
            null,
            null,
            {
                message: giftMessage,
                occasionCode,
            },
            orderId,
        );
    } catch (ex) {
        yield put(callHandleCartFailure(ex));

        mbpLogger.logError({
            orderItem: orderItemId,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'addGiftMessage',
            jsError: ex,
            message: 'Add gift message',
        });
    }
}

function* getPreCheckoutRecipientAddress(isPersistAddressEnabled = false) {
    const savedAddress = yield select(getSavedAddress);
    const recipient = yield call(convertAddressByAddressSource, savedAddress, isPersistAddressEnabled);
    const locationType = yield select(getLocationType);
    recipient.locationType = locationType;
    return recipient;
}

function* workerLogAddToCartError(exception) {
    const response = exception?.response || {};
    let addToCartErrorMessage = 'We are sorry, there may be a network connection issue. Please try again.';
    const errorMessage = response?.data?.detailedError?.errorMessage || response?.data?.errorMessage;
    const errorMessageList = response?.data?.detailedError?.validationErrors || [];
    if (errorMessage) {
        addToCartErrorMessage = errorMessage;
    }
    console.error('Debug - workerLogAddToCartError - addToCartErrorMessage:', addToCartErrorMessage);
    console.error('Debug - workerLogAddToCartError - response:', response);

    const featureFlags = yield select(getFeatureFlags);

    if (featureFlags['is-checkout-monitoring-enabled']) {
        mbpLogger.logError({
            function: 'workerLogAddToCartError',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'We are sorry, there may be a network connection issue. Please try again.',
        });
    }
    yield put(addToCartActions.loadAddToCartError(addToCartErrorMessage, errorMessageList));
}

function* onMbpAddToCart({ brandCode, productCode, isPassport }) {
    const item = {
        brandCode,
        productCode,
        quantity: '1',
    };
    try {
        const JWT_TOKEN = yield call(checkJWT);

        const addToCartResp = yield call(postAddToCart, {
            recipients: null,
            item,
            JWT_TOKEN,
            giftHistoryOrderItemId: '',
            personalization: '',
        });
        const { orderId } = addToCartResp.data;

        // Order Id
        yield call(orderOperations.setOrderId, {
            data: orderId,
        });

        yield call(retrieveCart);
        yield put(addToCartActions.addToCartSuccess());

        if (isPassport) {
            yield put(setProfileUserRole('M'));
        }
    } catch (ex) {
        yield put(callHandleCartFailure(ex));

        mbpLogger.logError({
            item,
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            function: 'onMbpAddToCart',
            jsError: ex,
            message: 'onMbpAddToCart Failed',
        });
    }
}

const circuitBreakAddToCart = circuitBreaker(2);

function* onAddToCart({
    item,
    history,
    categoryId,
    partNumber,
    skipAddons,
    deliveryDate,
    categoryName,
    categoryPath,
    isPassportBundleItem,
    movieSelected,
    selectedSku,
    isPassportBundle,
    handleMiniCartModalClick,
    enableMinicart,
    closeCalendar,
    showWrapup,
    quickviewLocationType,
    location,
}) {
    const args = { item, history };
    const logOrderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(getFeatureFlags);
    const checkoutFeatureFlags = yield select(getCheckoutFeatureFlags);
    try {
        yield put(addToCartActions.loadAddToCartloader(true));
        yield put(addToCartActions.loadItemAdded(null));
        yield put(addToCartActions.clearAddToCartError());
        mbpLogger.logInfo({
            function: 'onAddToCart',
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            message: 'onAddToCart - Start',
            item,
            orderId: logOrderId,
        });

        const JWT_TOKEN = yield call(checkJWT);
        const brandMovieSkus = yield select(getBrandMovieSkus);

        // use what is going to the backend, set the application state below
        let zipCode = item?.delivery?.zipCode || '';
        if (!zipCode) {
            zipCode = yield select(getUserSubmittedProductFilterZipcode);
        }

        /**
         * Because the category page re-renders based on the location type in the redux store
         * and quickview is on the category page, setting the redux store from quickview modal
         * causes bugs.
         *
         * reconcile the two here.
         */
        let locationType = yield select(getLocationType);
        if (quickviewLocationType) {
            locationType = quickviewLocationType;
        }

        const reqPayload = {
            item,
            JWT_TOKEN,
            giftHistoryOrderItemId: '',
        };

        // Starting at the end of the string (backwards pattern matching), continue to match any characters that aren't '-' or '_'
        // dolly parton is a temp fix for the two digital products (remove if no longer needed)
        if (brandMovieSkus[item.productCode.match(/[^-_]*$/)] || (selectedSku.id === '1001-193081A' || selectedSku.id === '1001-193082B')) {
            // eslint-disable-next-line no-param-reassign
            item.lineItemType = 'NBCU';
        }

        const preCheckoutSavedAddress = yield select(getSavedAddress);
        if (
            item?.delivery?.zipCode !== '00000'
            && (
                (
                    isSCIAddressAutoFillable(preCheckoutSavedAddress, locationType, zipCode)
                    && !['expressCheckout', 'buyAgain'].includes(preCheckoutSavedAddress.addressSource)
                )
                || (
                    ['expressCheckout', 'buyAgain'].includes(preCheckoutSavedAddress.addressSource)
                    && zipCode
                    && getZipByAddressSource(preCheckoutSavedAddress) === zipCode
                )
            )
        ) {
            // If there is an address saved in Redux pre checkout
            // (from Guided Nav, address book)
            // save that as part of add to cart call to autofill in checkout
            const recipientAddress = yield call(getPreCheckoutRecipientAddress);
            reqPayload.recipients = [recipientAddress];
        } else if (locationType && zipCode && item?.delivery?.zipCode !== '00000') {
            reqPayload.recipients = [{
                zipCode,
                locationType,
            }];
        }

        const addtoCartResp = yield call(postAddToCart, reqPayload);
        const [orderId] = addtoCartResp.data.orderId;

        // Order Id
        yield call(orderOperations.setOrderId, {
            data: addtoCartResp.data.orderId,
        });

        yield call(retrieveCart);
        // Last Added Order Item
        const orderItemId = addtoCartResp?.data?.orderItemId?.[0] || '';

        yield put(addToCartActions.loadItemAdded({
            orderItemId,
            orderItemDetails: item,
        }));

        // setting retail price on redux to use it on checkout flow
        // TODO: Remove this once we will get retail price and sale price into cart api response to use this on checkout flow
        const retailPrice = selectedSku?.prices?.[0]?.value || null;
        if (retailPrice) {
            const listOfRetailPrices = yield select(getProductRetailPrice);
            const retailPriceData = [...listOfRetailPrices, { [orderItemId]: retailPrice }];
            yield put(setProductRetailPrice(retailPriceData));
        }

        if (isPassportBundleItem) {
            yield put(subscribePassportBundle(item.productCode));
        }

        const giftMessage = yield select(getAddToCartGiftMessage);

        if (giftMessage && giftMessage.message && orderItemId) {
            const giftMessageObj = giftMessage.message;
            const occasionCode = giftMessage.occasionCode || '';

            yield call(updateGiftMessage, JWT_TOKEN, orderId, orderItemId, giftMessageObj, occasionCode);
            yield put(addToCartActions.loadAddToCartGiftMessage('', ''));
        }

        if (orderId !== '' && enableMinicart) {
            yield call(closeCalendar);
            yield call(handleMiniCartModalClick, true);
        }

        // Track Add-to-Cart
        let trackAddToCartPayload;
        let csEventbaseCode = '';
        const addedItem = yield select(getLastAddedOrderItemDetails);
        if (addedItem.product) {
            const { price, product, itemInfo } = addedItem;
            let pid = product?.productCode;
            if (flagState?.['is-product-id-consistent-events-enabled']) {
                pid = product?.parentProductCode;
            }
            csEventbaseCode = product?.parentProductCode;

            trackAddToCartPayload = {
                eventName: 'add_to_cart',
                eventCategory: 'Product Page', // TODO: find out from gabby
                eventAction: 'Add to Cart', // TODO: find out from gabby
                eventLabel: product.parentProductName,
                products: [
                    {
                        name: product.parentProductName,
                        partNumber: pid,
                        baseCode: flagState?.['is-product-id-consistent-events-enabled'] ? getBaseCode(pid) : getBaseCode(product.productCode),
                        variant: product?.productCode,
                        brand: product.brandCode,
                        sKUs: [{
                            partNumber: pid,
                        }],
                        price,
                        currency: 'USD',
                        quantity: itemInfo?.quantity,
                        position: 1,
                        categoryId: categoryId || '',
                        categoryName: categoryName || '',
                        categoryPath: categoryPath || '',
                    },
                ],
            };

            yield put(trackEvent(trackAddToCartPayload));
        }

        if (movieSelected) {
            const movieItem = {
                ...item, brandCode: '1001', productCode: movieSelected.skuSelected.sku, lineItemType: 'NBCU',
            };
            yield call(onAddToCart, { item: movieItem });
        }

        if (isPassportBundle?.enable) {
            yield put(subscribePassportBundle(isPassportBundle, history));
        }

        if (flagState['is-personalization-recently-viewed-products-enabled']) {
            yield call(persistCheckout, item);
        }

        const clickStreamCartPayload = payloadAddToCartCS({
            location, addToCartResp: addtoCartResp.data, trackPayload: trackAddToCartPayload, zipCode: item?.delivery?.zipCode, csEventbaseCode,
        });

        yield put(addToCartActions.loadAddToCartloader(false));
        yield put(addToCartActions.addToCartSuccess(clickStreamCartPayload));

        mbpLogger.logInfo({
            function: 'onAddToCart',
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            message: 'onAddToCart - success',
            item,
            orderId,
        });

        // Route User Based on Product type to wrap up, addon, shipping or cart page
        // Get the product type according to the current product has been added.
        const productInfo = addedItem?.product || {};
        const deliveryType = productInfo?.productType || '';
        const isDonationItem = addedItem?.itemInfo?.lineItemType === 'DONATION';
        const sympathyValue = productInfo?.sympathyValue === '1' || productInfo?.sympathyValue === '2';
        const internationalCountryCode = addedItem?.deliveryInfo?.countryCode || 'USA';
        const isInternationalProduct = !['USA', 'CAN'].includes(internationalCountryCode);

        // The following if statements are for 18F specific navigation.
        // If the 18F specific params are not passed, the user will be navigated to the cart page as per the previous saga behavior
        if (!enableMinicart) {
            const shouldShowAddonsPage = history
                && deliveryType === 'FPT'
                && !skipAddons
                && !isDonationItem
                && flagState['is-addons-enabled-from-pdp']
                && !(flagState['is-funeral-home-skip-addons-enabled']
                    && locationType === 'Funeral home')
                && !isInternationalProduct
                // subscription product flow
                && !(typeof (item.subscription) !== 'undefined'
                    && flagState['is-subscriptions-skipping-addons'])
                && !item.isOneClickExperience;

            const shouldShowWrapUpPage = history
                && deliveryType === 'GPT'
                && locationType !== 'Funeral home'
                && !skipAddons
                && !isDonationItem
                && flagState['is-wrapup-enabled']
                && !sympathyValue
                && !isInternationalProduct
                && showWrapup !== 'false'
                // subscription product flow
                && !(typeof (item.subscription) !== 'undefined'
                    && flagState['is-subscriptions-skipping-addons'])
                && !item.isOneClickExperience;

            if (shouldShowAddonsPage) {
                yield call(history.push, '/add-ons', {
                    categoryId, partNumber, deliveryDate, showWrapup, atcAction: flagState['is-atc-smooth-transition-enabled'],
                });
            } else if (shouldShowWrapUpPage) {
                yield call(history.push, '/wrap-up', { categoryId, partNumber, deliveryDate });
            } else if (history) {
                // User is Remembered ?
                const userProfile = yield select(getProfileInfo);
                const isAuth = yield select(getIsAuthenticatedStatus);
                const cartCount = yield select(commonSelectors.getCartCount);
                const orderContainsSubscriptionItem = yield select(commonSelectors.getOrderContainsSubscriptionItem);

                let isRememberedUser = false;
                if (userProfile.email) {
                    isRememberedUser = true;
                }

                if (orderContainsSubscriptionItem.length > 0 && flagState['is-subscriptions-skipping-addons'] && !isAuth) {
                    yield put(triggerInterstitialLogin(history, true));
                } else if (flagState['is-native-interstitial-page-enabled'] && !isAuth && !isRememberedUser) {
                    yield put(triggerInterstitialLogin(history));
                } else if (cartCount > 1) {
                    yield call(history.push, `/checkout/cart/${orderId}`);
                } else {
                    yield call(history.push, `/checkout/shipping/${orderId}`);
                }
            }
        }
        // sync application with what went to the backend
        yield put(setUserSubmittedProductFilterZipcode(zipCode));
        yield put(setLocationType(locationType));

        // Retrive current cart
        // Send cart snapshot to cart track event
        const fdOrder = yield select(commonSelectors.getCartOrder);
        yield call(trackCartUpdate, flagState, 'EventAddToCart', fdOrder);
    } catch (ex) {
        yield put(callHandleCartFailure(ex));

        mbpLogger.logError({
            item,
            history,
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            function: 'onAddToCart',
            jsError: ex,
            message: 'onAddToCart Failed',
            orderId: logOrderId,
        });

        const { response } = ex;
        if (response
            && response.status
            && ['401', '403'].includes(response.status.toString())
            && !circuitBreakAddToCart()) {
            mbpLogger.logError({
                function: 'onAddToCart',
                appName: process.env.npm_package_name,
                module: 'Checkout-Operations',
                message: `onAddToCart Failed - Status code: ${response.status} proceeding to get access token`,
                orderId: logOrderId,
            });
            yield call(handleAuthorizationApiFailures, onAddToCart, args);
        }

        yield put(addToCartActions.loadAddToCartloader(false));
        yield put(addToCartActions.addToCartFailed(ex));
    }
}

function* routeAfterAddingAddonWrapUp(history) {
    const orderId = yield select(commonSelectors.getOrderId);
    const isAuthenticated = yield select(getIsAuthenticatedStatus);
    const orderContainsSubscriptionItem = yield select(commonSelectors.getOrderContainsSubscriptionItem);

    const flagState = yield select(getFeatureFlags);
    const checkoutFeatureFlags = yield select(getCheckoutFeatureFlags);
    const cartCount = yield select(commonSelectors.getCartCount);
    const userProfile = yield select(getProfileInfo);

    let isRememberedUser = false;
    if (userProfile?.email) {
        isRememberedUser = true;
    }

    if (history) {
        if (orderContainsSubscriptionItem.length > 0 && !isAuthenticated) {
            yield put(triggerInterstitialLogin(history, true));
        } else if (
            flagState['is-native-interstitial-page-enabled']
            && !isAuthenticated
            && !isRememberedUser
        ) {
            yield put(triggerInterstitialLogin(history));
        } else if (cartCount > 1) { // redirect added to handle case where there is one item in the cart
            yield call(history.push, `/checkout/cart/${orderId}`);
        } else {
            yield call(history.push, `/checkout/shipping/${orderId}`);
        }
    }
}

function* onAddAddonsToCart({
    history,
    addonData,
    orderItemId,
    selectedAddOnItems,
}) {
    const orderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(getFeatureFlags);
    try {
        mbpLogger.logInfo({
            function: 'onAddAddonItemsToCart',
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            message: 'onAddAddonItemsToCart - Start',
            addonData,
            orderItemId,
        });

        yield put(addToCartActions.clearAddToCartError());
        yield put(addToCartActions.loadAddToCartloader(true));

        const JWT_TOKEN = yield call(checkJWT);

        yield call(postAddAddonsToCart, {
            addonData,
            orderItemId,
            JWT_TOKEN,
            orderId,
        });

        yield call(retrieveCart);

        // Track Add Add on to cart
        yield put(trackEvent({
            eventName: 'add_to_cart',
            eventCategory: 'Shopping',
            eventAction: 'Product AddOn Added',
            products: Object.keys(selectedAddOnItems).map((key) => {
                const {
                    name, groupName, brandId, partNumber, prices, currency,
                } = selectedAddOnItems[key];

                return {
                    name: groupName || name || '',
                    partNumber,
                    brand: brandId || '',
                    baseCode: getBaseCode(partNumber),
                    sKUs: [{
                        partNumber,
                    }],
                    prices,
                    currency: currency || 'USD',
                    quantity: '1',
                    position: 1,
                    categoryName: 'AddOns' || '',
                };
            }),
        }));

        yield put(addToCartActions.loadAddToCartloader(false));
        const clickstreamPayload = yield call(addOnPayloadAddToCartCS);
        yield put(addToCartActions.addToCartSuccess(clickstreamPayload));

        mbpLogger.logInfo({
            function: 'onAddAddonItemsToCart',
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            message: 'onAddAddonItemsToCart - success',
            addonData,
            orderItemId,
        });

        // Retrive current cart
        // Send cart snapshot to cart track event
        const fdOrder = yield select(commonSelectors.getCartOrder);
        yield call(trackCartUpdate, flagState, 'EventAddToCart', fdOrder);
    } catch (ex) {
        mbpLogger.logError({
            addonData,
            history,
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            function: 'onAddAddonItemsToCart',
            jsError: ex,
            message: 'onAddAddonItemsToCart Failed',
            orderItemId,
        });

        yield put(addToCartActions.loadAddToCartloader(false));
        yield put(addToCartActions.addToCartFailed(ex));
    }

    if (history) {
        yield call(routeAfterAddingAddonWrapUp, history);
    }
}

function* onAddWrapupsToCart({ wrapupData, history }) {
    const orderId = yield select(commonSelectors.getOrderId);
    try {
        mbpLogger.logInfo({
            function: 'onAddWrapupsToCart',
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            message: 'onAddWrapupsToCart - Start',
            wrapupData,
            orderId,
        });
        yield put(addToCartActions.clearAddToCartError());
        yield put(addToCartActions.loadAddToCartloader(true));

        const JWT_TOKEN = yield call(checkJWT);

        yield call(postAddWrapupsToCart, {
            wrapupData,
            JWT_TOKEN,
            orderId,
        });

        yield call(retrieveCart);

        const clickStreamPayload = yield call(wrapupPayloadAddToCartCS, wrapupData, orderId);
        yield put(addToCartActions.addToCartSuccess(clickStreamPayload));

        mbpLogger.logInfo({
            function: 'onAddWrapupsToCart',
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            message: 'onAddWrapupsToCart - success',
            wrapupData,
            orderId,
        });

        const flagState = yield select(getFeatureFlags);

        // Retrive current cart
        // Send cart snapshot to cart track event
        const fdOrder = yield select(commonSelectors.getCartOrder);
        yield call(trackCartUpdate, flagState, 'EventAddToCart', fdOrder);
        yield call(routeAfterAddingAddonWrapUp, history);
    } catch (ex) {
        mbpLogger.logError({
            wrapupData,
            history,
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            function: 'onAddWrapupsToCart',
            jsError: ex,
            message: 'onAddWrapupsToCart Failed',
            orderId,
        });

        yield put(addToCartActions.loadAddToCartloader(false));
        yield put(addToCartActions.addToCartFailed(ex));
    }
}

function* onAddAddonsAndWrapupToCart({
    addonData, history, orderItemId, selectedAddOnItems, wrapupData,
}) {
    const orderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(getFeatureFlags);
    try {
        yield put(addToCartActions.loadAddToCartloader(false));
        yield put(addToCartActions.clearAddToCartError());

        mbpLogger.logInfo({
            function: 'onAddAddonsAndWrapupToCart',
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            message: 'onAddAddonsAndWrapupToCart - Start',
            addonData,
            orderItemId,
        });

        const JWT_TOKEN = yield call(checkJWT);

        if (addonData) {
            yield call(postAddAddonsToCart, {
                addonData,
                orderItemId,
                JWT_TOKEN,
                orderId,
            });
        }

        if (wrapupData) {
            yield call(postAddWrapupsToCart, {
                wrapupData,
                JWT_TOKEN,
                orderId,
            });
        }

        yield call(retrieveCart);

        // Track Add to Cart
        yield put(trackEvent({
            eventName: 'add_to_cart',
            eventCategory: 'Shopping',
            eventAction: 'Product AddOn Added',
            products: Object.keys(selectedAddOnItems).map((key) => {
                const {
                    name, groupName, brandId, partNumber, prices, currency,
                } = selectedAddOnItems[key];

                return {
                    name: groupName || name || '',
                    partNumber,
                    brand: brandId || '',
                    sKUs: [{
                        partNumber,
                    }],
                    prices,
                    currency: currency || 'USD',
                    quantity: '1',
                    position: 1,
                    categoryName: 'AddOns' || '',
                };
            }),
        }));

        mbpLogger.logInfo({
            function: 'onAddAddonItemsToCart',
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            message: 'onAddAddonItemsToCart - success',
            addonData,
            orderItemId,
        });

        yield put(addToCartActions.loadAddToCartloader(false));
        const clickStreamPayload = yield call(addOnAndWrapupPayloadAddToCartCS, wrapupData, addonData, orderId);

        yield put(addToCartActions.addToCartSuccess(clickStreamPayload));

        // Retrive current cart
        // Send cart snapshot to cart track event
        const fdOrder = yield select(commonSelectors.getCartOrder);
        yield call(trackCartUpdate, flagState, 'EventAddToCart', fdOrder);
    } catch (ex) {
        mbpLogger.logError({
            addonData,
            history,
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            function: 'onAddAddonItemsToCart',
            jsError: ex,
            message: 'onAddAddonItemsToCart Failed',
            orderItemId,
        });

        yield put(addToCartActions.loadAddToCartloader(false));
        yield put(addToCartActions.addToCartFailed(ex));
    }

    yield call(routeAfterAddingAddonWrapUp, history);
}

function* workerUpsellsToCart(action) {
    const {
        data,
        history,
    } = action.payload;
    const cartId = yield select(commonSelectors.getOrderId);
    const cartItemId = data?.cartItemId || '';
    const products = Array.isArray(data?.products) && data?.products.length > 0 ? data?.products : [];
    const flagState = yield select(getFeatureFlags);

    try {
        mbpLogger.logInfo({
            function: 'workerUpsellsToCart',
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            message: 'workerUpsellsToCart - Start',
            data,
            cartItemId,
        });

        yield put(addToCartActions.loadAddToCartloader(true));

        const jwtToken = yield call(checkJWT);
        const payload = {
            env: {},
            jwtToken,
            cartId,
            data,
        };
        yield call(cartServices.addUpsellsToCart, payload);

        // Get the cart information
        yield call(retrieveCart);

        // Track Add Add on to cart
        yield put(trackEvent({
            eventName: 'add_to_cart',
            eventCategory: 'Shopping',
            eventAction: 'Product AddOn Added',
            products: Object.keys(products).map((key) => {
                const {
                    name, groupName, brandId, partNumber, prices, currency,
                } = products[key];

                return {
                    name: groupName || name || '',
                    partNumber,
                    brand: brandId || '',
                    baseCode: getBaseCode(partNumber),
                    sKUs: [{
                        partNumber,
                    }],
                    prices,
                    currency: currency || 'USD',
                    quantity: '1',
                    position: 1,
                    categoryName: 'AddOns' || '',
                };
            }),
        }));

        // const clickStreamPayload = yield call(addOnAndWrapupPayloadAddToCartCS, wrapupData, addonData, orderId);
        const  filteredAddons = products.filter(
            (product) => product.lineItemType === 'ADDON',
        );

        const lastItem = yield select(getLastAddedOrderItemDetails);
        const ADD_ON_PAGE = {
            title: 'add-ons',
            type: 'add-ons',
        };
        const WRAP_UP_PAGE = {
            title: 'wrap-up',
            type: 'wrap-up',
        };

        const clickStreamPayload = {
            eventType: 'clickstream.add-cart',
            page: filteredAddons.length > 0 ? ADD_ON_PAGE : WRAP_UP_PAGE,
            metaData: {
                products,
                order: { orderId: cartId },
                zipCode: lastItem?.deliveryInfo?.zipCode || '',
            },
        };

        yield put(addToCartActions.addToCartSuccess(clickStreamPayload));

        mbpLogger.logInfo({
            function: 'workerUpsellsToCart',
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            message: 'workerUpsellsToCart - success',
            data,
            cartItemId,
        });

        // Send cart snapshot to cart track event
        const fdOrder = yield select(commonSelectors.getCartOrder);
        yield call(trackCartUpdate, flagState, 'EventAddToCart', fdOrder);
        yield call(routeAfterAddingAddonWrapUp, history);
    } catch (ex) {
        mbpLogger.logError({
            data,
            history,
            appName: process.env.npm_package_name,
            module: 'AddToCart-Operations',
            function: 'workerUpsellsToCart',
            jsError: ex,
            message: 'workerUpsellsToCart Failed',
            cartItemId,
        });
        yield put(addToCartActions.addToCartFailed(ex));
        yield put(addToCartActions.loadAddToCartloader(false));
    }
}

function* watchUpsellsToCart() {
    yield takeLatest(addToCartActions.addUpsellsToCart().type, workerUpsellsToCart);
}

const circuitBreakAddToCartFood = circuitBreaker(2);

const circuitBreakBuyAgain = circuitBreaker(2);

function* workerOnBuyAgain(data) {
    try {
        const { orders, history } = data;
        const JWT_TOKEN = yield call(checkJWT);
        const flagState = yield select(getFeatureFlags);
        yield put(addToCartActions.loadAddToCartloader(true));
        yield put(addToCartActions.loadItemAdded(null));
        yield put(addToCartActions.clearAddToCartError());

        if (orders?.length) {
            let pmallProductSku = null;

            for (let i = 0; i < orders?.length; i += 1) {
                const orderDetails = orders[i];
                const { items: itemDetailList } = orderDetails;
                const recipientDetail = orderDetails?.recipientProfiles?.[0] || {};

                if (itemDetailList?.length) {
                    const payloadGenerator = yield new PayloadGeneratorFromOrderHistory();
                    // Get payload item object from payload generator
                    const item = yield call(payloadGenerator.generatePayloadFromOrderHistoryItem, {
                        itemDetailList, // getting first index for now
                        recipient: recipientDetail,
                    });
                    // get recipients from payload generator
                    const recipients = yield call(payloadGenerator.getRecipients);
                    const reqPayload = {
                        item,
                        recipients,
                        JWT_TOKEN,
                        giftHistoryOrderItemId: '',
                    };

                    if (item.brandCode === '1030' || item.brandCode === '1033') { // Pmall products
                        yield put(setUserSelectedAddress({
                            checkoutAutoFill: true,
                            addressSource: 'buyAgain',
                            recipient: recipients?.[0],
                        }));
                        yield put(setUserSubmittedProductFilterZipcode(recipients?.[0]?.zipCode));

                        pmallProductSku = `1030-${item.productCode}`;
                    } else {
                        const addtoCartResp = yield call(postAddToCart, reqPayload);
                        yield call(orderOperations.setOrderId, {
                            data: addtoCartResp?.data?.orderId,
                        });
                    }
                }

                if (!pmallProductSku) {
                    const orderId = yield select(commonSelectors.getOrderId);

                    yield call(retrieveCart);

                    // order prepare call -- this generates delivery date for product
                    yield call(prepareBuyAgainOrder, { JWT_TOKEN, orderId });

                    yield put(addToCartActions.loadAddToCartloader(false));
                    yield put(addToCartActions.addToCartSuccess());
                    // redirect user to shipping page
                    if (history) {
                        yield call(history.push, `/checkout/shipping/${orderId}`);
                    }
                } else {
                    yield call(history.push, `/searchterm/${pmallProductSku}`);
                }
            }
        }
        // Retrive current cart
        // Send cart snapshot to cart track event
        const fdOrder = yield select(commonSelectors.getCartOrder);
        yield call(trackCartUpdate, flagState, 'EventAddToCart', fdOrder);
    } catch (ex) {
        yield put(callHandleCartFailure(ex));

        mbpLogger.logError({
            function: 'workerOnBuyAgain',
            appName: process.env.npm_package_name,
            module: 'AddToCart-Operations.js',
            jsError: ex,
        });

        const { response } = ex;
        if (response
            && response.status
            && ['401', '403'].includes(response.status.toString())
            && !circuitBreakBuyAgain()) {
            mbpLogger.logError({
                function: 'workerOnBuyAgain',
                appName: process.env.npm_package_name,
                module: 'Checkout-Operations',
                message: `onAddToCart Failed - Status code: ${response.status} proceeding to get access token`,
            });
            yield call(handleAuthorizationApiFailures, onAddToCart, data);
        }

        yield put(addToCartActions.loadAddToCartloader(false));
        yield put(addToCartActions.addToCartFailed(ex));
    }
}

function* addToCartPmallAddons({ jwtToken, products }) {
    try {
        yield call(cartServices.addToCart, {
            env: {},
            jwtToken,
            products,
        });
    } catch (err) {
        mbpLogger.logError({
            function: 'addToCartPmallAddons',
            module: 'mbp-pwa-ui',
            message: 'Error addToCart pmall addons',
            jsError: err,
        });
    }
}

// Add to Cart New Cart services
// For Now this add to cart will support add pmall product
// and do a second add to cart call to add the addons
// but this will not stop the user to go checkout if the addons failed to
// be added.

// IMPORTANT: +++++++++++++++++++++++
// for the other kind of type of products that exist in the brands will need
// extra modifications.
function* workerAddToCartNewCartServices(action) {
    try {
        const {
            addonsAsProduct,
            categoryId,
            categoryName,
            categoryPath,
            closeCalendar,
            deliveryDate,
            enableMinicart,
            eventType,
            handleMiniCartModalClick,
            history,
            passportBundle,
            item, // array - main product
            movieSelected,
            partNumber,
            quickviewLocationType,
            showWrapup,
            skipAddons,
            location,
            displayDeliveryCalendar,
            standAloneCardData,
            isPersistAddressEnabled,
            isWrapUpEnabled,
            salesforceResponseWrapUpShowHide,
        } = action.payload;
        yield put(addToCartActions.loadAddToCartloader(true));
        yield put(addToCartActions.clearAddToCartError());

        // Item in position 0 is the main item
        if (item?.length > 0) {
            const jwtToken = yield call(checkJWT);
            const flagState = yield select(getFeatureFlags);

            // use what is going to the backend, set the application state below
            let zipCode = item?.delivery?.zipCode || '';
            if (!zipCode) {
                zipCode = yield select(getUserSubmittedProductFilterZipcode);
            }

            /**
             * Because the category page re-renders based on the location type in the redux store
             * and quickview is on the category page, setting the redux store from quickview modal
             * causes bugs.
             *
             * reconcile the two here.
             */
            let locationType = yield select(getLocationType);
            if (quickviewLocationType) {
                locationType = quickviewLocationType;
            }

            const reqPayload = {
                env: {},
                jwtToken,
                products: item,
            };

            const preCheckoutSavedAddress = yield select(getSavedAddress);
            const productFamily = getBrandPresentationFamily(item?.[0]?.brandCode || '1001');

            if (
                item?.[0]?.delivery?.zipCode !== '00000'
                && productFamily === 'flower'
                && (
                    (
                        isSCIAddressAutoFillable(preCheckoutSavedAddress, locationType, zipCode, isPersistAddressEnabled)
                        && !['buyAgain'].includes(preCheckoutSavedAddress.addressSource)
                    )
                    || (
                        ['buyAgain'].includes(preCheckoutSavedAddress.addressSource)
                        && zipCode
                        && getZipByAddressSource(preCheckoutSavedAddress, isPersistAddressEnabled) === zipCode
                    )
                )
            ) {
                // If there is an address saved in Redux pre checkout
                // (from Guided Nav, address book)
                // save that as part of add to cart call to autofill in checkout
                const recipientAddress = yield call(getPreCheckoutRecipientAddress, isPersistAddressEnabled);
                if (recipientAddress.address1) {
                    //  New API need three letter country
                    let country = recipientAddress.country;
                    if (country.length === 2) {
                        country = getCountryCodeFromTwoToThreeDigit(recipientAddress.country);
                    }

                    reqPayload.products[0].recipientInfo = {
                        addressId: recipientAddress.addressId,
                        entryId: recipientAddress.addressId,
                        recipient: {
                            ...recipientAddress,
                            country,
                            isAddressVerified: recipientAddress.isAddressVerified === 'V',
                            isAddressUpdate: false,
                            addressStatus: recipientAddress.isAddressVerified,
                        },
                    };
                }
            } else if (
                locationType
                && zipCode
                && item?.[0]?.delivery?.zipCode !== '00000'
                && (
                    productFamily === 'flower'
                    || (flagState['is-new-delivery-calendar-enabled'] && displayDeliveryCalendar)
                )
            ) {
                reqPayload.products[0].recipientInfo = {
                    addressId: '',
                    entryId: '',
                    recipient: {
                        zipCode,
                        locationType,
                    },
                };
            }

            const giftMessage = yield select(getAddToCartGiftMessage);

            if (giftMessage && giftMessage.message) {
                reqPayload.products[0].greeting = {
                    ...giftMessage,
                };
                yield put(addToCartActions.loadAddToCartGiftMessage('', ''));
            }

            /*
             * Add to Cart Main product
             */
            const addtoCartResp = yield call(cartServices.addToCart, reqPayload);

            const cartId = addtoCartResp.data.cartId;

            yield call(orderOperations.setOrderId, {
                data: [addtoCartResp.data.cartId],
            });

            // Cleaning up Personalization for TPF
            if (addtoCartResp.status === 200 && reqPayload?.products?.[0]?.components?.[0]?.componentType === 'PERSONALIZATION') {
                yield put(clearGCIData());
            }

            // Add to cart Pmall addons as extra products
            if (addonsAsProduct?.length > 0) {
                yield call(addToCartPmallAddons, {
                    jwtToken,
                    products: addonsAsProduct,
                });
            }

            // Clean personalization info
            if (item?.[0]?.brandCode === '1030' || item?.[0]?.brandCode === '1033' || item?.[0]?.personalization?.personalizationType === 'EXTERNAL') {
                yield put(updatePmallImages());
                yield put(removePmallItem());
            }
            // Adding  NBCU Products
            if (movieSelected) {
                const movieItem = [{
                    brandCode: '1001',
                    productCode: movieSelected.skuSelected.sku,
                    lineItemType: 'NBCU',
                }];

                yield call(cartServices.addToCart, {
                    env: {},
                    jwtToken,
                    products: movieItem,
                });
            }

            // Addins passport bundles
            if (passportBundle?.enable) {
                yield put(subscribePassportBundle(passportBundle, history));
            }

            if (standAloneCardData) {
                yield call(cartServices.addGreetingCardItems, {
                    env: {},
                    jwtToken,
                    ...standAloneCardData,
                });
            }

            yield call(retrieveCart);

            // const flagState = yield select(getFeatureFlags);
            if (flagState['is-personalization-recently-viewed-products-enabled']) {
                yield call(persistCheckout, item);
            }

            // TODO: (July-12-2023) - Need to check if this code for retail price is really  need since
            // checkout is in MFE now. Taked from the function onAddToCart

            // setting retail price on redux to use it on checkout flow
            // TODO: Remove this once we will get retail price and sale price into cart api response to use this on checkout flow
            // const retailPrice = selectedSku?.prices?.[0]?.value || null;
            // if (retailPrice) {
            //     const listOfRetailPrices = yield select(getProductRetailPrice);
            //     const retailPriceData = [...listOfRetailPrices, { [orderItemId]: retailPrice }];
            //     yield put(setProductRetailPrice(retailPriceData));
            // }
            // TODO: (July-12-2023)

            if (cartId !== '' && enableMinicart && handleMiniCartModalClick) {
                if (closeCalendar) {
                    yield call(closeCalendar);
                }
                yield call(handleMiniCartModalClick, true);
            }

            mbpLogger.logInfo({
                function: 'onAddToCartNewCartServices',
                appName: process.env.npm_package_name,
                module: 'AddToCart-Operations',
                message: 'onAddToCartNewCartServices - success',
                item,
                cartId,
            });

            // Track Add-to-Cart
            let trackAddToCartPayload;
            let csEventbaseCode = '';
            const addedItem = yield select(getLastAddedOrderItemDetails);

            if (addedItem && addedItem.product) {
                const { itemInfo, product } = addedItem;
                let pid = product?.productCode;
                if (flagState?.['is-product-id-consistent-events-enabled']) {
                    pid = product?.parentProductCode;
                }
                csEventbaseCode = product?.parentProductCode;

                trackAddToCartPayload = {
                    eventName: 'add_to_cart',
                    eventCategory: eventType || 'Product Page', // TODO: find out from gabby
                    eventAction: 'Add to Cart', // TODO: find out from gabby
                    eventLabel: product.parentProductName,
                    products: [
                        {
                            name: product.parentProductName,
                            partNumber: pid,
                            baseCode: flagState?.['is-product-id-consistent-events-enabled'] ? getBaseCode(pid)  : getBaseCode(product.productCode),
                            brand: product.brandCode,
                            variant: product?.productCode,
                            sKUs: [{
                                partNumber: pid,
                            }],
                            price: itemInfo.price,
                            quantity: itemInfo.quantity,
                            currency: itemInfo.currency || 'USD',
                            position: 1,
                            categoryId: categoryId || '',
                            categoryName: categoryName || '',
                            categoryPath: categoryPath || '',
                            subscription: {
                                duration: reqPayload?.products?.[0]?.subscription?.duration || null,
                                interval: reqPayload?.products?.[0]?.subscription?.interval || null,
                                type: reqPayload?.products?.[0]?.subscription?.type || null,
                            },
                        },
                    ],
                };

                yield put(trackEvent(trackAddToCartPayload));
            }

            if (flagState['is-personalization-recently-viewed-products-enabled']) {
                yield call(persistCheckout, item);
            }

            const clickstreamPayload = payloadAddToCartCS({
                location,
                addToCartResp: addtoCartResp.data,
                trackPayload: trackAddToCartPayload,
                zipCode: item?.delivery?.zipCode,
                csEventbaseCode,
            });

            yield put(addToCartActions.loadAddToCartloader(false));
            yield put(addToCartActions.addToCartSuccess(clickstreamPayload));

            mbpLogger.logInfo({
                function: 'workerAddToCartNewCartServices',
                appName: process.env.npm_package_name,
                module: 'AddToCart-Operations',
                message: 'workerAddToCartNewCartServices - success',
                item,
                orderId: cartId,
            });

            // WRAPUP SHOW/HIDE AB TEST FOR 18F GPT PRODUCTS
            // DELETE WHEN FINISHED
            const featureFlags = yield select(getFeatureFlags);
            if (featureFlags['is-ab-test-show-or-hide-wrapup-for-gpt-products-enabled']) {
                // SF API returns salesforceResponseWrapUpShowHide if flag true and gpt product for 18F
                if (salesforceResponseWrapUpShowHide && salesforceResponseWrapUpShowHide?.campaign?.campaignResponses?.length > 0) {
                    // Clickstream
                    yield put(emitCustomTrackingEvent({
                        action: 'Impression',
                        salesforceResponse: salesforceResponseWrapUpShowHide,
                        page: { type: 'product' },
                        eventSubType: 'campaign-tracking',
                    }));

                    if (isWrapUpEnabled) {
                        // control
                        // GA
                        yield put(trackEvent({
                            eventCategory: 'Experiment',
                            eventAction: 'Wrapup Show or Hide',
                            eventLabel: 'Control',
                        }));
                    } else {
                        // variant
                        // GA
                        yield put(trackEvent({
                            eventCategory: 'Experiment',
                            eventAction: 'Wrapup Show or Hide',
                            eventLabel: 'Variant',
                        }));
                    }
                }
            }
            // WRAPUP SHOW/HIDE AB TEST FOR 18F GPT PRODUCTS
            // DELETE WHEN FINISHED

            // Route User Based on Product type to wrap up, addon, shipping or cart page
            // Get the product type according to the current product has been added.
            const productItem = addedItem?.product || {};
            const deliveryType = productItem?.productType || '';
            const isDonationItem = addedItem?.itemInfo?.lineItemType === 'DONATION';
            const sympathyValue = productItem?.sympathyValue === '1' || productItem?.sympathyValue === '2';
            const internationalCountryCode = addedItem?.deliveryInfo?.countryCode || 'USA';
            const isInternationalProduct = !['USA', 'CAN'].includes(internationalCountryCode);
            const checkoutFeatureFlags = yield select(getCheckoutFeatureFlags);
            const productCode = productItem?.productCode;

            // The following if statements are for 18F specific navigation.
            // If the 18F specific params are not passed, the user will be navigated to the cart page as per the previous saga behavior
            if (!enableMinicart) {
                // false true true true false false true false true true
                const shouldShowAddonsPage = history
                    && deliveryType === 'FPT'
                    && !skipAddons
                    && !isDonationItem
                    && flagState['is-addons-enabled-from-pdp']
                    && !(
                        flagState['is-funeral-home-skip-addons-enabled']
                        && locationType === 'Funeral home'
                    )
                    && !isInternationalProduct
                    // subscription product flow
                    && !(
                        typeof (item[0].subscription) !== 'undefined'
                        && flagState['is-subscriptions-skipping-addons']
                    )
                    && !item[0].isOneClickExperience;

                const shouldShowWrapUpPage = history
                    && deliveryType === 'GPT'
                    && isWrapUpEnabled
                    && locationType !== 'Funeral home'
                    && !skipAddons
                    && !isDonationItem
                    && flagState['is-wrapup-enabled']
                    && !sympathyValue
                    && !isInternationalProduct
                    && showWrapup !== 'false'
                    // subscription product flow
                    && !(
                        typeof (item[0].subscription) !== 'undefined'
                        && flagState['is-subscriptions-skipping-addons']
                    )
                    && !item[0].isOneClickExperience;

                if (shouldShowAddonsPage) {
                    yield call(history.push, '/add-ons', {
                        categoryId, partNumber, deliveryDate, showWrapup, atcAction: flagState['is-atc-smooth-transition-enabled'], productCode,
                    });
                } else if (shouldShowWrapUpPage) {
                    yield call(history.push, '/wrap-up', { categoryId, partNumber, deliveryDate });
                } else if (history) {
                    // User is Remembered ?
                    const userProfile = yield select(getProfileInfo);
                    const isAuth = yield select(getIsAuthenticatedStatus);
                    const cartCount = yield select(commonSelectors.getCartCount);
                    const orderContainsSubscriptionItem = yield select(commonSelectors.getOrderContainsSubscriptionItem);

                    let isRememberedUser = false;
                    if (userProfile.email) {
                        isRememberedUser = true;
                    }

                    if (orderContainsSubscriptionItem.length > 0 && flagState['is-subscriptions-skipping-addons'] && !isAuth) {
                        yield put(triggerInterstitialLogin(history, true));
                    } else if (flagState['is-native-interstitial-page-enabled'] && !isAuth && !isRememberedUser) {
                        yield put(triggerInterstitialLogin(history));
                    } else if (cartCount > 1) {
                        yield call(history.push, `/checkout/cart/${cartId || ''}`);
                    } else {
                        yield call(history.push, `/checkout/shipping/${cartId || ''}`);
                    }
                }
            }
            if (cartId !== '' && addtoCartResp.status === 200) {
                yield put(setSelectedBYOItems({}));
            }
            // Retrive current cart
            // Send cart snapshot to cart track event
            const cartOrder = yield select(commonSelectors.getCartOrder);
            yield call(trackCartUpdate, flagState, 'EventAddToCart', cartOrder);
        } else {
            console.log('Error adding Item To Cart');
        }
    } catch (ex) {
        const cartId = yield select(commonSelectors.getOrderId);
        const {
            recipients = [], // Set a default value to prevent object destructuring error args = { recipients, item, history };
            item, // array
            history,
        } = action.payload;
        yield put(callHandleCartFailure(ex));
        console.error('Debug: Error add to cart:', {
            recipients,
            item,
            history,
            appName: process.env.npm_package_name,
            module: 'AddToCart-Operations',
            function: 'onAddToCartNewCartService',
            jsError: ex?.response,
            message: `workerAddToCartNewCartServices Failed - Status code: ${ex?.response?.status || 0}`,
            cartId,
        });
        mbpLogger.logError({
            recipients,
            item,
            history,
            appName: process.env.npm_package_name,
            module: 'AddToCart-Operations',
            function: 'onAddToCartNewCartService',
            jsError: ex,
            message: `workerAddToCartNewCartServices Failed - Status code: ${ex?.response?.status || 0}`,
            cartId,
        });
        if (item?.[0]?.brandCode === '1030' || item?.[0]?.brandCode === '1033' || item?.[0]?.personalization?.personalizationType === 'EXTERNAL') {
            removePmallItem();
        }
        const { response } = ex;
        if (response
            && response.status
            && ['401', '403'].includes(response.status.toString())
            && !circuitBreakAddToCartFood()) {
            mbpLogger.logError({
                function: 'workerAddToCartNewCartServices',
                appName: process.env.npm_package_name,
                module: 'Checkout-Operations',
                message: `workerAddToCartNewCartServices Failed - Status code: ${response.status} proceeding to get access token`,
                orderId: cartId,
            });
            yield call(handleAuthorizationApiFailures, workerAddToCartNewCartServices, { recipients, item, history });
        }

        yield put(addToCartActions.loadAddToCartloader(false));
        yield put(addToCartActions.addToCartFailed(ex));
    }
}

// Watchers

function* watchMbpAddToCartChannel() {
    if (typeof window !== 'undefined') {
        while (true) {
            const action = yield take(mbpAddToCartChannel);
            yield put(action);
        }
    }
}

function* watchAddAddonsToCart() {
    let action = yield take(addToCartActions.addAddonsToCart().type);
    while (action !== END) {
        yield fork(onAddAddonsToCart, action.payload);
        action = yield take(addToCartActions.addAddonsToCart().type);
    }
}

function* watchAddToCart() {
    let action = yield take(addToCartActions.addToCart().type);
    while (action !== END) {
        yield fork(onAddToCart, action.payload);
        action = yield take(addToCartActions.addToCart().type);
    }
}

function* watchMbpAddToCart() {
    let action = yield take(addToCartActions.mbpAddToCart().type);
    while (action !== END) {
        yield fork(onMbpAddToCart, action.payload);
        action = yield take(addToCartActions.mbpAddToCart().type);
    }
}

function* watchAddWrapUpsToCart() {
    let action = yield take(addToCartActions.addWrapUpsToCart().type);
    while (action !== END) {
        yield fork(onAddWrapupsToCart, action.payload);
        action = yield take(addToCartActions.addWrapUpsToCart().type);
    }
}

function* watchAddAddonsAndWrapUpsToCart() {
    let action = yield take(addToCartActions.addAddonsAndWrapupToCart().type);
    while (action !== END) {
        yield fork(onAddAddonsAndWrapupToCart, action.payload);
        action = yield take(addToCartActions.addAddonsAndWrapupToCart().type);
    }
}

function* watchAddToCartFailure() {
    let action = yield take(addToCartActions.addToCartFailed().type);
    while (action !== END) {
        yield fork(workerLogAddToCartError, action.payload);
        action = yield take(addToCartActions.addToCartFailed().type);
    }
}

function* watchOnBuyAgain() {
    let action = yield take(addToCartActions.buyAgain().type);
    while (action !== END) {
        yield fork(workerOnBuyAgain, action.payload);
        action = yield take(addToCartActions.buyAgain().type);
    }
}

function* watchAddToCartNewCartServices() {
    yield takeLatest(addToCartActions.addToCartNewCartServices().type, workerAddToCartNewCartServices);
}

const watchers = [
    fork(watchAddToCart),
    fork(watchAddAddonsToCart),
    fork(watchAddWrapUpsToCart),
    fork(watchAddAddonsAndWrapUpsToCart),
    fork(watchAddToCartFailure),
    fork(watchOnBuyAgain),
    fork(watchMbpAddToCartChannel),
    fork(watchMbpAddToCart),
    fork(watchAddToCartNewCartServices),
    fork(watchUpsellsToCart),
];

export {
    watchers,
    addToCartOnEvent,
};

export default {};
