/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './BillingAddressForm-ActionTypes';

export const updateBillingForm = (data) => ({
    type: actionTypes.UPDATE_BILLING_FORM,
    data,
});

export const billingUpdateFormValue = (event) => ({
    type: actionTypes.BILLING_UPDATE_FORM_VALUES,
    data: event,
});

export const billingClearUpdateFields = (data) => ({
    type: actionTypes.BILLING_CLEAR_UPDATE_FIELDS,
    data,
});

export const billingPrefillFormAddress = (data) => ({
    type: actionTypes.BILLING_PREFILL_FORM_ADDRESS,
    data,
});

export const billingGetAddress = (data) => ({
    type: actionTypes.BILLING_GET_ADDRESS,
    data,
});

export const updateBillingFormStatus = (data) => ({
    type: actionTypes.BILLING_UPDATE_FORM_STATUS,
    data,
});

export const updateBillingAddressAsDeliveryAddress = (data) => ({
    type: actionTypes.BILLING_ADDRESS_SAME_AS_DELIVERY_ADDRESS,
    data,
});

export const updateBillingFormAutoFilled = (data) => ({
    type: actionTypes.BILLING_UPDATE_FORM_AUTOFILLED,
    data,
});

export const updateCheckBillingAddressSameForm = (data) => ({
    type: actionTypes.CHECK_BILLING_SAME_RECIEPENT_FORM,
    data,
});

export const triggerStateCityData = (data) => ({
    type: actionTypes.BILLING_GET_STATE_CITY_DATA,
    data,
});

export const updateSaveUserDefaultBilling = (data) => ({
    type: actionTypes.UPDATE_SAVE_USER_DEFAULT_BILLING,
    data,
});

export const isRememberMeChecked = (data) => ({
    type: actionTypes.IS_REMEMBER_ME_CHECKED,
    data,
});
