/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { END } from 'redux-saga';
import {
    put, fork, call, takeEvery, select, take,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import * as addToCartActions from '../../../AddToCart/AddToCart-Actions';
import { trackEvent } from '../../../TagManager/ducks/TagManager/TagManager-Actions';
import { recipientFormFields } from '../RecipientForm/RecipientForm-Helpers';
import { setOrderId } from '../../../Order/Order-Operations';
import {
    cardIsleAvailable,
    loadCardIsle,
    addCardIsle,
    addCardIsleComplete,
    enterpriseAddCardIsle,
    addCardIsleGiftCardToCart,
    addCardIsleGiftCardToCartComplete,
    // resetCardIsle,
} from './CardIsle-Actions';
import { loadError } from '../../../Common/ducks/Error/Error-Actions';
import {
    getCartOrder, getUserSubmittedProductFilterZipcode, getOrderId,
    getPassportItem,
} from '../../../Common/Common-Selectors';
import { getCardIsleSelected } from './CardIsle-Selector';
import orderClient from '../../../../../apis/checkout-apis/orderClient';
import { checkJWT } from '../../../Member/ducks/Auth/Auth-Operations';
import { retrieveCart } from '../../../Cart/Cart-Operations';
import { getFeatureFlags } from '../../../Checkout/ducks/Global/Global-Selectors';
import {
    getIsPassportUserLoggedIn, getMemberLoyalty, getProfileInfo, getIsAuthenticatedStatus,
} from '../../../Member/ducks/Common/Common-Selectors';
import getPriceFromEngine from '../../../../../apis/product-apis/priceEngine';
import { processFeatureErrors } from '../../../Checkout/ducks/Common/ducks/Error/Error-Operations';
import { getActiveABTest } from '../../../App/ducks/ABTesting/ABTesting-Selectors';
import cartServices from '../../../../../apis/cart-service-apis/cartServices';
import { triggerInterstitialLogin } from '../../../Member/ducks/Auth/Auth-Actions';
// empty space is used to avoid card isle print - no card message
// this happens when user does not add message on card isle window

const getGreetingAvailability = ({
    jwtToken,
    productCode,
    brandCode,
    zipCode,
    locationType,
    deliveryDate,
}) => orderClient.getGreetingAvailability(
    {},
    jwtToken,
    productCode,
    brandCode,
    zipCode,
    locationType,
    deliveryDate,
);

function* loadCardIsleAvailable() {
    try {
        const [orderItemToEdit] = yield select(getCartOrder);

        if (orderItemToEdit && orderItemToEdit.recipientAddress) {
            const [cartItems] = orderItemToEdit.cartItems;
            const { product } = cartItems;
            const brandCode = product.brandCode || '';

            let productCode = product.productCode || '';
            if (productCode?.indexOf('-') >= 0) {
                productCode = productCode.split('-');
                productCode = productCode[productCode.length - 1];
            }

            const currentDate = cartItems?.deliveryInfo?.deliveryDate ? new Date(cartItems.deliveryInfo?.deliveryDate) : null;
            if (currentDate) {
                const currentDay = (`0${currentDate.getDate()}`).slice(-2);
                const currentMonth = (`0${currentDate.getMonth() + 1}`).slice(-2);

                const deliveryDate = currentDate ? `${currentMonth}/${currentDay}/${currentDate.getFullYear()}` : null;

                const pdpZipCode = yield select(getUserSubmittedProductFilterZipcode);
                const recipientFormData = recipientFormFields(orderItemToEdit.recipientAddress);

                if (recipientFormData && (cartItems?.zipCode || recipientFormData.zipCode) && recipientFormData.locationType) {
                    const jwtToken = yield call(checkJWT);
                    const configObj = {
                        jwtToken,
                        productCode,
                        brandCode,
                        zipCode: recipientFormData.zipCode || cartItems.zipCode || pdpZipCode,
                        locationType: recipientFormData.locationType || 'Residence',
                        deliveryDate,
                    };

                    const response = yield call(getGreetingAvailability, configObj);
                    yield put(loadCardIsle(response.data));

                    // Track Card Isle Impression - Available
                    if (response.data?.available) {
                        const taggingCardIsle = {
                            eventCategory: 'Checkout',
                            eventAction: 'Card Isle',
                            eventLabel: 'Impression',
                        };
                        yield put(trackEvent(taggingCardIsle));
                    }
                }
            } else {
                mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    module: 'mbp-pwa-ui',
                    function: 'loadCardIsleAvailable',
                    currentDate,
                    message: 'Failed in getting cardIsle availability',
                });
                yield put(loadError({
                    errType: 'cardIsle',
                    errMsg: 'We are sorry, there seems to be a technical issue with cardIsle delivery date, please try again later or use alternate payment method to complete order',
                }));
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'loadCardIsleAvailable',
            jsError: ex,
            message: 'Failed in getting cardIsle availability',
        });
        yield put(loadError({
            errType: 'cardIsle',
            errMsg: 'We are sorry, there seems to be a technical issue with cardIsle payment method, please try again later or use alternate payment method to complete order',
        }));
    }
}

function* loadCardIsleAvailableWatcher() {
    yield takeEvery(cardIsleAvailable().type, loadCardIsleAvailable);
}

function* addCardIsleToCart(orderItemId, recipientFormData) {
    try {
        const [orderItemToEdit] = yield select(getCartOrder);
        const cardIsleSelected = yield select(getCardIsleSelected);
        const activeABTests = yield select(getActiveABTest);
        const userSubmittedZip = yield select(getUserSubmittedProductFilterZipcode);
        if (
            orderItemToEdit && orderItemToEdit.cartItems
            && cardIsleSelected && cardIsleSelected.pickupCode
        ) {
            const [cartItems] = orderItemToEdit.cartItems;

            const { product } = cartItems;
            const brandCode = product.brandCode || '';
            const cartId = yield select(getOrderId);
            const zipCode = cartItems.zipCode || recipientFormData.zipCode || '';

            if (recipientFormData && zipCode && recipientFormData.locationType) {
                const jwtToken = yield call(checkJWT);

                const greetingPayload = {
                    env: {},
                    jwtToken,
                    cartItemId: orderItemId,
                    brandCode,
                    sku: cardIsleSelected.productCode, // card isle product code
                    greetingCardKey: cardIsleSelected.pickupCode,
                    greetingMessage: cardIsleSelected.textMessage || ' ',
                    greetingCardName: cardIsleSelected.name,
                    greetingCardImage: cardIsleSelected.img,
                    greetingCardType: 'CI',
                    greetingThumbNail: cardIsleSelected.img || '',
                    cartId,
                };

                // Loyalty
                let priceRules = null;
                let priceRulesResponse = {};
                const flags = yield select(getFeatureFlags);

                if (flags['is-loyalty-greeting-card-enabled'] && cardIsleSelected.productCode) {
                    const loyaltyStatus = yield select(getMemberLoyalty);
                    const passportUser = yield select(getIsPassportUserLoggedIn);

                    const payload = {
                        jwtToken,
                        products: [
                            {
                                partNumber: `${brandCode}-${cardIsleSelected.productCode}`,
                            },
                        ],
                        variants: [],
                    };

                    if (loyaltyStatus && passportUser) {
                        payload.variants.push(
                            {
                                name: 'LOYALTY_TYPE',
                                value: loyaltyStatus.toUpperCase(),
                            },
                            {
                                name: 'CUSTOMER_TYPE',
                                value: 'PASSPORT',
                            },
                        );
                    }

                    if (flags['is-zip-dynamic-pricing-enabled'] && activeABTests.zipDynamicPricing) {
                        payload.variants.push({
                            name: 'ZIP_CODE',
                            value: userSubmittedZip,
                        });
                    }

                    if (payload.variants.length > 0) {
                        priceRulesResponse = yield call(getPriceFromEngine, {}, jwtToken, payload, false);

                        if (priceRulesResponse?.data?.products?.[0]?.priceRules) {
                            priceRules = priceRulesResponse.data.products[0].priceRules;
                        }
                    }

                    if (priceRules?.length > 0) {
                        greetingPayload.priceRules = priceRules;
                    }
                }
                // Review the product has card isle available.
                yield call(cartServices.addGreetingCard, greetingPayload);
                yield put(addCardIsleComplete());

                // Tagging for save cardisle
                const taggingCardIsle = {
                    eventCategory: 'Checkout',
                    eventAction: 'Card Isle',
                    eventLabel: `${cardIsleSelected.name}|${cardIsleSelected.textMessage}`,
                };
                yield put(trackEvent(taggingCardIsle));

                if (flags['is-loyalty-greeting-card-enabled'] && greetingPayload.priceRules) {
                // Tagging for loyalty personalized card
                    yield put(trackEvent({
                        eventCategory: 'Checkout',
                        eventAction: 'Add to cart',
                        eventLabel: 'Personalized Card - Giftastic',
                    }));
                }
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'loadCardIsleAvailable',
            jsError: ex,
            message: 'Failed in getting cardIsle availability',
        });
        yield put(loadError({
            errType: 'cardIsle',
            errMsg: 'We are sorry, there seems to be a technical issue with cardIsle payment method, please try again later or use alternate payment method to complete order',
        }));

        yield fork(processFeatureErrors, {
            data: {
                orderItemId,
                errorFeatureType: 'giftMessage',
                errorValues: ex.response?.data,
            },
        });
    }
}

function* addCardIsleToCartWatcher() {
    let action = yield take(addCardIsle().type);
    while (action !== END) {
        yield fork(addCardIsleToCart, action.orderItemId, action.recipientFormData);
        action = yield take(addCardIsle().type);
    }
}

function* enterpriseAddCardIsleToCart(action) {
    const {
        orderItem, recipientAddress, cardIsleSelected, closeGreeting,
    } = action;

    const activeABTests = yield select(getActiveABTest);
    const userSubmittedZip = yield select(getUserSubmittedProductFilterZipcode);

    try {
        if (
            orderItem
            && orderItem.product
            && recipientAddress.locationType
            && cardIsleSelected.pickupCode
        ) {
            const { product } = orderItem;
            const brandCode = product.brandCode || '';
            const cartId = yield select(getOrderId);

            const jwtToken = yield call(checkJWT);

            const greetingPayload = {
                env: {},
                jwtToken,
                cartItemId: orderItem?.cartItemId || '',
                brandCode,
                sku: cardIsleSelected.productCode, // card isle product code
                greetingCardKey: cardIsleSelected.pickupCode,
                greetingMessage: cardIsleSelected.textMessage || ' ',
                greetingCardName: cardIsleSelected.name,
                greetingCardImage: cardIsleSelected.img || '',
                greetingCardType: 'CI',
                greetingThumbNail: cardIsleSelected.img || '',
                cartId,
            };

            // Loyalty
            let priceRules = null;
            let priceRulesResponse = {};
            const flags = yield select(getFeatureFlags);

            if (flags['is-loyalty-greeting-card-enabled'] && cardIsleSelected.productCode) {
                const loyaltyStatus = yield select(getMemberLoyalty);
                const passportUser = yield select(getIsPassportUserLoggedIn);

                const payload = {
                    jwtToken,
                    products: [
                        {
                            partNumber: `${brandCode}-${cardIsleSelected.productCode}`,
                        },
                    ],
                    variants: [],
                };

                if (loyaltyStatus && passportUser) {
                    payload.variants.push(
                        {
                            name: 'LOYALTY_TYPE',
                            value: loyaltyStatus.toUpperCase(),
                        },
                        {
                            name: 'CUSTOMER_TYPE',
                            value: 'PASSPORT',
                        },
                    );
                }

                if (flags['is-zip-dynamic-pricing-enabled'] && activeABTests.zipDynamicPricing) {
                    payload.variants.push({
                        name: 'ZIP_CODE',
                        value: userSubmittedZip,
                    });
                }

                if (payload.variants.length > 0) {
                    priceRulesResponse = yield call(getPriceFromEngine, {}, jwtToken, payload, false);
                    if (priceRulesResponse?.data?.products?.[0]?.priceRules) {
                        priceRules = priceRulesResponse.data.products[0].priceRules;
                    }
                }

                if (priceRules?.length > 0) {
                    greetingPayload.priceRules = priceRules;
                }
            }

            // Review the product has card isle available.
            yield call(cartServices.addGreetingCard, greetingPayload);
            yield put(addCardIsleComplete());

            yield call(closeGreeting, false);

            // Get Cart
            yield call(retrieveCart);

            // Tagging for save cardisle
            yield put(trackEvent({
                eventCategory: 'Checkout',
                eventAction: 'Card Isle',
                eventLabel: `${cardIsleSelected.name}|${cardIsleSelected.textMessage}`,
            }));

            if (flags['is-loyalty-greeting-card-enabled'] && greetingPayload.priceRules) {
                // Tagging for loyalty personalized card
                yield put(trackEvent({
                    eventCategory: 'Checkout',
                    eventAction: 'Add to cart',
                    eventLabel: 'Personalized Card - Giftastic',
                }));
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'CardIsle-Operation',
            function: 'loadCardIsleAvailable',
            jsError: ex,
            message: 'Failed in adding enterprise Card Isle to Cart',
        });

        yield fork(processFeatureErrors, {
            data: {
                orderItemId: action.orderItem.cartItemId,
                errorFeatureType: 'giftMessage',
                errorValues: ex.response?.data,
            },
        });

        yield put(loadError({
            errType: 'cardIsle',
            errMsg: 'We are sorry, there seems to be a technical issue with cardIsle payment method, please try again later or use alternate payment method to complete order',
        }));
    }
}

function* enterpriseAddCardIsleToCartWatcher() {
    let action = yield take(enterpriseAddCardIsle().type);
    while (action !== END) {
        yield fork(enterpriseAddCardIsleToCart, action);
        action = yield take(enterpriseAddCardIsle().type);
    }
}

function* workerAddCardIsleGiftCardToCart(action) {
    try {
        const jwtToken = yield call(checkJWT);
        yield put(addToCartActions.loadAddToCartloader(true));
        const greetingPayload = {
            env: {},
            jwtToken,
            ...action.payload,
        };

        const passportUser = yield select(getIsPassportUserLoggedIn);
        const passportItem = yield select(getPassportItem);
        const flags = yield select(getFeatureFlags);

        if ((passportUser || passportItem?.length > 0) && action.payload?.sku) {
            const activeABTests = yield select(getActiveABTest);
            const userSubmittedZip = yield select(getUserSubmittedProductFilterZipcode);

            // Loyalty
            let priceRules = null;
            let priceRulesResponse = {};
            const { sku, brandCode } = action.payload;

            const payload = {
                products: [
                    {
                        partNumber: `${brandCode}-${sku}`,
                    },
                ],
                variants: [],
            };

            payload.variants.push(
                {
                    name: 'CUSTOMER_TYPE',
                    value: 'PASSPORT',
                },
            );

            if (flags['is-zip-dynamic-pricing-enabled'] && activeABTests.zipDynamicPricing) {
                payload.variants.push({
                    name: 'ZIP_CODE',
                    value: userSubmittedZip,
                });
            }

            if (payload.variants.length > 0) {
                priceRulesResponse = yield call(getPriceFromEngine, {}, jwtToken, payload, false);
                if (priceRulesResponse?.data?.products?.[0]?.priceRules) {
                    priceRules = priceRulesResponse.data.products[0].priceRules;
                }
            }
            if (priceRules?.length > 0) {
                greetingPayload.priceRules = priceRules;
            }
        }

        // Review the product has card isle available.
        const responseCI = yield call(cartServices.addGreetingCardItems, greetingPayload);
        if (responseCI.data?.cartId) {
            const cartSize = responseCI.data.cartItems?.length || 0;

            if (cartSize === 1) {
                yield call(setOrderId, {
                    data: [responseCI.data?.cartId],
                });
            }
            if (responseCI.data?.cartId !== '' && action?.enableMiniCart && action?.handleMiniCartModalClick) {
                yield call(action.handleMiniCartModalClick, true);
            } else if (action.history) {
                const userProfile = yield select(getProfileInfo);
                const isAuth = yield select(getIsAuthenticatedStatus);
                let isRememberedUser = false;
                if (userProfile.email) {
                    isRememberedUser = true;
                }

                if (cartSize > 1) {
                    yield call(action.history.push, `/checkout/cart/${responseCI.data.cartId || ''}`);
                } else if (flags['is-native-interstitial-page-enabled'] && !isAuth && !isRememberedUser) {
                    yield put(triggerInterstitialLogin(action.history));
                } else {
                    yield call(action.history.push, `/checkout/shipping/${responseCI.data.cartId || ''}`);
                }
            }
            yield call(retrieveCart);
            yield put(addCardIsleGiftCardToCartComplete());
            yield put(addToCartActions.loadAddToCartloader(false));
        }
        // Tagging for save cardisle
        const taggingCardIsle = {
            eventCategory: 'Checkout',
            eventAction: 'Add to Cart',
            eventLabel: 'Cards Isle | Standalone',
        };
        yield put(trackEvent(taggingCardIsle));
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'addCardIsleGiftCardToCart',
            jsError: ex,
            message: 'Failed in getting cardIsle availability',
        });

        yield fork(processFeatureErrors, {
            data: {
                orderItemId: action.payload.greetingCardKey,
                errorFeatureType: 'giftMessage',
                errorValues: ex.response?.data,
            },
        });
        if (typeof window !== 'undefined') {
            // eslint-disable-next-line no-alert
            window.alert('We are sorry. Card Isle services are temporarily down. Please try again later.');
        }
        yield put(addToCartActions.loadAddToCartloader(false));
    }
}

function* addCardIsleGiftCardToCartWatcher() {
    let action = yield take(addCardIsleGiftCardToCart().type);
    while (action !== END) {
        yield fork(workerAddCardIsleGiftCardToCart, action);
        action = yield take(addCardIsleGiftCardToCart().type);
    }
}
const watchers = [
    fork(loadCardIsleAvailableWatcher),
    fork(addCardIsleToCartWatcher),
    fork(enterpriseAddCardIsleToCartWatcher),
    fork(addCardIsleGiftCardToCartWatcher),
];

export {
    watchers,
    loadCardIsleAvailable,
    addCardIsleToCart,
};

export default {};
