/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpLogger from 'mbp-logger';

const checkWhiteList = (whiteListFlag = {}) => {
    let whiteListConfirmed = false;
    const safeJsonParse = (str) => {
        if (typeof str !== 'string') return {};
        try {
            return JSON.parse(str);
        } catch {
            return {};
        }
    };
    try {
        const persistMember = sessionStorage?.getItem('persist:member');
        const parsedPersistMember = safeJsonParse(persistMember);
        const profile = safeJsonParse(parsedPersistMember?.profile);
        const userEmail = profile?.email || '';
        const userUUID = localStorage?.getItem('browserUUID') || '';

        if (userEmail) {
            if (whiteListFlag?.emails && whiteListFlag?.emails?.length) {
                whiteListFlag.emails.forEach((item) => {
                    if (item === userEmail) {
                        whiteListConfirmed = true;
                    }
                });
            }
        }
        if (userUUID) {
            if (whiteListFlag?.uuids && whiteListFlag?.uuids?.length) {
                whiteListFlag?.uuids?.forEach((item) => {
                    if (item === userUUID) {
                        whiteListConfirmed = true;
                    }
                });
            }
        }
    } catch (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            errorMessage: error,
            message: `Conversational UI WhiteList - ERROR: ${error}`,
        });
    }
    return whiteListConfirmed;
};
export default checkWhiteList;
