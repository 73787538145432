/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const ABCChatLinksByBrandProd = {
    '18F': 'https://bcrw.apple.com/urn:biz:a80a7352-e042-11e7-af38-7b88b04daa8e',
    '18B': 'https://bcrw.apple.com/urn:biz:df8107e5-b9a6-4e68-b329-e3e5b1247e21',
    HD: 'https://bcrw.apple.com/urn:biz:9ab55a12-ccb6-458d-8c6a-758cbf5ec242',
    CCO: 'https://bcrw.apple.com/urn:biz:f45ba478-770d-4a0a-b07f-0150d513a83b',
    TPF: 'https://bcrw.apple.com/urn:biz:2f592990-00e3-4d90-95d4-e6166978019e',
    SCH: 'https://bcrw.apple.com/urn:biz:f2e39503-9a29-4785-addb-bb89f0733e13',
    WLF: 'https://bcrw.apple.com/urn:biz:0a995446-c8f7-4206-83e5-da936feed683',
    FB: 'https://bcrw.apple.com/urn:biz:4a741121-9782-4688-8d64-e6c485394b26',
    FBQ: 'https://bcrw.apple.com/urn:biz:4a741121-9782-4688-8d64-e6c485394b26',
    BRY: 'https://bcrw.apple.com/urn:biz:5008980e-55ab-4892-8de5-1b2de545bcfe',
    VC: 'https://bcrw.apple.com/urn:biz:a8b8309f-5b4e-47b6-a2f8-b5ba8fb33c6c',
};
export const ABCChatLinksByBrandUAT = {
    '18F': 'https://bcrw.apple.com/urn:biz:4daebe42-0ff2-11e8-b2b3-b75ac98cf939',
    '18B': 'https://bcrw.apple.com/urn:biz:9503595f-51c9-4c9a-a9cb-230d4327bbaa',
    HD: 'https://bcrw.apple.com/urn:biz:380c6b92-2122-4a36-bee0-082e6683ef5b',
    CCO: 'https://bcrw.apple.com/urn:biz:d60843f4-33f5-4dad-8bb9-a571019edbc9',
    TPF: 'https://bcrw.apple.com/urn:biz:537b3871-4fbf-494f-80a8-90f08acb8333',
    SCH: 'https://bcrw.apple.com/urn:biz:bbaab2a4-8e5a-4504-b19f-3e3319b6d8d2',
    WLF: 'https://bcrw.apple.com/urn:biz:1be23b41-8134-4b39-9f12-4639d0bf5298',
    FB: 'https://bcrw.apple.com/urn:biz:1197a7b2-1ffc-48ff-91f2-ef1ec30b6493',
    FBQ: 'https://bcrw.apple.com/urn:biz:1197a7b2-1ffc-48ff-91f2-ef1ec30b6493',
    BRY: 'https://bcrw.apple.com/urn:biz:4b9269c9-ea11-4ef2-a119-284fd3e626a9',
    VC: 'https://bcrw.apple.com/urn:biz:d1f217ff-9498-446e-83b7-86e683becfc5',
};

export const determineChatlink = (operatingSystem, currentBrand, featureFlags, customerData) => {
    const isAndroid = operatingSystem === 'android';
    const isIOS = operatingSystem === 'ios';

    const isMobileChatEnabled = (isAndroid && featureFlags['is-gbm-in-pwa-enabled'])
        || (isIOS && featureFlags['is-abc-in-pwa-enabled']);

    if (!isMobileChatEnabled) return '';

    if (isAndroid) {
        const isFloralBrand = featureFlags['is-gbm-floral-brands-enabled']
            && ['18B', 'SCH', 'BRY', 'FB', 'FBQ'].includes(currentBrand);
        return isFloralBrand ? '' : '';
    }

    if (isIOS) {
        const envLink = process.env.NODE_ENV === 'production'
            ? ABCChatLinksByBrandProd[currentBrand]
            : ABCChatLinksByBrandUAT[currentBrand];

        if (customerData?.profile) {
            const {
                contactId = '', email, firstName = '', lastName = '',
            } = customerData.profile;
            return `${envLink}?biz-group-id=contactId=${contactId}|email=${email}|firstName=${firstName}|lastName=${lastName}|`;
        }

        return envLink;
    }

    return '';
};
