/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// App Selectors
export const getAppShellLoadedState = (state) => state.app.shell.loaded;
export const getPageFullyLoadedState = (state) => state.ui.pageFullyLoaded;

// SSR selectors
export const getIsBot = (state) => state.app.ssr.config?.isBot;
export const getSSRHostname = (state) => state.app.ssr.config.hostname;
export const getSSRPath = (state) => state.app.ssr.config.path;
export const getSSRSearch = (state) => state.app.ssr.config.search;
export const getSSRLoadingState = (state) => state.app.ssr.config.loaded;
export const getSSRDeviceType = (state) => state.app.ssr?.config?.deviceType;
export const getSSRConfig = (state) => state.app.ssr.config;

// Member Selectors
export const getCurrentUser = (state) => state.member.auth;
export const getCustomerData = (state) => state.member;

// UI Selectors
export const getSuggestionData = (state) => state.ui.suggestData;
export const getPassportBundleItemCurrentlyInCart = (state) => state?.ui?.appContext?.passportBundleProductInCart?.passportBundleItemCurrentlyInCart;
export const country = (state) => state?.ui?.country;
export const getEmailSubscriptionStatus = (state) => state?.ui?.emailSubscription || {};
export const getChatBotOpenState = (state) => state?.ui?.appContext?.chatBotOpen;
export const getPageType = (state) => state.tagManager?.pageView?.pageType || '';
export const getDonationsCategory = (state) => state?.ui?.donationsCategory;

// CGC Card
export const getCGCCardData = (state) => state.ui?.appContext?.cgcCardData || [];
export const getCGCCardError = (state) => state.ui?.appContext?.cgcCardError || '';

// GuidedNav FindAGiftFast selectors
export const getValidatedZipcode = (state) => state.ui.appContext.validatedZipcode;
export const getIsProductFilterZipcodeValid = (state) => state.ui.appContext.isProductFilterZipcodeValid;
export const getUserSubmittedProductFilterZipcode = (state) => state.ui.appContext.userSubmittedProductFilterZipcode;
export const getLocationType = (state) => state.ui.appContext.locationType;
export const getProductFilterOccasion = (state) => state.ui.appContext.occasion;
export const getProductFilterProfile = (state) => state.ui.appContext.filterProfile;
// export const getCategoryLockStatus = state => state.ui.category.locked; // not in use

// Address Prefill Selectors
export const getSavedAddress = (state) => state.ui?.appContext?.userSubmittedAddress || null;
export const getAutoFillAddress = (state) => state.ui?.appContext?.autoFillAddress || '';
export const getAddressSource = (state) => state.ui?.appContext?.userSubmittedAddress?.addressSource || null;

// Banner Code Select || nullor
export const getBannerCode = (state) => state.ui?.appContext?.bannerCode || null;

// wine verification for PDP
export const getWineValidation = (state) => state.ui?.pdpCart?.wineData || null;

// submit wine data for PDP
export const getWineData = (state) => state.ui?.appContext?.wineData || null;

// wine verification for PDP age verified
export const getAgeVerificationFlag = (state) => state.ui?.appContext?.isAgeVerified || null;

// NBCU movie skus
export const getBrandMovieSkus = (state) => state.ui?.appContext?.brandMovieSkus;

export const getGuidedNavModalState = (state) => state.ui?.appContext?.guidedNavModalHasBeenLoadedInSession;
export const getValidzipcodeSubmittedOnce = (state) => state.ui?.appContext?.validZipcodeSubmittedOnce;
export const getGuidedNavLockedProductClicked = (state) => state.ui?.appContext?.guidedNavLockedProductClicked;

// Add to cart position in PDP
export const getAddToCartPosition = (state) => state.ui?.appContext?.addToCartPosition || null;

// get mini cart upsell click state
export const getMiniCartUpsellState = (state) => state.ui?.appContext?.miniCartUpsell || false;

export const getMiniCartModalState = (state) => state.ui?.appContext?.isMiniCartModalOpen || false;
export const getCartSliderOpened = (state) => state.ui?.hasCartSliderOpened || 'close';
// Config
export const getConfigOption = (state, entry) => {
    if (entry !== '') {
        return state.app.config[entry];
    }

    return null;
};

// GCI
export const getGCIData = (state) => state?.ui?.currentProduct?.gciData || {};
export const getGCIPersonalizationKey = (state) => state?.ui?.currentProduct?.gciKey || '';
export const getCustomCardData = (state) => state?.ui?.appContext?.cgcCustomCardData || {};

// iGo Salesforce
export const getIGoProducts = (state) => state?.igo?.products || [];

// Retail price
export const getProductRetailPrice = (state) => state.ui?.currentProduct?.retailPrice || '';

// Subscription Modal Popup user confirmation flag
export const getUserConfirmationForSubscriptionPopup = (state) => state.ui?.currentProduct?.userConfirmationForSubscriptionPopup || false;

export const getScrollPosition = (state) => state.ui?.appContext?.scrollPosition || {};

export const getTwillioMessageChatBot = (state) => state?.ui?.currentProduct?.twillioChatBotMessage || false;

// get subscription Frequency data pdp page
export const getSubscriptionFrequencyData = (state) => state.ui?.subscriptionDetail || {};

// get sideBar View Mini Cart
export const getSideBarViewCart = (state) => state.ui?.sideMiniCart || false;

// Passport holdout group status
export const getPassportHoldoutStatus = (state) => state.ui?.isInPassportHoldout || false;

// Check Current User PassportHoldoutGroup Tracking Info
export const getPassportHoldoutTrackingInfo = (state) => state.ui?.passportHoldoutGroupTracking || {};

// Get IOS(ABC) chat link
export const getAbcChatLink = (state) => state.ui.appContext.abcChatLink || '';

export const getGuidedNavProductUnlock = (state) => state.ui?.appContext?.guidedNavProductUnlock || false;

export const getWineAgeVerified = (state) => state.ui?.appContext?.wineAgeValidation || false;

export const getProductUnAvailable = (state) => state.ui?.appContext?.productUnAvailable || false;

export const getUserState = (state) => state.ui?.appContext?.validatedStateByZipcode || '';

export const getSelectedInlineAddons = (state) => state.ui?.currentProduct?.selectedInlineAddons || [];

export const getLoginModuleVisibility = (state) => state?.ui?.loginModule?.showBanner || false;

export const getSelectedBYOItems = (state) => state.ui?.currentProduct?.selectedBYOItem || {};

export const getIsEmailCaptureStatus = (state) => state.ui?.appContext?.isEmailCaptured || false;

export const getIsHoldoutPersonalizationStatus = (state) => state.ui?.appContext?.isHoldoutPersonalizationEnabled || false;

export const getEmailOptInSFResponse = (state) => state.ui?.emailOptInSalesforceResponse || {};

export const getEmailOptInRefetchTimestamp = (state) => state.ui?.emailOptInAPIRefetchTimestamp || 0;

// Dialog
export const getDialogOpen = (state) => state.ui?.dialog?.isOpen;

export const getDialogType = (state) => state.ui?.dialog?.type;

export const getOfferGatewayData = (state) => state.ui?.dialog?.offerGateway;

export const getWrapUpProductRefTitle = (state) => state.ui?.wrapUpProductRefTitle || 'Default';

export default {};
