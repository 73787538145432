/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import mbpUtil from 'mbp-api-util';
import mbpLogger from 'mbp-logger';
import getAuth0Config from './auth-config';
import {
    auth0,
    getAccessTokenSafely,
} from './auth';
import { getGuestUserAccessToken, setGuestEmailIdInLocalStorage } from './guest';
import {
    customCallbackURL,
    getAccessTokenInSession,
    getTokenLocalStorage,
    setPageTitle,
    getGdprQuery,
} from './helpers';
import { usePopUp } from './usePopUp';

import {
    getBrand,
    getLastVisted,
    getOrderId,
    getProfileInfo,
} from '../../../Common/Common-Selectors';

import { setProfileEmailId } from '../../../Profile/Profile-Actions';
import {
    logUserLoggedOut,
    logGuestSession,
    logUserLoggedInSuccess,
    logUserLoggedInFailed,
    logHydraidUserSession,
    addRouteChanges,
} from '../../Auth-Actions';
import { MeasureTimeTaken } from '../../helper/helper';
import { getFeatureFlag } from '../../../../../App/ducks/Config/Config-Selectors';
import cartService from '../../../../../../../apis/cart-service-apis/index';
import { emitCustomerIdentificationEvent, emitCustomTrackingEvent } from '../../../../../TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { subscribeEmailApi } from '../../../../../../../apis/account-apis/subscribeEmailApi';
import { setEmailSubscriptionData, setOfferGatewayData } from '../../../../../App/App-Actions';
import { getOfferGatewayData, getSSRDeviceType } from '../../../../../App/App-Selectors';
import useBrowserUUID from '../../../../../../../app/helpers/useBrowserUUID';
import { getEnterpriseId } from '../../../EnterpriseId/EnterpriseId-Selectors';

function useAuthProvider() {
    const brandsEmailOpt = {
        FB: '18B',
        FBQ: '18B',
        HD: 'HND',
        WF: 'WLF',
        SY: 'STK',
        STY: 'STK',
        BRY: 'BERRIES',
        VC: 'VTC',
    };
    const popUpParamsRef = useRef();
    const brandDetails = useSelector(getBrand);
    const ffAuth0AuthorizeTimeoutInSeconds = useSelector((state) => getFeatureFlag('auth0-authorize-timeout-in-seconds')(state));
    const ffLogTimeTakenToCheckSessionAuth0 = useSelector((state) => getFeatureFlag('is-log-timetaken-to-check-session-auth0')(state));
    const isNativeLoginEnabled = useSelector((state) => getFeatureFlag('is-native-login-enabled')(state));
    const lastVisited = useSelector(getLastVisted);
    const orderId = useSelector(getOrderId);
    const userProfile = useSelector(getProfileInfo);
    const offerGatewayData = useSelector(getOfferGatewayData);
    const browserUUID = useBrowserUUID() || '';
    const enterpriseId = useSelector(getEnterpriseId);
    const deviceType = useSelector(getSSRDeviceType);
    const [authReady, setAuthReady] = useState(false);

    const authClientId = brandDetails?.auth_client_id;
    const brandCode = brandDetails?.code;
    const isCSR = (typeof window !== 'undefined');
    const auth0Config = getAuth0Config(brandDetails?.domain);
    const history = useHistory();
    const dispatchAction = useDispatch();

    const {
        openPopup,
        handleClosePopUp,
        handlePopUpFocus,
        showOverlay,
        setShowOverlay,
    } = usePopUp();

    const logout = async () => {
        dispatchAction(logUserLoggedOut());

        auth0.logout();
    };

    const handleGuestSession = async () => {
        const accessTokenInSession = getAccessTokenInSession();
        const guestAccessToken = await getGuestUserAccessToken();

        let userInfo = getTokenLocalStorage('userInfo') || null;
        userInfo = JSON.parse(userInfo);

        if (accessTokenInSession !== guestAccessToken) {
            dispatchAction(logGuestSession({
                user: userInfo?.profile || {},
                isAuthenticated: false,
                accessToken: guestAccessToken,
            }));
        } else {
            dispatchAction(logHydraidUserSession({
                user: userInfo?.profile || {},
                isAuthenticated: false,
                accessToken: guestAccessToken,
            }));
        }
    };

    const mergeCustomerCart = async (signedInAccessToken) => {
        const accessTokenInSession = getAccessTokenInSession();

        const status = {
            success: false,
            skip: false,
        };

        try {
            const jwtTest = mbpUtil.testJWT(accessTokenInSession);

            if (jwtTest.isValid && jwtTest.isGuest && !jwtTest.isExpired) {
                await cartService.mergeCart({ registeredJWTToken: signedInAccessToken, guestToken: accessTokenInSession });
                status.success = true;
            } else if (jwtTest.isValid && !jwtTest.isGuest) {
                status.skip = true;
            }
        } catch (ex) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                fileName: 'auth.js',
                function: 'mergeCustomerCart',
                jsError: ex,
                message: 'failed to merge customer cart',
            });
        }

        return status;
    };

    const triggerOfferGatewayEvents = (isOptIn, email) => {
        const salesforceResponse = offerGatewayData.salesforceResponse;
        if (isOptIn === 'true') {
            dispatchAction(emitCustomerIdentificationEvent({
                customEvent: {
                    action: salesforceResponse?.campaign?.campaignResponses?.[0]?.payload?.eventDefinitionKey,
                },
                page: 'home',
                salesforceResponse,
                eventType: 'clickstream.customer-identification',
                eventSubType: 'journey-email-trigger',
                user: {
                    email,
                },
            }));

            dispatchAction(setOfferGatewayData({
                offerGatewayOptin: true,
            }));
        } else {
            dispatchAction(emitCustomTrackingEvent({
                action: 'Dismissal',
                eventSubType: 'campaign-tracking',
                page: 'home',
                salesforceResponse,
            }));
        }
    };

    const handlePostLogin = async (signedInAccessToken, signInMethod) => {
        let status = false;

        const authClient = auth0.getInstance();
        const cartMerged = await mergeCustomerCart(signedInAccessToken);

        if (cartMerged.success || cartMerged.skip) {
            const userInfo = await auth0.getUserInfo();

            const isAuthenticated = await authClient.isAuthenticated();

            dispatchAction(logUserLoggedInSuccess({
                user: userInfo,
                isAuthenticated,
                accessToken: signedInAccessToken,
                signInMethod,
            }));

            if (offerGatewayData?.salesforceResponse && Object.keys(offerGatewayData?.salesforceResponse).length > 0 && userInfo.offerGatewayOptin) triggerOfferGatewayEvents(userInfo.offerGatewayOptin, userInfo.email);

            status = true;
        } else {
            dispatchAction(logUserLoggedInFailed());

            logout();
        }

        return status;
    };

    const loginByRedirect = async (options = {}, params = {}) => {
        try {
            if (Object.keys(options).length === 0 && !('connection' in options) && !('login_hint' in options) && !('ext-offer-gateway-flow' in options) && isNativeLoginEnabled) {
                history.push('/sign-in');
            } else {
                const authClient = auth0.getInstance();

                const auth0Options = options;
                const appState = {};

                const callbackURL = customCallbackURL(auth0Config.redirectURL, {
                    source: auth0Config.appSource,
                    brandName: brandCode,
                    ...params,
                });

                auth0Options['ext-gdpr-value'] = getGdprQuery()?.gdprValue;
                auth0Options['ext-brand'] = brandCode;
                auth0Options['ext-last-visited-page'] = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
                if (browserUUID) auth0Options['ext-browser-uuid'] = browserUUID;
                if (enterpriseId) auth0Options['ext-enterprise-id'] = enterpriseId;
                if (deviceType) auth0Options['ext-device-type'] = deviceType;

                if (params?.routeBack) {
                    appState.routeBack = params.routeBack;
                } else {
                    appState.routeBack = lastVisited;
                }

                if (params?.register === 'Y') auth0Options['screen_hint'] = 'signup';

                if (authClient) {
                    await authClient.loginWithRedirect({
                        appState,
                        authorizationParams: {
                            audience: auth0Config.audience,
                            callbackURL,
                            ...auth0Options,
                        },
                    });
                }
            }
        } catch (ex) {
            console.log('ex', ex);
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                fileName: 'auth0-provider.js',
                function: 'loginByRedirect',
                jsError: ex,
                message: 'failed to call login by redirect',
            });
        }
    };

    const loginByAuth0PopUp = async (options = {}, params = {}) => {
        let popUpAuthStatus = false;

        popUpParamsRef.current = {
            options,
            params,
        };

        try {
            const authClient = auth0.getInstance();

            const auth0Options = options;

            const callbackURL = customCallbackURL(auth0Config.redirectURL, {
                source: auth0Config.appSource,
                brandName: brandCode,
                ...params,
            });

            auth0Options['ext-gdpr-value'] = getGdprQuery()?.gdprValue;
            auth0Options['ext-brand'] = brandCode;
            auth0Options['ext-last-visited-page'] = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

            if (params?.register === 'Y') auth0Options['screen_hint'] = 'signup';

            setShowOverlay(true);

            await authClient.loginWithPopup({
                authorizationParams: {
                    audience: auth0Config.audience,
                    callbackURL,
                    ...auth0Options,
                },
            }, {
                popup: openPopup(),
                timeoutInSeconds: 300000,
            });

            handleClosePopUp();

            const accessToken = await authClient.getTokenSilently({
                authorizationParams: {
                    audience: auth0Config.audience,
                },
            });

            await handlePostLogin(accessToken, 'popup');

            setShowOverlay(false);

            popUpAuthStatus = true;
        } catch (ex) {
            console.log('ex', ex);
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                fileName: 'auth0-provider.js',
                function: 'loginByAuth0PopUp',
                jsError: ex,
                message: 'failed to login by auth0 popup',
            });

            handleClosePopUp();

            setShowOverlay(false);

            dispatchAction(logUserLoggedInFailed());
        }

        return popUpAuthStatus;
    };

    const handleAuthCallBack = async () => {
        try {
            setPageTitle('Signing in');

            const authClient = auth0.getInstance();

            const response = await authClient.handleRedirectCallback();

            const accessToken = await authClient.getTokenSilently({
                authorizationParams: {
                    audience: auth0Config.audience,
                },
            });

            const isPostLoginCompleted = await handlePostLogin(accessToken, 'redirect');

            if (isPostLoginCompleted) {
                if (response?.appState?.routeBack) {
                    history.replace(response?.appState?.routeBack);
                } else {
                    history.replace(lastVisited);
                }
            } else {
                history.replace('/');
            }

            setPageTitle('');
        } catch (ex) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                fileName: 'auth0-provider.js',
                function: 'handleAuthCallBack',
                jsError: ex,
                message: 'failed to login by auth0 redirect',
            });

            dispatchAction(logUserLoggedInFailed());

            logout();
        }
    };

    const handleAuthPasswordlessCallBack = async (isoffergateway) => {
        try {
            setPageTitle('Signing in');

            const authClient = auth0.getInstance();
            const authorizationParams = {
                audience: auth0Config.audience,
            };

            if (isoffergateway && isoffergateway === 'yes') authorizationParams['ext-offer-gateway-flow'] = 'yes';

            const accessToken = await authClient.getTokenSilently({
                authorizationParams,
            });

            const isPostLoginCompleted = await handlePostLogin(accessToken, 'redirect');

            if (isPostLoginCompleted) {
                history.replace(lastVisited);
            } else {
                history.replace('/');
            }

            setPageTitle('');
        } catch (ex) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                fileName: 'auth0-provider.js',
                function: 'handleAuthPasswordlessCallBack',
                jsError: ex,
                message: 'failed to login by auth0 redirect',
            });

            dispatchAction(logUserLoggedInFailed());

            logout();
        }
    };

    const subscribeUser = async (email) => {
        let brandId = brandCode;
        if (Object.keys(brandsEmailOpt).includes(brandId)) {
            brandId = brandsEmailOpt[brandId];
        }
        const jwtToken = await getAccessTokenSafely();
        // subscribe to marketing email api
        subscribeEmailApi({
            email, brandCode: brandId, token: jwtToken, action: 'SUBSCRIBE',
        }).then((apiResponse) => {
            const { error } = apiResponse?.data;
            if (error?.message) {
                return;
            }
            setEmailSubscriptionData(apiResponse?.data);
        }).catch((error) => {
            mbpLogger.logError({
                function: 'Auth - subscribeEmailApi',
                message: 'Error loading data from subscribe Email API',
                appName: process.env.npm_package_name,
                module: 'Email Opt In',
                jsError: error,
            });
        });
    };

    const handleGuestCallBack = async () => {
        setPageTitle('Signing in as guest');

        const queryString = qs.parse(history.location.search, { ignoreQueryPrefix: true });
        const { guestEmailId, emailOptIn } = queryString;

        if (!userProfile.email) {
            let routeBack = `/checkout/shipping/${orderId}`;

            if (lastVisited.includes('/checkout/payment')) {
                routeBack = lastVisited;
            }

            if (guestEmailId && emailOptIn && emailOptIn === 'true') subscribeUser(guestEmailId);
            dispatchAction(setProfileEmailId(guestEmailId));
            dispatchAction(emitCustomerIdentificationEvent({
                eventSubType: 'guest-checkout',
                user: { email: guestEmailId },
                page: { type: 'checkout', title: 'checkout' },
            }));

            await setGuestEmailIdInLocalStorage(guestEmailId);

            history.replace(routeBack);
        } else if (lastVisited.includes('/checkout/cart') && userProfile.email) {
            history.replace('/');
        } else {
            history.replace(`/checkout/cart/${orderId}`);
        }

        setPageTitle('');
    };

    const handleSamlCallback = async () => {
        const currentURL = new URL(window.location.href);
        const conn = currentURL.searchParams.get('connection');
        const routeBack = currentURL.searchParams.get('routeBack');
        try {
            const authClient = auth0.getInstance();

            if (!authClient) throw Error('Auth instance missing');

            await authClient.loginWithRedirect({
                authorizationParams: {
                    connection: conn,
                    redirect_uri: `${window.location.origin}/auth/callback`,
                    authorizeTimeoutInSeconds: 90,
                },
                appState: { routeBack: routeBack || '/' },
            });
        } catch (ex) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                fileName: 'auth0-provider.js',
                function: 'handleSamlCallback',
                jsError: ex,
                message: 'failed to login with redirect from IdP-Initiated callback in handleSamlCallback function',
            });
        }
    };

    const checkAuth0SessionExist = async (authClient) => {
        const timeKeeper = MeasureTimeTaken();

        try {
            timeKeeper.startClock();

            await authClient.getTokenSilently({
                authorizationParams: {
                    audience: auth0Config.audience,
                },
            });

            const { timeTaken } = timeKeeper.endClock();

            if (ffLogTimeTakenToCheckSessionAuth0) {
                mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    module: 'mbp-pwa-ui',
                    fileName: 'auth0-provider.js',
                    function: 'checkAuth0SessionExist',
                    message: `silent auth time taken ${timeTaken}ms`,
                });
            }
        } catch (ex) {
            const { timeTaken } = timeKeeper.endClock();

            if (ffLogTimeTakenToCheckSessionAuth0) {
                mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    module: 'mbp-pwa-ui',
                    fileName: 'auth0-provider.js',
                    function: 'checkAuth0SessionExist',
                    message: `silent auth time taken ${timeTaken}ms`,
                });
            }

            throw ex;
        }
    };

    const checkSession = async () => {
        try {
            const accessTokenInSession = getAccessTokenInSession();
            const authClient = auth0.getInstance();

            const registeredUserAccessToken = await checkAuth0SessionExist(authClient);

            const isAuthenticated = await authClient.isAuthenticated();

            if (isAuthenticated) {
                const user = await auth0.getUserInfo();

                // auto sign-in
                if (accessTokenInSession !== registeredUserAccessToken) {
                    dispatchAction(logUserLoggedInSuccess({
                        user,
                        isAuthenticated,
                        accessToken: registeredUserAccessToken,
                        signInMethod: 'auto',
                    }));
                } else {
                    dispatchAction(logHydraidUserSession({
                        user,
                        isAuthenticated,
                        accessToken: registeredUserAccessToken,
                    }));
                }
            } else {
                await handleGuestSession();
            }
        } catch (ex) {
            await handleGuestSession();
        }
    };

    const trackRouteChanges = (path, searchParams) => {
        dispatchAction(addRouteChanges(path, searchParams));

        if (path.indexOf('/auth/login') >= 0) {
            if (isNativeLoginEnabled) history.replace('/sign-in');
            else loginByRedirect();
        } else if (path.indexOf('/auth/create') >= 0) {
            const queryString = qs.parse(history.location.search, { ignoreQueryPrefix: true });
            const { userRef, fnKey, lnKey } = queryString;
            // eslint-disable-next-line no-useless-escape
            const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const namePattern = /^[A-Za-z][A-Za-z0-9.' -]*$/;
            const options = {};
            if (userRef && userRef.match(emailPattern)) {
                options['login_hint'] = userRef;
                if (fnKey && fnKey.match(namePattern)) options['ext-first-name'] = fnKey;
                if (lnKey && lnKey.match(namePattern)) options['ext-last-name'] = lnKey;
                loginByRedirect(options, { register: 'Y' });
            } else if (isNativeLoginEnabled) history.replace('/sign-in');
            else loginByRedirect(options, { register: 'Y' });
        }
    };

    const listenToRouteChanges = () => {
        history.listen((location) => {
            trackRouteChanges(`${location.pathname}`, `${location?.search}`);
        });

        trackRouteChanges(`${history.location.pathname}`, `${history?.location?.search}`);
    };

    async function initAuthClient() {
        // TODO what happens if this fails?
        await auth0.createClient(authClientId, ffAuth0AuthorizeTimeoutInSeconds);

        const pathName = history.location.pathname;
        const queryString = qs.parse(history.location.search, { ignoreQueryPrefix: true });
        const { mode, isoffergateway } = queryString;

        if (pathName.indexOf('/auth/callbackguest') >= 0) {
            handleGuestCallBack();
        } else if (pathName.indexOf('/auth/callback') >= 0 && mode && mode === 'passwordless') {
            handleAuthPasswordlessCallBack(isoffergateway);
        } else if (pathName.indexOf('/auth/callback') >= 0) {
            handleAuthCallBack();
        } else if (pathName.indexOf('/auth/saml/callback') >= 0) {
            handleSamlCallback();
        } else if (pathName.indexOf('/auth/login') >= 0) {
            if (isNativeLoginEnabled) history.replace('/sign-in');
            else loginByRedirect();
        } else if (pathName.indexOf('/auth/create') >= 0) {
            const { userRef, fnKey, lnKey } = queryString;
            // eslint-disable-next-line no-useless-escape
            const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const namePattern = /^[A-Za-z][A-Za-z0-9.' -]*$/;
            const options = {};
            if (userRef && userRef.match(emailPattern)) {
                options['login_hint'] = userRef;
                if (fnKey && fnKey.match(namePattern)) options['ext-first-name'] = fnKey;
                if (lnKey && lnKey.match(namePattern)) options['ext-last-name'] = lnKey;
                loginByRedirect(options, { register: 'Y' });
            } else if (isNativeLoginEnabled) history.replace('/sign-in');
            else loginByRedirect(options, { register: 'Y' });
        } else if (pathName.indexOf('/auth/logout') < 0) {
            await checkSession();
        }
        setAuthReady(true);
        auth0.setAuthReady();
    }

    useEffect(() => {
        if (authClientId && !auth0.isReady && isCSR && ffAuth0AuthorizeTimeoutInSeconds) {
            initAuthClient();
            listenToRouteChanges();
        }
    }, [isCSR, auth0.isReady, authClientId, ffAuth0AuthorizeTimeoutInSeconds]);

    return {
        showOverlay,
        logout,
        popUpParamsRef,
        handlePopUpFocus,
        loginByRedirect,
        loginByAuth0PopUp,
        getAccessTokenSafely,
        authReady,
        subscribeUser,
    };
}

export default useAuthProvider;
