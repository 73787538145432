/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import createState from 'redux-create-state';

import * as actions from './PaymentForm-Actions';
import initialState from '../../../../initialState';

const paymentFormReducer = (state = initialState.payment.paymentForm, action) => {
    switch (action.type) {
        case actions.paymentPrefillFormfields().type: {
            const formValue = action.data;
            const finaldata = {
                ...state,
                accountNumber: formValue.accountNumber,
                brand: formValue.brand,
                lastFourDigits: formValue.lastFourDigits,
                cvv: formValue.cvv,
                expirationMonth: formValue.expirationMonth,
                expirationYear: formValue.expirationYear,
                nameOnCreditCard: formValue.nameOnCreditCard,
                isFromWallet: !!formValue.isFromWallet,
                savePaymentMethod: !!formValue.savePaymentMethod,
                walletId: formValue.walletId,
            };
            return finaldata;
        }
        case actions.paymentUpdateFormValues().type: {
            const formEvent = action.data;
            switch (formEvent.target.name) {
                case 'accountNumber':
                    return { ...state, accountNumber: formEvent.target.value };
                case 'amount':
                    return { ...state, amount: formEvent.target.value };
                case 'cvv':
                    return { ...state, cvv: formEvent.target.value };
                case 'brand':
                    return { ...state, brand: formEvent.target.value };
                case 'expirationMonth':
                    return { ...state, expirationMonth: formEvent.target.value };
                case 'expirationYear':
                    return { ...state, expirationYear: formEvent.target.value };
                case 'nameOnCreditCard':
                    return { ...state, nameOnCreditCard: formEvent.target.value };
                case 'email':
                    return { ...state, email: formEvent.target.value };
                case 'savePaymentMethod':
                    return { ...state, savePaymentMethod: formEvent.target.value };
                case 'walletId':
                    return { ...state, walletId: formEvent.target.value };
                default:
                    return state;
            }
        }
        case actions.paymentClearUpdateFields().type:
            return {
                ...state,
                accountNumber: '',
                brand: '',
                lastFourDigits: '',
                amount: '',
                cvv: '',
                expirationMonth: '',
                expirationYear: '',
                nameOnCreditCard: '',
                isFormValid: false,
                isFromWallet: false,
                savePaymentMethod: false,
                wallet: '',
            };
        case actions.paymentGetFileds().type:
            return state;
        case actions.paymentUpdateFormStatus().type:
            return { ...state, isFormValid: action.data };
        case actions.setPaymentMethod().type:
            return createState(state || initialState.payment.paymentForm.paymentMethod, ['paymentMethod', action.payload]);
        default:
            return state;
    }
};

export default paymentFormReducer;
