/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const UPDATE_FIELD_PROMOTION_FORM = 'mbp-checkout/UPDATE_FIELD_PROMOTION_FORM';
export const CLEAR_ALL_FIELD_PROMOTION_FORM = 'mbp-checkout/CLEAR_ALL_FIELD_PROMOTION_FORM';
export const UPDATE_PROMO_AND_MEMBERID = 'mbp-checkout/CUPDATE_PROMO_AND_MEMBERID';
export const SET_BANNER_CODES_ATTEMPTS = 'mbp-checkout/SET_BANNER_CODES_ATTEMPTS';
export const SET_PROMOTION_LOAD = 'MFE/CHECKOUT/SET_PROMOTION_LOAD';
export const PROMOTION_REDIRECT_TO_AFFILIATE = 'MFE/CHECKOUT/PROMOTION_REDIRECT_TO_AFFILIATE';
export const CLEAR_PROMO_AFFILIATE = 'MFE/CHECKOUT/CLEAR_PROMO_AFFILIATE';
