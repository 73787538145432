/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { string } from 'prop-types';
import mbpUtil from 'mbp-api-util';
import mbpLogger from 'mbp-logger';

const ConversationalUISearch = ({
    deviceType,
}) => {
    const history = useHistory();
    const iframeRef = useRef(null);
    const [isIframeLoaded, setIsIframeLoaded] = useState(true);
    const trustedOrigin = `${mbpUtil?.getEnv('APP_CONVERSATIONAL_UI_HOST')}`;
    const isMobile = deviceType !== 'desktop';

    useEffect(() => {
        const handleMessage = (event) => {
            try {
                if (event.data.type === 'search-term-input-value') {
                    const searchTermValue = event.data.message;
                    history.push(`/searchterm/${searchTermValue.length === 0 ? 'notfound' : searchTermValue}`);
                }
            } catch (error) {
                mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    errorMessage: error,
                    message: `FloraChatSearch: Could not post value - ERROR: ${error}`,
                });
            }
        };
        if (isIframeLoaded) {
            window?.addEventListener('message', handleMessage);
        }
        return () => {
            window?.removeEventListener('message', handleMessage);
        };
    }, [isIframeLoaded, trustedOrigin]);

    const handleIframeLoad = () => setIsIframeLoaded(true);
    const handleIframeError = () => setIsIframeLoaded(false);

    return isIframeLoaded && isMobile ? (
        <iframe
            ref={iframeRef}
            id="18fchatbotsearch"
            title="18fchatbotsearch"
            style={{
                overflow: 'hidden',
                display: 'block',
                boxShadow: 'none',
                border: 'none',
                width: '100%',
                zIndex: 2243000,
            }}
            src={`${trustedOrigin}/chat/search`}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
        />
    ) : null;
};

ConversationalUISearch.propTypes = {
    deviceType: string.isRequired,
};

export default ConversationalUISearch;
