/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, call, put, select, take, takeEvery, all,
} from 'redux-saga/effects';
import { END } from 'redux-saga';
import mbpLogger from 'mbp-logger';

import { duck as tmDuck } from '../TagManager';
import memberDucks from '../Member/ducks';
import orderClient from '../../../apis/checkout-apis/orderClient';
import cartServices from '../../../apis/cart-service-apis/cartServices';

import {
    getPassportSubscriptionStatus,
    getPassportData,
    getPassportOrderItemId,
    getIfGiftCardApplied,
    getAppliedGiftCards,
    getOrderId,
    getPaymentMethod,
    getIsPassportRenewalAllowed,
    getPassportItem,
} from './Passport-Selectors';

import {
    passportFailed,
    loadOrderId,
    calculateShipping,
    calculateShippingComplete,
    loadPassportError,
    loadPassportLoader,
    clearPassportError,
    getDynamicPassportCharge,
    triggerPassportSubscription,
    hidePassportModalToRemoveGC,
    triggerAddPassportByRemoveGiftCard,
    callHandleCartFailure,
    subscribePassportBundle,
    passportPromoAppliedSuccessfully,
} from './Passport-Action';

import { retrieveCart } from '../Cart/Cart-Operations';
import { getBaseCode } from '../../../app/helpers/tracking/product/dataLayerProductHelpers';
import { setProfileUserRole } from '../Member/ducks/Profile/Profile-Actions';
import { addToCartSuccess } from '../AddToCart/AddToCart-Actions';

const {
    auth: {
        authOperations,
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;

const doApplyPromotion = ({
    JWT_TOKEN, orderId, promoCode, membershipId, currentPayment,
}) => cartServices.applyPromotion({
    env: {},
    jwtToken: JWT_TOKEN,
    cartId: orderId,
    promotionCode: promoCode,
    memberShipCode: membershipId,
    currentPayment,
});

const removeItem = ({
    wcEnv,
    jwtToken,
    orderItemId,
    orderId,
}) => cartServices.removeOrderItem({
    env: wcEnv, jwtToken, orderItemId, cartId: orderId,
});

function* workerLogPassportError(exception) {
    const response = exception?.response || {};
    let passportErrorMessage = 'We are sorry, there seems to be a technical issue, please try again';
    const errorMessage = response?.data?.detailedError?.errorMessage || response?.data?.errorMessage;
    const errorMessageList = response?.data?.detailedError?.validationErrors || [];
    if (errorMessage) {
        passportErrorMessage = errorMessage;
    }
    yield put(loadPassportError(passportErrorMessage, errorMessageList));
}

function* proccessPassportSubscription(action) {
    yield put(loadPassportLoader(true));
    yield put(clearPassportError());

    let trackEventCategory = '';
    const jwtToken = yield call(checkJWT);
    const passportSubscriptionStatus = yield select(getPassportSubscriptionStatus);
    const passportRenewalAllowed = yield select(getIsPassportRenewalAllowed);
    const { pageOn, history } = action.data;
    const passportData = yield select(getPassportData);
    let productCode = passportData?.item_id;
    // if passport is passed in props
    if (action.data.passportSku) {
        productCode = action.data.passportSku;
    }

    if (productCode.includes('-')) {
        const passportArray = productCode.split('-');
        productCode = (passportArray && passportArray?.[passportArray.length - 1]) || '';
    }

    const brandCode = passportData?.brand_id;

    if (!passportSubscriptionStatus && passportRenewalAllowed) {
        if (brandCode && productCode) {
            const item = { brandCode, productCode, isRenewal: true };
            const payload = {
                env: {},
                item,
                jwtToken,
            };
            const addToCartResp = yield call(cartServices.addPassportToCart, payload);
            const { cartId } = addToCartResp.data;
            const orderId = [cartId];
            if (pageOn === 'product') {
                trackEventCategory = 'Shopping';
                yield put(loadOrderId(orderId));
            } else if (
                ['shipping', 'cart', 'payment', 'payment-review', 'review-order', 'review'].includes(pageOn)
            ) {
                trackEventCategory = 'Checkout';
                yield put(calculateShipping());
                yield take(calculateShippingComplete().type);
                yield put(getDynamicPassportCharge());
            } else if (pageOn === 'passportPage') {
                trackEventCategory = 'Passport Page';
                yield put(loadOrderId(orderId));
            } else if (pageOn === 'passportSideBar') {
                trackEventCategory = 'Passport SideBar';
                yield put(loadOrderId(orderId));
            } else if (pageOn === 'payment-floral') {
                trackEventCategory = 'Payment Floral';
                yield put(loadOrderId(orderId));
            } else if (pageOn === 'subscriptionsExp') {
                trackEventCategory = 'Subscriptions Exp Page';
                yield put(loadOrderId(orderId));
            }

            yield call(retrieveCart);
            yield put(setProfileUserRole('M'));

            const eventAction = 'Add to cart';
            const { primeName, primeSku, primePrice } = addToCartResp.data;
            const passportEventObj = {
                eventName: 'add_to_cart',
                eventCategory: trackEventCategory,
                eventAction,
                eventLabel: 'Passport|<<pageType>>',
                products: [
                    {
                        name: primeName,
                        partNumber: primeSku,
                        baseCode: getBaseCode(primeSku),
                        brand: brandCode,
                        sKUs: [{
                            partNumber: primeSku,
                        }],
                        price: primePrice,
                        quantity: '1',
                        position: 1,
                        categoryId: '12 Months of Passport',
                    },
                ],
            };

            // clicstream add to cart tracking for passport
            const passportItem = yield select(getPassportItem);

            if (Array.isArray(passportItem) && passportItem.length > 0) {
                // created separate variable for baseCode & partNumber.

                const { product, deliveryInfo, itemInfo } = passportItem[0]?.cartItems[0] || {};
                const clickstreamPayload = {
                    eventType: 'clickstream.add-cart',
                    metaData: {
                        order: {
                            orderId: cartId,
                        },
                        products: [{
                            baseCode: product?.parentProductCode,
                            name: product?.parentProductName,
                            partNumber: product?.productCode,
                            price: itemInfo?.price,
                        }],
                        zipCode: passportItem[0]?.recipientAddress?.zipCode || deliveryInfo?.zipCode || '',
                    },
                };

                yield put(addToCartSuccess(clickstreamPayload));
            }

            yield put(tmDuck.actions.trackEvent(passportEventObj));
        } else {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                function: 'workerGetPassportTncContent',
                message: 'brandCode && productCode are missing',
            });

            yield put(loadPassportLoader(false));
            // generic error
            yield put(passportFailed());
        }
    } else {
        const passportOrderItemId = yield select(getPassportOrderItemId);
        const orderId = yield select(getOrderId);
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: passportOrderItemId,
            orderId,
        };

        // Remove Passport
        if (passportOrderItemId) yield call(removeItem, configObj);

        if (pageOn === 'payment') {
            trackEventCategory = 'Checkout';
            yield put(calculateShipping());
            yield take(calculateShippingComplete().type);
            yield put(getDynamicPassportCharge());
        }

        yield call(retrieveCart);
        yield put(setProfileUserRole(''));

        // Tealium Tracking Starts
        const eventAction = 'Remove from cart (pwa)';
        const passportEventObj = {
            eventCategory: trackEventCategory,
            eventAction,
            eventLabel: 'Passport|<<pageType>>',
        };

        yield put(tmDuck.actions.trackEvent(passportEventObj));
        // Tealium Tracking Ends
    }

    if (pageOn === 'passportPage' && history) {
        const orderId = yield select(getOrderId);
        history.push(`/checkout/cart/${orderId}`);
    }

    yield put(loadPassportLoader(false));
}

function* workerTriggerPassportSubscription(action) {
    try {
        // If the page is passport that is located in precheckout Ex: /passport-flowers.
        // We need get the last cart to make sure if gift cart is in it.
        if (action.data.pageOn === 'passportPage') {
            yield call(retrieveCart);
        }
        const ifGiftCardApplied = yield select(getIfGiftCardApplied);
        if (!ifGiftCardApplied) {
            yield call(proccessPassportSubscription, action);
        } else {
            // GC is applied show modal seeking to remove GC
            yield put(passportFailed({
                response: {
                    data: {
                        errorKey: 'ERROR_PRODUCT_NO_RESULTS',
                        errorMessage: 'Celebrations Passport cannot be purchased with Gift Card, to continue adding Passport please remove applied Gift Card on your cart',
                        errorCode: 400,
                    },
                },
            }));
        }
    } catch (ex) {
        console.error('workerTriggerPassportSubscription - ex:', ex.response);
        yield put(callHandleCartFailure(ex));
        yield put(loadPassportLoader(false));
        yield put(passportFailed(ex));
    }
}

function* watcherTriggerPassportSubscription() {
    yield takeEvery(triggerPassportSubscription().type, workerTriggerPassportSubscription);
}

function* workerRemoveAllAppliedGiftCard(action) {
    try {
        const cartId = yield select(getOrderId);
        const appliedGiftCards = yield select(getAppliedGiftCards);
        if (appliedGiftCards && appliedGiftCards.length > 0) {
            const jwtToken = yield call(checkJWT);

            const rmGCPromise = appliedGiftCards.map((giftCard) => {
                const { giftCardId } = giftCard;

                return cartServices.removeGiftCard(
                    {
                        env: {},
                        jwtToken,
                        cartId,
                        giftCardId,
                    });
            });

            yield all(rmGCPromise);
            yield call(proccessPassportSubscription, action);
            yield put(hidePassportModalToRemoveGC());
        }

        if (action.data.pageOn === 'passportPage') {
            const orderId = yield select(getOrderId);
            action.data.history.push(`/checkout/cart/${orderId}`);
        }
    } catch (ex) {
        yield put(hidePassportModalToRemoveGC());
        yield put(loadPassportLoader(false));
        yield put(passportFailed(ex));
    }
}

function* watcherTriggerAddPassportByRemoveGiftCard() {
    yield takeEvery(triggerAddPassportByRemoveGiftCard().type, workerRemoveAllAppliedGiftCard);
}

function* workerSubscribePassportBundle(action) {
    const { passportBundle, history } = action.data;
    yield put(tmDuck.actions.trackEvent({
        eventCategory: 'product',
        eventAction: 'Passport Banner Click',
        eventLabel: 'Bundle',
    }));

    yield put(triggerPassportSubscription('product', passportBundle?.passportSku));

    // regext for -- catching promocode from the url params
    if (history?.location?.search && new RegExp(/promoCode=(.*)/).test(history.location.search)) {
        const match = (history.location.search).match(/promoCode=(.*)/);
        if (match.length >= 1) {
            const promoCode = match[1];
            const JWT_TOKEN = yield call(checkJWT);
            const selectedPaymentMethod = yield select(getPaymentMethod);
            const currentPayment = selectedPaymentMethod && selectedPaymentMethod.id ? selectedPaymentMethod.id : '';
            const orderId = yield select(getOrderId);

            // Apply Promo
            yield call(doApplyPromotion, {
                JWT_TOKEN,
                orderId,
                promoCode,
                membershipId: '',
                currentPayment,
            });

            yield put(passportPromoAppliedSuccessfully());
        }
    }
}

function* watcherSubscribePassportBundle() {
    yield takeEvery(subscribePassportBundle().type, workerSubscribePassportBundle);
}

function* watchPassportFailure() {
    let action = yield take(passportFailed().type);
    while (action !== END) {
        yield fork(workerLogPassportError, action.payload);
        action = yield take(passportFailed().type);
    }
}

const watchers = [
    fork(watcherTriggerAddPassportByRemoveGiftCard),
    fork(watcherTriggerPassportSubscription),
    fork(watchPassportFailure),
    fork(watcherSubscribePassportBundle),
];

export {
    watchers,
};

export default {};
