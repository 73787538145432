/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import clickStreamEventActionTypes from './ClickStreamEvent-ActionTypes';

export const emitProductImpression = (payload) => ({
    type: clickStreamEventActionTypes.PRODUCT_IMPRESSION,
    payload,
});

export const emitCustomTrackingEvent = (payload) => ({
    type: clickStreamEventActionTypes.CUSTOM_TRACKING_EVENT,
    payload,
});

export const emitCustomerIdentificationEvent = (payload) => ({
    type: clickStreamEventActionTypes.CUSTOMER_IDENTIFICATION_EVENT,
    payload,
});

export const setClickstreamExperiments = (payload) => ({
    type: clickStreamEventActionTypes.SET_CLICKSTREAM_EXPERIMENTS,
    payload,
});

export const emitClickstreamCheckoutEvent = (payload) => ({
    type: clickStreamEventActionTypes.CLICKSTREAM_CHECKOUT_EVENT,
    payload,
});

export default {
    emitProductImpression,
    emitCustomerIdentificationEvent,
    setClickstreamExperiments,
    emitClickstreamCheckoutEvent,
};
