/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    string, shape, array, bool, arrayOf,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { uid } from 'react-uid';
import { makeStyles } from '@material-ui/core';

import ReactMarkdown from 'react-markdown';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector } from 'react-redux';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    QaListStyle,
} from '../style/QaListStyle';
import FaqSeoSchema from '../../../../helpers/Seo/FaqSeoSchema';
import { getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { RenderChatAsLink } from '../../../MultiBrandChatController/AigoChatWidgetWrapperV2/popOpenChat';

const useStyles = makeStyles((theme) => QaListStyle(theme));

const QaList = ({
    defaultCategoryValue, layoutDescription, categoryDescription, accordion,
    layoutTitle, identifyWidthObj, faqSchemaSkip, isCategory,
}) => {
    const [activeValue, setActiveValue] = useState(defaultCategoryValue);
    const [accordionActive, setAccordionActive] = useState(null);
    const [categoryValue, setCategoryValue] = useState(defaultCategoryValue);
    const presentationFamily = useSelector(getPresentationFamily);
    const classes = useStyles();
    const handleChange = (event, newActiveValue) => {
        setActiveValue(newActiveValue);
        setCategoryValue(event.target.value || newActiveValue);
    };

    const handleChangeExpansionPanel = (panel) => (event, newExpanded) => {
        setAccordionActive(newExpanded ? panel : null);
    };

    const setExpansionPanel = (layoutItem, index, key) => (
        <Grid item md={12} xs={12} className={classes.expansionContainer} data-testid={`faq-question-${`${key}-${index}`}`} key={uid(layoutItem, index)}>
            <ExpansionPanel expanded={accordionActive === index} onChange={handleChangeExpansionPanel(index)}>
                <ExpansionPanelSummary>
                    <Grid container>
                        <Grid item md={11} xs={10}>
                            <Typography variant="body1" className={classes.expansionTitle}>
                                <ReactMarkdown source={layoutItem.question} escapeHtml={false} />
                            </Typography>
                        </Grid>
                        <Grid item md={1} xs={2} className={classes.addIcon}>
                            {(accordionActive === index) ? <RemoveCircleIcon /> : <AddCircleIcon />}
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid item md={12} xs={12} className={classes.expansionDetails}>
                        <ReactMarkdown
                            source={layoutItem.answer}
                            escapeHtml={false}
                            renderers={{
                                text: ({ value }) => {
                                    if (typeof value === 'string' && value?.toLowerCase()?.includes('chat')) {
                                        return <RenderChatAsLink value={value} />;
                                    }
                                    return value;
                                },
                            }}
                        />
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Grid>
    );

    const getNormalLayoutQA = (layoutItem, index) => (
        <Grid container key={uid(layoutItem, index)}>
            <Grid item md={12} xs={10}>
                <Typography variant="body1">
                    <b>{layoutItem.question}</b>
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <ReactMarkdown source={layoutItem.answer} />
            </Grid>
        </Grid>
    );

    const setSeoSchema = () => {
        if (layoutDescription?.length === 0) return null;
        const coalescedQuestions = Object.keys(layoutDescription).map((catObjectKey) => layoutDescription[catObjectKey].value);
        return <FaqSeoSchema faqArray={coalescedQuestions} />;
    };

    const getFaqFullList = () => {
        const getFaqList = [];
        (layoutDescription || []).forEach((items) => {
            if (isCategory) {
                getFaqList.push(items?.value);
            }
        });
        const toSingleArray = [].concat(...getFaqList);
        return toSingleArray;
    };

    const handleCategoryFaq = () => {
        if (layoutDescription?.length === 0) return null;
        return layoutDescription.map((layoutItem, index) => {
            if (isCategory) {
                const {
                    key,
                    value,
                } = layoutItem;

                return (
                    <Grid item md={12} xs={12} key={uid(layoutItem, index)}>
                        <Typography
                            component="div"
                            role="tabpanel"
                            hidden={key !== categoryValue}
                            aria-labelledby={`faq-tab-${categoryValue}-${key}`}
                            id={`faq-tab-${categoryValue}-${key}`}
                        >
                            {value.map((faqItem, faqIndex) => (
                                (accordion) ? setExpansionPanel(faqItem, faqIndex, key) : getNormalLayoutQA(faqItem, faqIndex)
                            ))}
                        </Typography>
                    </Grid>
                );
            }
            return (accordion) ? setExpansionPanel(layoutItem, index) : getNormalLayoutQA(layoutItem, index);
        });
    };
    const categoryLength = (categoryDescription && categoryDescription.length > 0);
    return (
        <Grid container className={classes.layoutBorder}>
            <Grid item md={12} xs={12} className={(categoryLength) ? '' : classes.marginBottom18}>
                {layoutTitle?.indexOf('#') >= 0 ? (<div className={classes.layoutTitleBox}><ReactMarkdown source={layoutTitle} /></div>) : (<Typography variant="h5" className={classes.layoutTitle} gutterBottom>{layoutTitle}</Typography>)}
            </Grid>
            {(categoryLength)
                && (
                    <Grid item md={12} xs={12} className={classes.expansionContainer}>
                        {(identifyWidthObj.isDesktop)
                            ? (
                                <Tabs
                                    value={activeValue}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleChange}
                                    aria-label="category"
                                    className={classes.tabs}
                                >
                                    {categoryDescription.map((categoryItem) => (
                                        <Tab label={categoryItem?.label} key={uid(categoryItem?.value)} value={categoryItem?.value} />
                                    ))}
                                </Tabs>
                            ) : (
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="category-select-outlined-label">Category</InputLabel>
                                    <Select
                                        labelId="category-select-outlined-label"
                                        id="category-select-outlined"
                                        value={categoryValue}
                                        onChange={handleChange}
                                        label="Category"
                                    >
                                        {presentationFamily !== 'food' && (
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                        )}
                                        {categoryDescription.map((categoryItem) => (
                                            <MenuItem value={categoryItem?.value} key={uid(categoryItem?.value)}>{categoryItem?.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                    </Grid>
                )}
            {(layoutDescription && layoutDescription.length > 0)
                && (
                    <>
                        {!faqSchemaSkip && <FaqSeoSchema faqArray={getFaqFullList()} />}
                        {handleCategoryFaq()}
                    </>
                )}
            {(layoutDescription && layoutDescription.length > 0 && !faqSchemaSkip)
                && (
                    setSeoSchema()
                )}
        </Grid>
    );
};

QaList.defaultProps = {
    categoryDescription: [],
    defaultCategoryValue: '',
    faqSchemaSkip: false,
};

QaList.propTypes = {
    categoryDescription: arrayOf(shape({
        label: string,
        value: string,
    })),
    layoutDescription: array.isRequired,
    layoutTitle: string.isRequired,
    isCategory: bool.isRequired,
    identifyWidthObj: shape({
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
    }).isRequired,
    defaultCategoryValue: string,
    accordion: bool.isRequired,
    faqSchemaSkip: bool,
};

export default QaList;
