/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { determineChatlink } from '../Global_chat_helpers';
import { getCurrentBrandId, getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getOperatingSystem } from '../../../../state/ducks/App/ducks/Config/Config-Helpers';

const triggerPopChatEvent = (payload = {}) => {
    if (typeof window !== 'undefined') {
        const popOpenDesktopChat = new CustomEvent('popOpenChat', { detail: payload });
        window.dispatchEvent(popOpenDesktopChat);
    }
};

export const RenderChatAsLink = ({
    value,
}) => {
    const operatingSystem = getOperatingSystem().toLowerCase();
    const currentBrand = useSelector((state) => getCurrentBrandId(state));
    const customerData = useSelector((state) => state?.member);
    const featureFlags = useSelector((state) => getFeatureFlags(state));
    const chatRegex = /(chatting|chat)/gi;
    const parts = value?.split(chatRegex);
    const renderPart = (part, index) => {
        const linkStyle = operatingSystem !== 'android' ? { textDecoration: 'underline', cursor: 'pointer' } : null;
        if (part?.toLowerCase() === 'chat' || part?.toLowerCase() === 'chatting') {
            return (
                <a
                    key={index}
                    onClick={() => {
                        if (operatingSystem !== 'android' && operatingSystem !== 'ios') {
                            triggerPopChatEvent({});
                        }
                    }}
                    onKeyDown={() => { }}
                    href={determineChatlink(operatingSystem, currentBrand, featureFlags, customerData) || null}
                    role="button"
                    tabIndex="0"
                    alt="chat"
                    style={linkStyle}
                >
                    {part}
                </a>
            );
        }
        // removing extra br tag
        const cleanedPart = part?.replace(/<\/br>/g, '');
        return <React.Fragment key={index}>{ReactHtmlParser(cleanedPart)}</React.Fragment>;
    };
    return (
        <span>
            {parts?.map((part) => (
                <>
                    {renderPart(part)}
                </>
            ))}
        </span>
    );
};

RenderChatAsLink.propTypes = {
    value: string.isRequired,
};

export default triggerPopChatEvent;
