/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { UID } from 'react-uid';
import {
    object, shape, string, arrayOf, bool,
} from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { RenderChatAsLink } from '../../../../MultiBrandChatController/AigoChatWidgetWrapperV2/popOpenChat';

const styles = (theme) => ({   // eslint-disable-line arrow-parens
    moreAbout: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        outlineColor: '#CCC',
        margin: '30px 0',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    moreAboutWithGrid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        outlineColor: '#CCC',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    text: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '21px',
        padding: '15px',
        fontWeight: 'bold',
        color: theme.palette.sectionHeaderTextColor,
        [theme.breakpoints.down(600)]: {
            fontSize: '16px',
        },
    },
    plusIcon: {
        height: '26px',
        marginBottom: '0',
        width: '26px',
        '&:hover': {
            textDecoration: 'none',
        },
        [theme.breakpoints.down(600)]: {
            height: '20px',
        },
    },
    seoContentContainer: {
        overflow: 'hidden',
        transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    footerBody: {
        paddingLeft: '15px',
        paddingRight: '15px',
        fontSize: '14px',
        maxWidth: '1400px',
        margin: '0 auto',
        letterSpacing: '.03em',
        WebkitFontSmoothing: 'antialiased',
        '& h1, h2': {
            width: '100%',
            color: theme.palette.sectionHeaderTextColor,
            fontFamily: theme.typography.fontFamily,
            margin: '0',
            padding: '15px',
            fontSize: '14px',
            whiteSpace: 'normal',
            background: theme.palette.colorNeutral30,
        },
        '& h2': {
            '& ul': {
                boxSizing: 'content-box',
            },
        },
        '& p': {
            padding: '10px 15px',
            color: theme.palette.sectionHeaderTextColor,
            lineHeight: '1.5',
        },
        '& a': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
        '& ul': {
            lineHeight: '1.5',
            color: theme.palette.sectionHeaderTextColor,
            margin: '10px 30px',
        },
    },
});

class GraphqlSeoFooter extends Component {
    constructor(props) {
        super(props);
        const { data: { default_state } } = this.props;
        this.heightRef = React.createRef();
        this.transEnd = React.createRef();
        this.state = {
            isExpanded: default_state === 'Expanded',
            divHeight: 'auto',
        };
    }

    componentDidUpdate() {
        if (this.transEnd?.current) {
            this.transEnd.current.addEventListener('transitionend', () => {
                // after height transition set to auto for responsiveness
                this.transEnd.current.style.height = 'auto';
            });
        }
    }

    showMoreText = () => {
        const { isExpanded } = this.state;
        this.setState({
            isExpanded: !isExpanded,
            divHeight: this.heightRef.current.clientHeight, // get hieght of div
        });
    }

    render() {
        const { isExpanded, divHeight } = this.state;
        const {
            classes, data: { group, title }, hasHomepageGridStructure,
        } = this.props;

        const currentHeight = isExpanded ? divHeight : 0;
        if (group?.length && title !== undefined) {
            return (
                <>
                    <div
                        className={hasHomepageGridStructure ? classes.moreAboutWithGrid : classes.moreAbout}
                        role="button"
                        tabIndex="0"
                        onKeyDown={this.showMoreText}
                        onClick={this.showMoreText}
                    >
                        {title && (
                            <div className={classes.text}>
                                {title}
                            </div>
                        )}
                        {isExpanded
                            ? (
                                <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" aria-label="Minimize" className={classes.plusIcon}>
                                    <g>
                                        <path style={{ fill: '#373737' }} d="M19,13H5V11H19V13Z" />
                                    </g>
                                </svg>
                            ) : (
                                <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" aria-label="Expand" className={classes.plusIcon}>
                                    <g>
                                        <path style={{ fill: '#373737' }} d="M11 3 h2 v7 h7 v2 h-7 v7 h-2 v-7 h-7 v-2 h7 z" />
                                    </g>
                                </svg>
                            )}
                    </div>
                    <div
                        className={classes.seoContentContainer}
                        style={{ height: `${currentHeight}px` }}
                        ref={this.transEnd}
                    >
                        <div className={classes.footerBody} ref={this.heightRef}>
                            <UID>
                                {(id, uid) => group.map((data) => (
                                    <div key={uid(data)}>
                                        <ReactMarkdown
                                            source={data?.heading}
                                            skipHtml
                                        />
                                        <ReactMarkdown
                                            source={data?.copy}
                                            skipHtml
                                            renderers={{
                                                text: (textProps) => {
                                                    const child = textProps.value;
                                                    if (typeof child === 'string' && child?.toLowerCase()?.includes('chat')) {
                                                        return <RenderChatAsLink value={child} />;
                                                    }
                                                    return child;
                                                },
                                            }}
                                        />
                                    </div>
                                ))}

                            </UID>
                        </div>
                    </div>
                </>
            );
        }
        return null;
    }
}

GraphqlSeoFooter.propTypes = {
    classes: object.isRequired,
    data: shape({
        group: arrayOf(shape({
            heading: string,
            copy: string,
        }).isRequired),
        title: string.isRequired,
    }).isRequired,
    hasHomepageGridStructure: bool.isRequired,
};

const mapStateToProps = (state) => ({
    hasHomepageGridStructure: getFeatureFlag('has-homepage-grid-structure')(state),
});

const enhance = compose(
    connect(mapStateToProps, null),
);

export default enhance(withStyles(styles)(GraphqlSeoFooter));
