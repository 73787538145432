/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import {
    arrayOf, node, string, bool, oneOfType, object,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import useVisibility from '../hooks/useVisibility';
import { getIsBot } from '../../../state/ducks/App/App-Selectors';
import { getFeatureFlag } from '../../../state/ducks/App/ducks/Config/Config-Selectors';

const RenderWhenVisible = (props) => {
    const {
        children,
        placeholderWidth,
        placeholderHeight,
        renderSSR = false,
    } = props;

    const isRenderWhenVisibleEnabled = useSelector(getFeatureFlag('is-render-when-visible-enabled'));

    // note: keep this above below hooks so we dont enter
    // more complexities needlessly (useVisibility)
    if (!isRenderWhenVisibleEnabled) {
        return children;
    }

    const seed = useUIDSeed();
    const { current: callbackId } = useRef(seed(children));
    const [visibile, ref] = useVisibility(callbackId);
    const isBot = useSelector(getIsBot);
    const isSSR = (typeof window === 'undefined');

    if (!isBot && isSSR && !renderSSR) {
        return null;
    }

    let style = {
        width: placeholderWidth || '100vw',
        height: placeholderHeight || '100vh',
        maxWidth: '100%',
    };

    if (visibile) style = {};

    return (
        <div
            data-staticid={callbackId} // static callback id that wont change between renders
            ref={ref}
            style={style}
            data-id={props?.children?.props?.block?.offer_gateway?.content_type}
        >
            {visibile ? children : null}
        </div>
    );
};

RenderWhenVisible.propTypes = {
    children: oneOfType([arrayOf(node), object]).isRequired,
    placeholderWidth: string,
    placeholderHeight: string,
    renderSSR: bool,
};

RenderWhenVisible.defaultProps = {
    placeholderWidth: '',
    placeholderHeight: '',
    renderSSR: false,
};

export default RenderWhenVisible;
