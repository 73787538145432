/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable no-shadow */

import { END } from 'redux-saga';
import {
    call, take, put, fork, select, takeLatest, getContext,
} from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import QueryString from 'qs';
import axios from 'axios';

import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';
import * as attributionHelpers from '../../../app/helpers/attribution/attributionHelpers';
import brandThemes from '../../../app/components/AppShell/brandtheme';
import {
    getSearchResult,
    loadAppShell,
    loadAppShellSSR,
    setAppShellLoadState,
    initSSR,
    clearSearchResult,
    setProductFilterZipcodeValidity,
    setValidatedZipcode,
    validateProductFilterZipcode,
    verifyWine,
    verifyWineDone,
    verifyWineFailed,
    setWineData,
    fetchGCIKey,
    setGCIKey,
    setSearchResult,
    setAgeVerifyFlagInvalid,
    createEmailSubscription,
    setEmailSubscriptionData,
    resetEmailSubscription,
    setBannerCode,
    clearBannerCode,
    postChatEventWithEID,
    subscriptionVerifyAddress,
    setAddressSource,
    setValidatedStateOfZipcode,
} from './App-Actions';
import {
    isWineAvailable, mockSSREnvironment, getSCIandSGParams,
} from './App-Helpers';
import isDesktopMobileTablet from '../../../app/helpers/DesktopMobile/isDesktopMobileTablet';
import { getCurrentUser } from './App-Selectors';
import { getBrand } from './ducks/Brand/Brand-Selectors';
import { addToCartNewCartServices } from '../AddToCart/AddToCart-Actions';
import {
    clearSCILocNumber, clearSCICampaign, clearSCIDispositionType, setSCILocNumber,
} from '../SCI/SCI-Actions';
import { getSCILocNumber } from '../SCI/SCI-Selectors';
import { fetchBrand } from './ducks/Brand/Brand-Operations';
import { fetchFeatureFlags } from './ducks/Config/Config-Operations';
import { checkJWT } from '../Member/ducks/Auth/Auth-Operations';
import { subscribeEmailApi } from '../../../apis/account-apis/subscribeEmailApi';
import { getFeatureFlags } from './ducks/Config/Config-Selectors';
import postChatEventsWithEID from '../../../apis/enterpriseid-apis/chatEventEnterpriseId';
import { workerSagaUpdateEnterpriseId, workerSagaCreateEnterpiseId } from '../Member/ducks/EnterpriseId/EnterpriseId-Operations';
import { initializeABTesting } from './ducks/ABTesting/ABTesting-Operations';
import orderClient from '../../../apis/checkout-apis/orderClient';
import { qasAddressSearchOperations } from '../Common/ducks/AddressSearchQAS';
import EventDispatcher from '../../../app/pages/Account/state/reducers/EventDispatcher';
import { addToCartOnEvent } from '../AddToCart/AddToCart-Operations';
import { trackEvent } from '../TagManager/ducks/TagManager/TagManager-Actions';

const wcEnv = {};
const fetchVerifyWine = (jwt, payload) => orderClient.verifyWine(wcEnv, jwt, payload);
const verifyWineAndAvailability = (jwt, payload) => orderClient.verifyWineAndAvailability(wcEnv, jwt, payload);

const fetchGCIKeyAPI = ({ jwt, params }) => orderClient.getGCIKey(wcEnv, jwt, params);

const brandsEmailOpt = {
    FB: '18F',
    FBQ: '18F',
    HD: 'HND',
    WF: 'WLF',
    SY: 'STK',
    STY: 'STK',
    BRY: '18F',
};
function* setAttributionState(props) {
    const {
        location,
        history,
        brand,
        featureFlags,
    } = props;

    // handle when location.search may already have leading '?' removed
    const locationSearch = location?.search?.substr(0, 1) === '?' ? location.search.slice(1) : location?.search;
    const urlParams = QueryString.parse(locationSearch);
    const bannerCode = urlParams?.r || urlParams?.ref || urlParams?.LocNumber;

    if (urlParams) {
        console.log({
            function: 'setAttributionState',
            message: `Debug: bannerCode: "${bannerCode}"`,
            urlParams,
        });
    }
    // Set bannerCode in redux
    if (bannerCode) {
        yield put(setBannerCode(bannerCode));
    } else {
        yield put(clearBannerCode());
    }

    // Process Attribution (set cookie)
    if (location.pathname.indexOf('ProcessExpressRequest') > 0) { // Express Checkout
        // TODO: Move to Checkout App
        yield call(attributionHelpers.processAttribution, props, 'ProcessExpressRequest');
        history.push(`/checkout/express${location.search}`);
    } else {
        const attributionProps = {
            attributionCookieExpiration: brand.attributionCookieExpiration || '',
            location,
            featureFlags,
            history,
        };

        yield call(attributionHelpers.processAttribution, attributionProps, 'Attribution', bannerCode || '');
    }
}

function* onLoadAppShell({
    appShellLoaded,
    fastlyDeviceType,
    history,
    hostname,
    isBot,
    location,
    shopperManagerId,
    siteVariant,
    isUSCustomer,
    userAgent,
    userKey,
}) {
    const mbpLogger = yield getContext('mbpLogger');
    const cookies = yield getContext('cookies');
    try {
        mbpLogger.logDebug({
            hostname,
            path: location.pathname,
            search: location.search,
            shopperManagerId,
            isBot,
            userAgent,
            fastlyDeviceType,
            appShellLoaded,
            function: 'onLoadAppShell',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'Load App | START',
        });

        if (location.search) {
            console.log({
                cookies,
                hostname,
                path: location.pathname,
                search: location.search,
                shopperManagerId,
                isBot,
                userAgent,
                fastlyDeviceType,
                appShellLoaded,
                function: 'onLoadAppShell',
                module: 'mbp-pwa-ui',
                message: 'Load App | START',
            });
        }

        const user = yield select(getCurrentUser);
        // brand config from ref-brand
        // is the brand loaded from SSR?
        let brand = yield select(getBrand);
        if (!brand) {
            yield call(fetchBrand, { hostname });
            brand = yield select(getBrand);
        }

        if (!appShellLoaded) {
            let deviceType = '';
            if (isBot) {
                deviceType = isDesktopMobileTablet(userAgent, hostname);
            } else {
                deviceType = fastlyDeviceType || isDesktopMobileTablet(userAgent);
            }

            yield put(initSSR({
                hostname,
                path: location.pathname,
                search: location.search,
                shopperManagerId,
                isBot,
                userAgent,
                deviceType,
                fastlyDeviceType,
                userKey,
                siteVariant,
                isUSCustomer,
            }));

            yield call(fetchFeatureFlags);
            yield put(setAppShellLoadState(true));
        }

        // CSR Ops Only
        if (typeof window !== 'undefined' && !location?.pathname?.includes('/auth/login')) {
            const featureFlags = yield select(getFeatureFlags);
            const params = QueryString.parse(location?.search?.replace('?', '')) || {};
            const localStorageValueOfEnterpriseId = window.localStorage.getItem('enterpriseId');

            if (params) {
                console.error({
                    function: 'onLoadAppShell',
                    module: 'mbp-pwa-ui',
                    message: 'Debug: START - params',
                    params,
                    featureFlags: !!featureFlags?.['is-checkout-monitoring-enabled'],
                });
            }

            // Enterprise Id
            if (featureFlags['is-create-enterprise-id-enabled']) {
                if (localStorageValueOfEnterpriseId && params.mi_u) {
                    yield call(workerSagaUpdateEnterpriseId, params);
                } else {
                    yield call(workerSagaCreateEnterpiseId, params);
                }
            }
            // Optimize
            yield call(initializeABTesting);
            yield call(addToCartOnEvent);

            // Attribution
            yield call(setAttributionState, {
                location,
                history,
                brand,
                featureFlags,
            });

            if (featureFlags['is-checkout-monitoring-enabled'] && params) {
                console.error({
                    function: 'onLoadAppShell',
                    module: 'mbp-pwa-ui',
                    message: 'Debug: AFTER - setAttributionState - cookie',
                    cookieBanner: cookies.get('banner'),
                });
            }
            // SCI
            const cookieValue = cookies.get('banner');
            const sciLocNumber = yield select(getSCILocNumber, location);
            if (cookieValue?.c !== sciLocNumber) {
                yield put(clearSCILocNumber());
                yield put(clearSCICampaign());
                yield put(clearSCIDispositionType());
            }

            // from Smartgift on SCI
            const sciSGParams = getSCIandSGParams(params.customParams, params.sku);
            if (sciSGParams?.SCILocNumber && sciSGParams?.source === 'smg') {
                yield put(setSCILocNumber(sciSGParams.SCILocNumber));
                yield put(setAddressSource('smg'));
            }

            // from dignitymemorial, SCI direct
            if (params?.LocNumber) {
                yield put(setSCILocNumber(params?.LocNumber));
                yield put(setAddressSource('SCI'));
            }

            // local dev only
            if (hostname.includes('localhost') && !hostname.includes('8080')) {
                const mockSSRConfig = mockSSREnvironment(hostname, location);
                yield put(initSSR(mockSSRConfig));
            }

            if (featureFlags['is-interstitial-login-enabled']) {
                yield put(trackEvent({
                    eventCategory: 'Checkout',
                    eventAction: 'Experience Impression',
                    eventLabel: 'Interstitial Page Enabled',
                }));
            } else {
                yield put(trackEvent({
                    eventCategory: 'Checkout',
                    eventAction: 'Experience Impression',
                    eventLabel: 'Interstitial Page Disabled',
                }));
            }

            if (featureFlags['is-checkout-monitoring-enabled'] && params) {
                console.error({
                    function: 'onLoadAppShell',
                    module: 'mbp-pwa-ui',
                    message: 'Debug: END - cookie',
                    cookieBanner: cookies.get('banner'),
                });
            }
        }
        mbpLogger.logDebug({
            brand,
            user,
            hostname,
            location,
            shopperManagerId,
            isBot,
            userAgent,
            fastlyDeviceType,
            function: 'onLoadAppShell',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            message: 'onLoadAppShell | Complete',
        });
    } catch (ex) {
        // console.log('bladerunner testing', ex);
        mbpLogger.logError({
            hostname,
            location,
            shopperManagerId,
            isBot,
            userAgent,
            fastlyDeviceType,
            function: 'onLoadAppShell',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            jsError: ex,
            message: 'Failed to load app data.',
        });
    }
}

function* watchLoadAppShell() {
    let action = yield take(loadAppShell().type);

    while (action !== END) {
        yield call(onLoadAppShell, action.payload);
        action = yield take(loadAppShell().type);
    }
}

function* watchLoadAppSSR() {
    let action = yield take(loadAppShellSSR().type);

    while (action !== END) {
        yield call(onLoadAppShell, action.payload);
        action = yield take(loadAppShellSSR().type);
    }
}

// Zip Validation Sagas
function* workerValidateProductFilterZipcode(action) {
    mbpLogger.logDebug({
        appName: process.env.npm_package_name,
        function: 'workerValidateProductFilterZipcode',
        message: `Validate Zipcode "${action?.payload}"`,
    });
    try {
        const zipcode = action.payload;
        const accessToken = yield call(checkJWT);
        if (zipcode.length >= 5) {
            const response = yield call(orderClient.getCityStateFromZip, ...[{}, accessToken, zipcode]);
            const { data } = response;
            if (data?.esbSaltaServiceResponse?.checkZipResponse?.checkZipResult?.location?.zipCode) {
                const state = data?.esbSaltaServiceResponse?.checkZipResponse?.checkZipResult?.location?.state || '';
                yield put(setValidatedZipcode(zipcode));
                yield put(setProductFilterZipcodeValidity(true));
                yield put(setValidatedStateOfZipcode(state));
            } else if (data?.esbSaltaServiceResponse?.checkZipResponse?.checkZipResult?.flwsErrors) {
                yield put(setProductFilterZipcodeValidity(false));
                yield put(setValidatedStateOfZipcode(''));
            } else {
                yield put(setValidatedStateOfZipcode(''));
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            function: 'workerValidateProductFilterZipcode',
            jsError: ex,
            message: `workerValidateProductFilterZipcode - failed on zipcode "${action?.payload}"`,
        });
    }
}

function* watchValidateProductFilterZipcode() {
    yield takeLatest(validateProductFilterZipcode().type, workerValidateProductFilterZipcode);
}

function* onVerifyWine({
    wineData, addToCart, handleMobileAgeVerification,
}) {
    // Check for required data
    if (!wineData) {
        return;
    }
    let zipCode;
    let isValidZip = true;
    const accessToken = yield call(checkJWT);
    const featureFlags = yield select(getFeatureFlags);
    const newWineVerificationApi = featureFlags['is-wine-verification-new-api-enabled'];
    const user = yield select(getCurrentUser);
    let response = {};
    if (newWineVerificationApi) {
        response = yield call(verifyWineAndAvailability, user.accessToken, wineData);
    } else {
        response = yield call(fetchVerifyWine, user.accessToken, wineData);
    }

    const { data } = response;
    if (data) {
        const wineResponse = {
            ...data,
            wineVerify: {
                birthDay: wineData?.birthDay || wineData?.wineVerify?.birthDay,
                birthMonth: wineData?.birthMonth || wineData?.wineVerify?.birthMonth,
                birthYear: wineData?.birthYear || wineData?.wineVerify?.birthYear,
                minAge: wineData?.minAge || wineData?.wineVerify?.minAge,
            },
        };
        yield put(setWineData(wineResponse));
    }

    if (data?.wineAvailability) {
        Object.keys(data.wineAvailability).forEach((key) => {
            if (data.wineAvailability[key]?.zipCode)  zipCode = data.wineAvailability[key]?.zipCode;
        });
    }
    if (zipCode) {
        const zipRes = yield call(orderClient.getCityStateFromZip, ...[{}, accessToken, zipCode]);
        // checking if zip code is valid / else below condition will return false
        isValidZip = zipRes?.data?.esbSaltaServiceResponse?.checkZipResponse?.checkZipResult?.location?.zipCode;
    }

    if (!data || data.error || !isWineAvailable(data) || !isValidZip) {
        yield put(verifyWineFailed(data));
        yield put(setAgeVerifyFlagInvalid(false));
        return;
    }

    yield put(verifyWineDone(data));

    if (addToCart && addToCart.partNumber) {
        const {
            reqObj,
            history,
            partNumber,
            categoryId,
            categoryName,
            categoryPath,
            enableMinicart,
            enableMiniCartView,
            handleMiniCartModalClick,
            isMobileData,
        } = addToCart;

        const item = [{ ...reqObj }];
        if (wineData?.birthDay || wineData?.wineVerify?.birthDay) {
            const birthDay = wineData?.birthDay || wineData?.wineVerify?.birthDay;
            const birthMonth = wineData?.birthMonth || wineData?.wineVerify?.birthMonth;
            const birthYear = wineData?.birthYear || wineData?.wineVerify?.birthYear;
            item[0].wineVerify = {
                birthDate: `${birthMonth}/${birthDay}/${birthYear}`,
                minAge: wineData.minAge,
            };
        }

        handleMobileAgeVerification(false);

        yield put(addToCartNewCartServices({
            item: [...item],
            history,
            categoryId,
            partNumber,
            categoryName,
            categoryPath,
            enableMinicart: enableMinicart || enableMiniCartView,
            handleMiniCartModalClick,
            isMobile: isMobileData,
            eventType: 'Product Page',
        }));
    }
}

const getSuggestionData = (params) => axios.get('https://brm-suggest-0.brsrvr.com/api/v1/suggest/?', { params }).then((response) => response.data);

function* onSearchResult(searchValue) {
    const brand = yield select(getBrand);
    const { bloomreach } = brandThemes[brand?.code] || {};
    const APP_BLOOMREACH_AUTH_KEY = mbpUtil.getEnv('APP_BLOOMREACH_AUTH_KEY');

    const featureFlags = yield select(getFeatureFlags);
    let esAutoCompleteEnabled = featureFlags['is-elastic-auto-complete-enabled'];
    if (!featureFlags['is-elastic-search-enabled']) {
        // disabling ES auto complete if ES is disabled.
        esAutoCompleteEnabled = false;
    }

    const isBloomreachEnabled = (!esAutoCompleteEnabled && !featureFlags['is-elastic-search-enabled']);
    if (!bloomreach?.account_id || !APP_BLOOMREACH_AUTH_KEY || !isBloomreachEnabled) {
        return;
    }

    const cookies = new Cookies();
    const br_uid_2 = cookies.get('_br_uid_2');
    const params = {
        account_id: bloomreach.account_id,
        auth_key: APP_BLOOMREACH_AUTH_KEY,
        domain_key: bloomreach?.domain_key || '',
        request_id: bloomreach?.request_id || '',
        _br_uid_2: br_uid_2,
        url: bloomreach?.url || '',
        q: searchValue,
        request_type: 'suggest',
        view_id: bloomreach?.view_id || '',
    };
    const Data = yield call(getSuggestionData, params);
    if (Data) {
        yield put(setSearchResult(Data.response));
    }
}

function* watchGetSearchResult() {
    let action = yield take(getSearchResult().type);
    while (action !== END) {
        yield fork(onSearchResult, action.payload);
        action = yield take(getSearchResult().type);
    }
}

function* watchVerifyWine() {
    let action = yield take(verifyWine().type);
    while (action !== END) {
        yield fork(onVerifyWine, action.payload);
        action = yield take(verifyWine().type);
    }
}

function* workerCreateEmailSubscription(data) {
    try {
        const { email } = data;
        yield put(resetEmailSubscription);
        const token = yield call(checkJWT);
        const brand = yield select(getBrand);
        let brandId = brand.code;

        if (Object.keys(brandsEmailOpt).includes(brandId)) {
            brandId = brandsEmailOpt[brandId];
        }
        const response = yield call(subscribeEmailApi, { email, brandCode: brandId, token });
        yield put(setEmailSubscriptionData(response.data));
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerCreateEmailSubscription',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            jsError: ex,
        });
    }
}

function* workerGenesysEventData(postData) {
    const featureFlags = yield select(getFeatureFlags);

    try {
        const token = yield call(checkJWT);
        yield call(postChatEventsWithEID, { ...postData, token, featureFlags });
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerGenesysEventData',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            jsError: ex,
        });
    }
}

function* watchCreateEmailSubscription() {
    let action = yield take(createEmailSubscription().type);
    while (action !== END) {
        yield fork(workerCreateEmailSubscription, action.payload);
        action = yield take(createEmailSubscription().type);
    }
}

function* workerFetchGCIKey(gciPayload) {
    const jwt = yield call(checkJWT);
    const { params } = gciPayload;
    try {
        const response = yield call(fetchGCIKeyAPI, { jwt, params });
        const { data } = response;
        const { gciKeyId } = data || {};
        if (gciKeyId) {
            yield put(setGCIKey(gciKeyId));
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerFetchGCIKey',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            jsError: ex,
            message: 'Failed to load key',
        });
    }
}

function* watcherFetchGCIKey() {
    let action = yield take(fetchGCIKey().type);
    while (action !== END) {
        yield fork(workerFetchGCIKey, action.payload);
        action = yield take(fetchGCIKey().type);
    }
}

function* watcherGenesysEventData() {
    let action = yield take(postChatEventWithEID().type);
    while (action !== END) {
        yield fork(workerGenesysEventData, action.payload);
        action = yield take(postChatEventWithEID().type);
    }
}
function* workerSubcriptionVerifyAddress(action) {
    const proceedToSave = yield call(qasAddressSearchOperations.workers.validateAddressQAS, action);
    const { allowSave, addressVerifiedStatus } = proceedToSave;
    if (allowSave) {
        const updateVerification = { ...action };
        updateVerification.data.dpvIndicator = addressVerifiedStatus.dpvIndicator;
        updateVerification.data.isAddressVerified = addressVerifiedStatus.isAddressVerified;
        const data = updateVerification.data;
        yield put(EventDispatcher.updateSubscriptionRecipientForm(data));
    }
}

function* watchSubscriptionVerifyAddress() {
    let action = yield take(subscriptionVerifyAddress().type);
    while (action !== END) {
        yield fork(workerSubcriptionVerifyAddress, action.payload);
        action = yield take(subscriptionVerifyAddress().type);
    }
}

const watchers = [fork(watchLoadAppShell), fork(watchValidateProductFilterZipcode), fork(watchVerifyWine), fork(watcherFetchGCIKey), fork(watchGetSearchResult), fork(watchCreateEmailSubscription), fork(watcherGenesysEventData), fork(watchSubscriptionVerifyAddress)];

const serverWatchers = [fork(watchLoadAppSSR)];

export {
    onLoadAppShell,
    watchers,
    serverWatchers,
    getSearchResult,
    clearSearchResult,
    workerValidateProductFilterZipcode,
    watchValidateProductFilterZipcode,
    onVerifyWine,
    watchVerifyWine,
    verifyWine,
    setWineData,
    subscriptionVerifyAddress,
};
