/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, {
    useRef, useState, useEffect, useCallback,
} from 'react';
import { string } from 'prop-types';
import mbpUtil from 'mbp-api-util';

const ConversationalUIFooterChat = ({ deviceType }) => {
    const trustedOrigin = `${mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST')}`;
    const isMobile = deviceType !== 'desktop';
    const iframeRef = useRef(null);
    const [isIframeLoaded, setIsIframeLoaded] = useState(true);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [translate, setTranslate] = useState({ x: 0, y: 0 });
    const footerHeight = -96;
    const rightBoundaryLimit = 20;

    const handleIframeLoad = () => setIsIframeLoaded(true);
    const handleIframeError = () => setIsIframeLoaded(false);

    const handleWindowResize = useCallback(() => {
        setTranslate({ x: 0, y: 0 });
    }, []);

    const handleConvUiFooterContainer = useCallback(
        (event) => {
            const {
                type, x, y, width, height,
            } = event.data;

            if (event.origin !== trustedOrigin || !iframeRef.current) return;

            const iframeRect = iframeRef?.current?.getBoundingClientRect();
            const { width: containerWidth, height: containerHeight } = iframeRect;

            switch (type) {
                case 'conv-ui-footer-reset':
                    setTranslate({ x: 0, y: 0 });
                    break;

                case 'conv-ui-footer-drag-start':
                    setDragStart({ x, y });
                    iframeRef.current.style.bottom = dragStart.x + footerHeight;
                    break;

                case 'conv-ui-footer-drag-move': {
                    const newX = x - dragStart.x + translate.x;
                    const newY = y - dragStart.y + translate.y;
                    const withinVerticalBounds = newY <= containerHeight - footerHeight && newY >= -(window.innerHeight - containerHeight);
                    const withinHorizontalBounds = newX >= -(window.innerWidth - containerWidth) && newX <= rightBoundaryLimit;
                    if (withinVerticalBounds && withinHorizontalBounds) {
                        setTranslate({ x: newX, y: newY });
                    }
                    break;
                }

                case 'conv-ui-footer-resize':
                    if (width) iframeRef.current.style.width = width;
                    if (height) iframeRef.current.style.height = height;
                    break;

                default:
                    break;
            }
        },
        [dragStart, translate],
    );

    useEffect(() => {
        window?.addEventListener('message', handleConvUiFooterContainer);
        window?.addEventListener('resize', handleWindowResize);

        return () => {
            window?.removeEventListener('message', handleConvUiFooterContainer);
            window?.removeEventListener('resize', handleWindowResize);
        };
    }, [handleConvUiFooterContainer, handleWindowResize]);

    return isIframeLoaded && isMobile ? (
        <iframe
            ref={iframeRef}
            id="18fchatbotfooter"
            title="18fchatbotfooter"
            style={{
                overflow: 'hidden',
                display: 'block',
                boxShadow: 'none',
                border: 'none',
                backgroundColor: 'white',
                position: 'fixed',
                bottom: `${footerHeight}px`,
                left: 0,
                width: '100%',
                zIndex: 2242999,
                transition: 'bottom 0.3s ease, height 0.3s ease',
            }}
            src={`${trustedOrigin}/chat/footer`}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
        />
    ) : null;
};

ConversationalUIFooterChat.propTypes = {
    deviceType: string.isRequired,
};

export default ConversationalUIFooterChat;
