/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component';

import { getDialogOpen, getDialogType } from '../../../../../state/ducks/App/App-Selectors';
import ComponentErrorBoundary from '../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';
import { setDialogOpen } from '../../../../../state/ducks/App/App-Actions';

const LoadableOfferGatewayDialogContainer = loadable(() => import(/* webpackChunkName: "OfferGatewayDialogContainer" */ './OfferGateway/OfferGatewayDialogContainer'));

export default function GenericDialog() {
    const isOpen = useSelector(getDialogOpen);
    const dialogType = useSelector(getDialogType);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setDialogOpen(false));
    }, []);

    if (isOpen) {
        switch (dialogType) {
            case 'offerGateway':
                return (
                    <ComponentErrorBoundary function="GenericDialog" module="offerGateway">
                        <LoadableOfferGatewayDialogContainer />
                    </ComponentErrorBoundary>
                );

            default:
                return null;
        }
    }

    return null;
}
